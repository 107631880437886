import { useAppDispatch, useAppSelector } from "hooks";
import styles from "./UserReferrals.module.scss";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getAllUserReferralsBegin } from "pages/Profile/actions";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

function UserReferrals() {
  const { referredClients } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();

  useEffect(() => {
    dispatch(getAllUserReferralsBegin());
  }, []);

  return (
    <div className={styles.container}>
      <h2>{translations("label.list_of_referrals")}</h2>

      <div className={styles.table}>
        <table className={styles.nonScrollableTable}>
          <thead>
            <tr>
              <td>{translations("label.date")}</td>
              <td>{translations("label.referred_user")}</td>
            </tr>
          </thead>
        </table>

        <div className={styles.scrollableTbody}>
          <table>
            <tbody>
              {referredClients?.length > 0 ? (
                referredClients?.map((client, index) => (
                  <tr key={index}>
                    <td>{dayjs(client?.created_at).format("DD.MM.YYYY")}</td>
                    <td>{client?.firstname + " " + client?.lastname}</td>
                  </tr>
                ))
              ) : (
                <span>{translations("label.no_referrals_made_yet")}</span>
              )}
            </tbody>
          </table>
        </div>
        <div
          className={styles.button}
          onClick={() => navigate("/invite-referral")}
        >
          <span>{translations("button.make_new_referrals")}</span>
        </div>
      </div>
    </div>
  );
}

export default UserReferrals;
