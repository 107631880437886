import { useAppDispatch, useAppSelector } from "hooks";
import { useState, useEffect } from "react"; // Import useRef and useEffect
import { GlobalIState } from "types/RootState";

import styles from "./MultiSelectWithSearchDropdown.module.scss";
import { getDefaultColour, getFilterValue } from "utils/utils";
import {
  getAppellationsFiltersBegin,
  getRegionsFilterBegin,
  setFilters,
} from "pages/Purchasing/actions";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { CLEAR_FILTERS } from "pages/Purchasing/constants";
import StorageService from "services/storage.service";

interface Props {
  label: string;
  options: string[];
  type: string;
}

export default function MultiSelectWithSearchDropdown(props: Props) {
  const { options, type } = props;
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    filters,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const countriesFilter: any = filters.filter(
    (item) => item.filter === "Country"
  );
  const regionsFilter: any = filters.filter((item) => item.filter === "Region");

  const filterValues = getFilterValue(type, filters) ?? [];
  const [selectedOptions, setSelectedOptions] = useState<any[]>([
    ...filterValues,
  ]);
  const [filterValue, setFilterValue] = useState("");
  const [data, setData] = useState([]);
  const [shouldFetchRegions, setShouldFetchRegions] = useState(false);
  const [shouldFetchAppellations, setShouldFetchAppellations] = useState(false);
  const clearFiltersCookie = StorageService.getDataByKey("clear_filters");

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

    dispatch(
      setFilters({
        filter: type,
        value: selectedOptions.includes(option)
          ? selectedOptions.filter((item) => item !== option)
          : [...selectedOptions, option],
      })
    );

    if (type === "Country") {
      setShouldFetchRegions(true);
    }

    if (type === "Region") {
      setShouldFetchAppellations(true);
    }
  };

  const handleFilterFilters = (e) => {
    const text = e.target.value;
    setFilterValue(text);

    if (text === "") {
      setData(options);
    } else {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(text.toLowerCase())
      );
      setData(filtered);
    }
  };

  useEffect(() => {
    if (options) {
      setData([...options]);
    }

    if (actionName === CLEAR_FILTERS) {
      setSelectedOptions([]);
    }
  }, [options, actionName]);

  useEffect(() => {
    if (type === "Country" && shouldFetchRegions) {
      if (countriesFilter[0] && countriesFilter[0]?.value?.length >= 1) {
        dispatch(
          getRegionsFilterBegin({
            countries: countriesFilter[0]?.value,
            lang: getLanguage(),
          })
        );
        setShouldFetchRegions(false);
      }
    }
  }, [shouldFetchRegions, filters]);

  useEffect(() => {
    if (type === "Region") {
      if (
        countriesFilter[0] &&
        countriesFilter[0]?.value?.length >= 1 &&
        regionsFilter[0] &&
        regionsFilter[0]?.value?.length >= 1
      ) {
        dispatch(
          getAppellationsFiltersBegin({
            countries: countriesFilter[0]?.value,
            regions: regionsFilter[0]?.value,
            lang: getLanguage(),
          })
        );
        setShouldFetchAppellations(false);
      }
    }
  }, [shouldFetchAppellations, filters]);

  useEffect(() => {
    if (clearFiltersCookie) {
      setFilterValue("");
      StorageService.deleteCookie("filters");
      StorageService.deleteCookie("clear_filters");
    }
  }, [clearFiltersCookie]);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {!["Type", "Country", "Vintage", "Region", "Appellation"].includes(
        type
      ) ? (
        <input
          type="text"
          placeholder={translations("label.filter")}
          className={styles.filterValue}
          value={filterValue}
          onChange={(e) => handleFilterFilters(e)}
        />
      ) : null}
      <div className={styles.multiSelectDropdownContainer}>
        {data?.map((option) => (
          <Tooltip title={option?.length >= 15 ? option : ""}>
            {option?.length !== 0 ? (
              <label key={option}>
                <input
                  type="checkbox"
                  value={option}
                  className={styles.inputCheckbox}
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option?.length >= 15
                  ? option.substring(0, 15) + "..."
                  : option}
              </label>
            ) : null}
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
