const scope = "vinfinity/profile";

export const CLEAR_ACTION = `${scope}/CLEAR_ACTION`;

export const CHANGE_PASSWORD_BEGIN = `${scope}/CHANGE_PASSWORD_BEGIN`;
export const CHANGE_PASSWORD_FAILED = `${scope}/CHANGE_PASSWORD_FAILED`;
export const CHANGE_PASSWORD_SUCCESS = `${scope}/CHANGE_PASSWORD_SUCCESS`;

export const RESET_PASSWORD_BEGIN = `${scope}/RESET_PASSWORD_BEGIN`;
export const RESET_PASSWORD_FAILED = `${scope}/RESET_PASSWORD_FAILED`;
export const RESET_PASSWORD_SUCCESS = `${scope}/RESET_PASSWORD_SUCCESS`;

export const GET_CLIENT_BEGIN = `${scope}/GET_CLIENT_BEGIN`;
export const GET_CLIENT_FAILED = `${scope}/GET_CLIENT_FAILED`;
export const GET_CLIENT_SUCCESS = `${scope}/GET_CLIENT_SUCCESS`;

export const GET_TERMS_AND_CONDITIONS = `${scope}/GET_TERMS_AND_CONDITIONS`;
export const GET_TERMS_AND_CONDITIONS_SUCCESS = `${scope}/GET_TERMS_AND_CONDITIONS_SUCCESS`;

export const GET_PRIVACY_POLICY = `${scope}/GET_PRIVACY_POLICY`;
export const GET_PRIVACY_POLICY_SUCCESS = `${scope}/GET_PRIVACY_POLICY_SUCCESS`;

export const AGREE_TO_TERMS_AND_CONDITIONS = `${scope}/AGREE_TO_TERMS_AND_CONDITIONS`;
export const DECLINE_TERMS_AND_CONDITIONS = `${scope}/DECLINE_TERMS_AND_CONDITIONS`;

export const AGREE_TO_PRIVACY_POLICY = `${scope}/AGREE_TO_PRIVACY_POLICY`;
export const DECLINE_PRIVACY_POLICY = `${scope}/DECLINE_PRIVACY_POLICY`;
export const REQUEST_ACCOUNT_CLOSURE = `${scope}/REQUEST_ACCOUNT_CLOSURE`;

export const UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN = `${scope}/UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN`;
export const UPDATE_NOTIFICATION_IDENTIFICATION_FAILED = `${scope}/UPDATE_NOTIFICATION_IDENTIFICATION_FAILED`;
export const UPDATE_NOTIFICATION_IDENTIFICATION_SUCCESS = `${scope}/UPDATE_NOTIFICATION_IDENTIFICATION_SUCCESS`;

export const UPDATE_LANGUAGE_BEGIN = `${scope}/UPDATE_LANGUAGE_BEGIN`;
export const UPDATE_LANGUAGE_FAILED = `${scope}/UPDATE_LANGUAGE_FAILED`;
export const UPDATE_LANGUAGE_SUCCESS = `${scope}/UPDATE_LANGUAGE_SUCCESS`;

export const CLEAR_CLIENT = `${scope}/CLEAR_CLIENT`;

export const UPDATE_PROFILE_PICTURE_BEGIN = `${scope}/UPDATE_PROFILE_PICTURE_BEGIN`;
export const UPDATE_PROFILE_PICTURE_FAILED = `${scope}/UPDATE_PROFILE_PICTURE_FAILED`;
export const UPDATE_PROFILE_PICTURE_SUCCESS = `${scope}/UPDATE_PROFILE_PICTURE_SUCCESS`;

export const UPDATE_PERSONAL_DETAILS_BEGIN = `${scope}/UPDATE_PERSONAL_DETAILS_BEGIN`;
export const UPDATE_PERSONAL_DETAILS_FAILED = `${scope}/UPDATE_PERSONAL_DETAILS_FAILED`;
export const UPDATE_PERSONAL_DETAILS_SUCCESS = `${scope}/UPDATE_PERSONAL_DETAILS_SUCCESS`;

export const UPDATE_ADDRESS_DETAILS_BEGIN = `${scope}/UPDATE_ADDRESS_DETAILS_BEGIN`;
export const UPDATE_ADDRESS_DETAILS_FAILED = `${scope}/UPDATE_ADDRESS_DETAILS_FAILED`;
export const UPDATE_ADDRESS_DETAILS_SUCCESS = `${scope}/UPDATE_ADDRESS_DETAILS_SUCCESS`;

export const UPDATE_APPLICATION_VIEW_FROM_STORAGE = `${scope}/UPDATE_APPLICATION_VIEW_FROM_STORAGE`;
export const UPDATE_APPLICATION_VIEW_BEGIN = `${scope}/UPDATE_APPLICATION_VIEW_BEGIN`;
export const UPDATE_APPLICATION_VIEW_FAILED = `${scope}/UPDATE_APPLICATION_VIEW_FAILED`;
export const UPDATE_APPLICATION_VIEW_SUCCESS = `${scope}/UPDATE_APPLICATION_VIEW_SUCCESS`;

export const ADD_PHONE_BEGIN = `${scope}/ADD_PHONE_BEGIN`;
export const ADD_PHONE_FAILED = `${scope}/ADD_PHONE_FAILED`;
export const ADD_PHONE_SUCCESS = `${scope}/ADD_PHONE_SUCCESS`;

export const CONFIRM_ADD_PHONE_BEGIN = `${scope}/CONFIRM_ADD_PHONE_BEGIN`;
export const CONFIRM_ADD_PHONE_FAILED = `${scope}/CONFIRM_ADD_PHONE_FAILED`;
export const CONFIRM_ADD_PHONE_SUCCESS = `${scope}/CONFIRM_ADD_PHONE_SUCCESS`;

export const ADD_EMAIL_BEGIN = `${scope}/ADD_EMAIL_BEGIN`;
export const ADD_EMAIL_FAILED = `${scope}/ADD_EMAIL_FAILED`;
export const ADD_EMAIL_SUCCESS = `${scope}/ADD_EMAIL_SUCCESS`;

export const CONFIRM_ADD_EMAIL_BEGIN = `${scope}/CONFIRM_ADD_EMAIL_BEGIN`;
export const CONFIRM_ADD_EMAIL_FAILED = `${scope}/CONFIRM_ADD_EMAIL_FAILED`;
export const CONFIRM_ADD_EMAIL_SUCCESS = `${scope}/CONFIRM_ADD_EMAIL_SUCCESS`;

export const SET_DEFAULT_COLOR = `${scope}/SET_DEFAULT_COLOR`;
export const RESET_DEFAULT_COLOR = `${scope}/RESET_DEFAULT_COLOR`;

export const GET_PENDING_DELIVERY_BALANCE_BEGIN = `${scope}/GET_PENDING_DELIVERY_BALANCE_BEGIN`;
export const GET_PENDING_DELIVERY_BALANCE_FAILED = `${scope}/GET_PENDING_DELIVERY_BALANCE_FAILED`;
export const GET_PENDING_DELIVERY_BALANCE_SUCCESS = `${scope}/GET_PENDING_DELIVERY_BALANCE_SUCCESS`;

export const GET_WALLET_TOTAL_AMOUNT_BEGIN = `${scope}/GET_WALLET_TOTAL_AMOUNT_BEGIN`;
export const GET_WALLET_TOTAL_AMOUNT_FAILED = `${scope}/GET_WALLET_TOTAL_AMOUNT_FAILED`;
export const GET_WALLET_TOTAL_AMOUNT_SUCCESS = `${scope}/GET_WALLET_TOTAL_AMOUNT_SUCCESS`;

export const CONFIRM_PASSWORD_CHANGE_BEGIN = `${scope}/CONFIRM_PASSWORD_CHANGE_BEGIN`;
export const CONFIRM_PASSWORD_CHANGE_FAILED = `${scope}/CONFIRM_PASSWORD_CHANGE_FAILED`;
export const CONFIRM_PASSWORD_CHANGE_SUCCESS = `${scope}/CONFIRM_PASSWORD_CHANGE_SUCCESS`;

export const CHANGE_EMAIL_BEGIN = `${scope}/CHANGE_EMAIL_BEGIN`;
export const CHANGE_EMAIL_FAILED = `${scope}/CHANGE_EMAIL_FAILED`;
export const CHANGE_EMAIL_SUCCESS = `${scope}/CHANGE_EMAIL_SUCCESS`;
export const CONFIRM_CHANGE_EMAIL_BEGIN = `${scope}/CONFIRM_CHANGE_EMAIL_BEGIN`;
export const CONFIRM_CHANGE_EMAIL_FAILED = `${scope}/CONFIRM_CHANGE_EMAIL_FAILED`;
export const CONFIRM_CHANGE_EMAIL_SUCCESS = `${scope}/CONFIRM_CHANGE_EMAIL_SUCCESS`;

export const CHANGE_PHONE_NUMBER_BEGIN = `${scope}/CHANGE_PHONE_NUMBER_BEGIN`;
export const CHANGE_PHONE_NUMBER_FAILED = `${scope}/CHANGE_PHONE_NUMBER_FAILED`;
export const CHANGE_PHONE_NUMBER_SUCCESS = `${scope}/CHANGE_PHONE_NUMBER_SUCCESS`;

export const CONFIRM_CHANGE_PHONE_NUMBER_BEGIN = `${scope}/CONFIRM_CHANGE_PHONE_NUMBER_BEGIN`;
export const CONFIRM_CHANGE_PHONE_NUMBER_FAILED = `${scope}/CONFIRM_CHANGE_PHONE_NUMBER_FAILED`;
export const CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS = `${scope}/CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS`;

export const GET_REFERRAL_CODE_BEGIN = `${scope}/GET_REFERRAL_CODE_BEGIN`;
export const GET_REFERRAL_CODE_FAILED = `${scope}/GET_REFERRAL_CODE_FAILED`;
export const GET_REFERRAL_CODE_SUCCESS = `${scope}/GET_REFERRAL_CODE_SUCCESS`;

export const GET_ALL_USER_REFERRALS_BEGIN = `${scope}/GET_ALL_USER_REFERRALS_BEGIN`;
export const GET_ALL_USER_REFERRALS_FAILED = `${scope}/GET_ALL_USER_REFERRALS_FAILED`;
export const GET_ALL_USER_REFERRALS_SUCCESS = `${scope}/GET_ALL_USER_REFERRALS_SUCCESS`;

export const GET_USER_REFERRALS_BEGIN = `${scope}/GET_USER_REFERRALS_BEGIN`;
export const GET_USER_REFERRALS_FAILED = `${scope}/GET_USER_REFERRALS_FAILED`;
export const GET_USER_REFERRALS_SUCCESS = `${scope}/GET_USER_REFERRALS_SUCCESS`;

export const CHECK_IF_HAS_SAVED_CARD_BEGIN = `${scope}/CHECK_IF_HAS_SAVED_CARD_BEGIN`;
export const CHECK_IF_HAS_SAVED_CARD_FAILED = `${scope}/CHECK_IF_HAS_SAVED_CARD_BEGIN`;
export const CHECK_IF_HAS_SAVED_CARD_SUCCESS = `${scope}/CHECK_IF_HAS_SAVED_CARD_SUCCESS`;

export const GENERATE_TWO_FACTOR_AUTH_BEGIN = `${scope}/GENERATE_TWO_FACTOR_AUTH_BEGIN`;
export const GENERATE_TWO_FACTOR_AUTH_FAILED = `${scope}/GENERATE_TWO_FACTOR_AUTH_FAILED`;
export const GENERATE_TWO_FACTOR_AUTH_SUCCESS = `${scope}/GENERATE_TWO_FACTOR_AUTH_SUCCESS`;

export const VERIFY_TWO_FACTOR_AUTH_BEGIN = `${scope}/VERIFY_TWO_FACTOR_AUTH_BEGIN`;
export const VERIFY_TWO_FACTOR_AUTH_FAILED = `${scope}/VERIFY_TWO_FACTOR_AUTH_FAILED`;
export const VERIFY_TWO_FACTOR_AUTH_SUCCESS = `${scope}/VERIFY_TWO_FACTOR_AUTH_SUCCESS`;

export const DEACTIVATE_TWO_FACTOR_AUTH_BEGIN = `${scope}/DEACTIVATE_TWO_FACTOR_AUTH_BEGIN`;
export const DEACTIVATE_TWO_FACTOR_AUTH_FAILED = `${scope}/DEACTIVATE_TWO_FACTOR_AUTH_FAILED`;
export const DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS = `${scope}/DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS`;

export const UPDATE_USER_INFO_BEGIN = `${scope}/UPDATE_USER_INFO_BEGIN`;
export const UPDATE_USER_INFO_FAILED = `${scope}/UPDATE_USER_INFO_FAILED`;
export const UPDATE_USER_INFO_SUCCESS = `${scope}/UPDATE_USER_INFO_SUCCESS`;

export const CHANGE_AUTH_SECURITY_BEGIN = `${scope}/CHANGE_AUTH_SECURITY_BEGIN`;
export const CHANGE_AUTH_SECURITY_FAILED = `${scope}/CHANGE_AUTH_SECURITY_FAILED`;
export const CHANGE_AUTH_SECURITY_SUCCESS = `${scope}/CHANGE_AUTH_SECURITY_SUCCESS`;

export const GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN = `${scope}/GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN`;
export const GET_S3_UPLOAD_REFERRAL_IMAGE_FAILED = `${scope}/GET_S3_UPLOAD_REFERRAL_IMAGE_FAILED`;
export const GET_S3_UPLOAD_REFERRAL_IMAGE_SUCCESS = `${scope}/GET_S3_UPLOAD_REFERRAL_IMAGE_SUCCESS`;
export const CLEAR_S3_LINK = `${scope}/CLEAR_S3_LINK`;
