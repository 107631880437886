import React from "react";

interface Props {
  text: string;
}

const NecktieFormatText: React.FC<Props> = ({ text }) => {
  const truncateText = (str: string): string => {
    return str.length >= 8 ? `${str.slice(0, 8)}...` : str;
  };

  if (text?.includes("*")) {
    const lines = text
      .split("*")
      .map((line) => line.trim())
      .filter(Boolean);
    return (
      <span>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {truncateText(line)}
            {index < lines.length - 1 && (
              <>
                <br />*
              </>
            )}
          </React.Fragment>
        ))}
      </span>
    );
  }

  return <div>{text?.length >= 50 ? text?.slice(0, 50) + "..." : text}</div>;
};

export default NecktieFormatText;
