import React, { useEffect, useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { useTranslation } from "react-i18next";

import styles from "./appwraper.module.scss";
import VinfinityLogo from "assets/images/VinfinityLogo.png";
import {
  ProfileIcon,
  LogoutIcon,
  WalletIcon,
  MobileMenuIcon,
  CloseMobileMenuIcon,
  PurchasingWineIcon,
  GamificationIcon,
  InvestmentsIcon,
  BackButtonIcon,
  AllCategoriesIcon,
  LoginIcon,
  ShoppingCartHeaderIcon,
  CloseFirstTypeIcon,
  SearchIcon,
  BookmarksIcon,
  ReferralsIcon,
  PrivateLedgerIcon,
  ConciergeIcon,
  ShoppingCartSecondaryIcon,
  ClearIcon,
  CloseIconWhiteBg,
  UpperIcon,
  PlusIcon,
  EditPencilIcon,
  FiltersIcon,
  SearchMobileMenuIcon,
  MembershipIcon,
  CloseNoBgIcon,
  LocationMapIcon,
  ListStreamMap,
  HowItWorksIcon,
  CloseIconWhite,
  GoldCoinIcon,
  ChFlagIcon,
  NewSearchIcon,
} from "assets/icons/icons";

import { useAppDispatch, useAppSelector } from "hooks";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import {
  clearEventsFilter,
  clearScrollTop,
  closeToaster,
  openModal,
  openToaster,
  setEventsFilter,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import ModalComponent from "components/Modal/Modal.component";
import ChangeLanguageModal from "components/ChangeLanguage/ChangeLanguage.component";
import { GlobalIState } from "types/RootState";
import { Badge, Tooltip } from "@mui/material";
import {
  checkPathsToHideBackButton,
  checkPathsToHideMobileMenu,
  formatPrice,
  getCurrencyStr,
  getDefaultBackground,
  getDefaultColour,
  getFilterOptions,
  getFilterValue,
  getFiltersSelectedCount,
  getSortingFilters,
  updateFiltersArray,
} from "utils/utils";
import { logOut } from "pages/Authentication/actions";
import { clearClient } from "pages/Profile/actions";
import WalletTabs from "components/WalletTabs/WalletTabs.component";
import {
  clearCartItems,
  clearCountryRegionAppellationFilter,
  clearFilters,
  fetchProductsBegin,
} from "pages/Purchasing/actions";
import MultiSelectDropdown from "components/MultiSelectDropdown/MultiSelectDropdown.component";
import StorageService from "services/storage.service";
import ProductFilters from "pages/Purchasing/ProductFilters/ProductFilters.page";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "components/Toast/CustomToast.component";
import SortingFilter from "components/SortingFilter/SortingFilter.component";
import {
  getAddedWinesBegin,
  getSearchedJournalWinesBegin,
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
} from "pages/PersonalLedger/actions";
import MobileHeader from "components/MobileHeader/MobileHeader.component";
import ButtonComponent from "components/Button/Button.component";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import LoaderBounce from "components/Loader/bounce/LoaderBounce.component";

declare global {
  interface Window {
    Intercom: any;
  }
}

const AppWrapperMiddleware: React.FC<any> = () => {
  const {
    toaster,
    leftPartShownItem,
    middlePartShownItem,
    rightPartShownItem,
    scrollTop,
    eventSelectedCategory,
  } = useAppSelector((state: GlobalIState) => state.globalReducer);
  const { client, defaultBackground, defaultColour, pending_delivery_balance } =
    useAppSelector((state: GlobalIState) => state.ProfilePageReducer);

  const {
    products,
    cartItems,
    filters,
    filterOptions,
    regionFilterOptions,
    appellationsFilterOptions,
    productBookmarks,
    adProductData,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);

  const isLoggedIn = () => StorageService.getDataByKey("token");
  const langStored = () => localStorage.getItem("lang")?.toUpperCase();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  let ledgerActiveTab = searchParams.get("tab");
  let membershipActiveView = searchParams.get("view");
  let wineStreamParam = searchParams.get("stream");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isLedgerSearching, setIsLedgerSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [ledgerSearchValue, setLedgerSearchValue] = useState("");
  const pathname = location.pathname;
  const isMobile = useCheckMobileScreen();
  const shouldHideMobileMenu = isMobile && checkPathsToHideMobileMenu(pathname);

  const hideBackButton =
    checkPathsToHideBackButton(pathname) ||
    (isMobile && ["/product/promotion", "/journal"].includes(pathname));

  const getFormattedValue = (value: number | string) => {
    return isLoggedIn()
      ? value?.toString()?.length <= 3
        ? Number(value)?.toFixed(2)
        : formatPrice(value?.toString())
      : "0";
  };

  let medalsValue = isLoggedIn()
    ? formatPrice(client?.medals_balance.toString())
    : "0";
  let stakedValue = getFormattedValue(client?.staked_balance);
  const currency = getCurrencyStr(client?.currency);
  const [shouldShowPurchasingFilters, setShowPurchasingFilters] =
    useState(false);
  const [showRegionFilter, setShowRegionFilter] = useState(false);
  const [showAppellationFilter, setShowAppellationFilter] = useState(false);
  const [show1kMedalsPopup, setShow1kMedalsPopup] = useState(false);
  const appWrapperContentRef = useRef<HTMLDivElement>(null);
  const page = StorageService.getDataByKey("products_page");
  const numOfPagesCookie = () => StorageService.getDataByKey("numOfPages");
  const showFooterCookie = () => StorageService.getDataByKey("show_footer");
  const footerRemovedByUser = () =>
    StorageService.getDataByKey("removed_footer_by_user");
  const showOnlyInSwitzerland = () =>
    StorageService.getDataByKey("show_only_shop_in_switzerland");
  const isClosedOnlyInSwitzerland = () =>
    StorageService.getDataByKey("is_closed_only_shop_in_switzerland");
  const wineNameMissingCookie = StorageService.getDataByKey(
    "error_wine_name_missing"
  );
  const streamParam = searchParams.get("stream");
  const isClosedThousandMedalsPopup = () =>
    StorageService.getDataByKey("1kmedals_popup_closed");
  const showThousandMedalsPopup = () =>
    StorageService.getDataByKey("show_1k_medals_popup");
  const typeFilterOptions = getFilterOptions(filterOptions?.types, "name");
  const countryFilterOptions = getFilterOptions(
    filterOptions?.countries,
    "country_name"
  );
  const grapesFilterOptions = getFilterOptions(
    filterOptions?.grapes,
    "varietal_name"
  );
  const producersFilterOptions = getFilterOptions(
    filterOptions?.producers,
    "producer_name"
  );
  const vintageFilterOptions = getFilterOptions(
    filterOptions?.vintages,
    "vintage_name"
  );
  const pairingFilterOptions = getFilterOptions(
    filterOptions?.pairing,
    "pairing"
  );
  const savedPosition = sessionStorage.getItem(`${location.pathname}`);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pullStart, setPullStart] = useState<number | null>(null);
  const pullThreshold = 50;
  const productFiltersCookie = () => StorageService.getDataByKey("filters");

  const getLanguageStored = () => {
    return localStorage.getItem("lang");
  };

  const handleLogout = async () => {
    await dispatch(clearClient());
    await dispatch(clearCartItems());
    await dispatch(logOut());
    window.location.reload();
    await dispatch(
      openToaster("SUCCESS", translations("label.logout_success"))
    );
  };

  const handleRedirect = (url: string) => {
    if (isMobileMenuOpen) setIsMobileMenuOpen(false);
    navigate(url);
  };

  const handleLanguageChangeModal = () => {
    dispatch(
      openModal({
        content: <ChangeLanguageModal />,
        type: modalTypes.mdNoBg,
      })
    );
  };

  const scrollToTop = (ref) => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(function () {
        dispatch(clearScrollTop());
      }, 1000);
    }
  };

  const handleInvestmentsPlusClick = () => {
    if (isLoggedIn()) {
      navigate("/investments/topup");
    } else {
      navigate("/login", {
        state: {
          lastRoute: "/investments/topup",
        },
      });
    }
  };

  const handlePrivateLedgerScanIconClick = () => {};

  const saveScrollPosition = () => {
    if (appWrapperContentRef.current) {
      const scrollPosition = appWrapperContentRef.current.scrollTop;
      sessionStorage.setItem(`${location.pathname}`, scrollPosition.toString());
    }
  };

  const debouncedSaveScrollPosition = debounce(saveScrollPosition, 300);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (window.location.pathname === "/purchasing" && streamParam === "list") {
      const isNearBottom =
        scrollTop + clientHeight >= scrollHeight - (isMobile ? 40 : 2);

      if (
        isNearBottom &&
        products?.length >= 24 &&
        page !== (numOfPagesCookie() ? numOfPagesCookie() : 1)
      ) {
        StorageService.setKeyValue("products_page", page + 1);
        dispatch(
          fetchProductsBegin(
            streamParam,
            page + 1,
            searchValue,
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? [{}]
              : updateFiltersArray(
                  filters.filter((item) => item.value.length >= 1)
                ),
            getLanguageStored()
          )
        );
      }

      debouncedSaveScrollPosition();
    }
  };

  const handlePullToRefresh = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      cacheNames.forEach(async (cacheName) => {
        await caches.delete(cacheName);
      });
    }

    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        registration.unregister();
      }
    }

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleViewMoreFilters = () => {
    dispatch(
      openModal({
        content: <ProductFilters />,
        type: modalTypes.md,
      })
    );
  };

  const handleClearFilters = () => {
    StorageService.deleteCookie("filters");
    StorageService.deleteCookie("filters");
    StorageService.deleteCookie("priceSlider");
    StorageService.setKeyValue("clear_filters", true);
    dispatch(clearFilters());
    dispatch(fetchProductsBegin(streamParam, 1, "", [{}], getLanguageStored()));
    setSearchValue("");
    setShowPurchasingFilters(false);
  };

  const handleApplyFiltersClick = () => {
    dispatch(
      fetchProductsBegin(
        streamParam,
        1,
        searchValue ? searchValue : "",
        updateFiltersArray(filters.filter((item) => item.value.length >= 1)),
        getLanguageStored()
      )
    );

    setTimeout(() => {
      setShowPurchasingFilters(false);
    }, 500);
  };

  const handleSearchValue = (value) => {
    if (value.includes("://")) {
      dispatch(
        openToaster("ERROR", translations("error.search.url_value_not_allowed"))
      );
    } else {
      StorageService.setKeyValue("search", value);
      setSearchValue(value);
    }
  };

  const handleOpenConcierge = () => {
    window.Intercom("show");
  };

  const handleSetActiveTab = (tab: string) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  const handleHideFooter = () => {
    StorageService.setKeyValue("show_footer", false);
    StorageService.setKeyValue("removed_footer_by_user", true);
    window.location.reload();
  };

  const handleHideOnlyAvailableInChPopup = () => {
    StorageService.setKeyValue("show_only_shop_in_switzerland", false);
    StorageService.setKeyValue("is_closed_only_shop_in_switzerland", true);
    window.location.reload();
  };

  const handleAuthBtnClick = () => {
    if (isLoggedIn()) {
      handleLogout();
    } else {
      navigate("/login");
      window.location.reload();
    }
  };

  const handleStreamTabChange = (type: string) => {
    searchParams.set("stream", type);
    setSearchParams(searchParams);

    if (streamParam === type) {
      searchParams.set("refresh", "true");
      setSearchParams(searchParams);
    }

    dispatch(
      fetchProductsBegin(
        type,
        1,
        searchValue ? searchValue : "",
        updateFiltersArray(filters.filter((item) => item.value.length >= 1)),
        getLanguageStored()
      )
    );
  };

  const handleTouchStart = (e: TouchEvent) => {
    if (
      appWrapperContentRef.current &&
      appWrapperContentRef.current.scrollTop === 0
    ) {
      setPullStart(e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    const preventPullToRefresh = [
      "/profile",
      "/journal",
      "/cart",
      "/cart/delivery",
      "/games",
      "/games/info",
      "/games/question",
      "/games/fill-in-the-blank",
      "/games/true-or-false",
      "/games/audio-questions",
      "/games/pour-and-score",
      "/games/magic-match",
      "/games/match-the-pairs",
    ];
    if (
      isMobile &&
      !preventPullToRefresh.includes(window.location.pathname) &&
      streamParam !== "map" &&
      pullStart !== null
    ) {
      const pullDistance = e.touches[0].clientY - pullStart;

      if (pullDistance > pullThreshold && !isRefreshing) {
        StorageService.setKeyValue("isRefreshing", true);
        setIsRefreshing(true);
        handlePullToRefresh();
        setTimeout(() => {
          StorageService.setKeyValue("isRefreshing", false);
          setIsRefreshing(false);
          setPullStart(null);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    scrollToTop(appWrapperContentRef);
    // eslint-disable-next-line
  }, [leftPartShownItem, middlePartShownItem, rightPartShownItem, scrollTop]);

  useEffect(() => {
    if (toaster?.isOpen) {
      if (toaster?.message?.length > 0 && toaster?.type === "SUCCESS") {
        toast(<CustomToast />, {
          autoClose: 3000,
          position: "top-center",
          style: {
            minWidth: isMobile ? "80%" : "unset",
            width: isMobile ? "80%" : "70dvw",
            minHeight: isMobile ? 100 : 50,
            color: "#000",
            backgroundColor: "#E6FFCD",
            border: "2px solid #58C322",
            borderRadius: 12,
            margin: "20px auto 0px auto",
            padding: "10px 20px",
            position: "absolute",
            top: isMobile ? "-30px" : "-45px",
          },
          hideProgressBar: true,
          closeButton: true,
        });
      }
      if (
        toaster?.message?.length > 0 &&
        toaster?.type === "ERROR" &&
        toaster?.message !== "error.send_2fa_two_factor_code"
      ) {
        toast(<CustomToast />, {
          autoClose: 3000,
          position: "top-center",
          style: {
            minWidth: isMobile ? "80%" : "unset",
            width: isMobile ? "80%" : "70dvw",
            minHeight: isMobile ? 100 : 50,
            color: "#000",
            backgroundColor: "#FFDCD8",
            border: "2px solid #FF5741",
            borderRadius: 12,
            margin: "20px auto 0px auto",
            padding: "10px 20px",
            position: "absolute",
            top: isMobile ? "-30px" : "-45px",
          },
          hideProgressBar: true,
          closeButton: true,
        });
      }

      setTimeout(function () {
        dispatch(closeToaster());
        if (wineNameMissingCookie) {
          StorageService.deleteCookie("error_wine_name_missing");
        }
      }, 4000);
    }
    // eslint-disable-next-line
  }, [toaster]);

  useEffect(() => {
    const ledgerDelayDebounceFn = setTimeout(() => {
      if (!isMobile && isLoggedIn()) {
        if (ledgerSearchValue?.length > 0) {
          dispatch(getSearchedJournalWinesBegin(ledgerSearchValue));
        } else {
          if (ledgerActiveTab === "vinfinity-wines") {
            dispatch(getUserOrdersBegin(ledgerSearchValue));
            dispatch(getUserBlindTastingsBegin(ledgerSearchValue));
          } else if (ledgerActiveTab === "added-wines") {
            dispatch(getAddedWinesBegin(ledgerSearchValue));
          }
        }
      }
    }, 100);
    // eslint-disable-next-line
    return () => clearTimeout(ledgerDelayDebounceFn);
  }, [ledgerSearchValue]);

  useEffect(() => {
    if (searchValue.length === 0) {
      StorageService.setKeyValue("search", "");
    }

    const delayDebounceFn = setTimeout(() => {
      let storedFilters = productFiltersCookie()
        ? productFiltersCookie()?.filter((item) => item?.value?.length >= 1)
        : [];

      if (!isMobile) {
        dispatch(
          fetchProductsBegin(
            streamParam ? streamParam : "list",
            1,
            searchValue,
            storedFilters?.length === 0
              ? [{}]
              : updateFiltersArray(storedFilters),
            getLanguageStored()
          )
        );
      }
    }, 100);

    // eslint-disable-next-line
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, getLanguageStored()]);

  useEffect(() => {
    const countryFilter = getFilterValue("Country", filters);
    const regionFilter = getFilterValue("Region", filters);

    if (countryFilter && countryFilter?.length !== 0) {
      setShowRegionFilter(true);
    } else setShowRegionFilter(false);

    if (
      countryFilter &&
      countryFilter?.length !== 0 &&
      regionFilter &&
      regionFilter?.length !== 0
    ) {
      setShowAppellationFilter(true);
    } else setShowAppellationFilter(false);

    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (!showFooterCookie() && !footerRemovedByUser()) {
      StorageService.setKeyValue("show_footer", true);
    }

    if (!showOnlyInSwitzerland() && !isClosedOnlyInSwitzerland()) {
      StorageService.setKeyValue("show_only_shop_in_switzerland", true);
    }

    if (
      isLoggedIn() &&
      client &&
      showThousandMedalsPopup() &&
      !isClosedThousandMedalsPopup()
    ) {
      setShow1kMedalsPopup(true);
    }
  }, [
    footerRemovedByUser(),
    showThousandMedalsPopup(),
    isClosedThousandMedalsPopup(),
    showOnlyInSwitzerland(),
    isClosedOnlyInSwitzerland(),
  ]);

  useEffect(() => {
    if (!showRegionFilter && !showAppellationFilter) {
      dispatch(clearCountryRegionAppellationFilter());
    }
  }, [showRegionFilter, showAppellationFilter]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      if (navigator.userAgent.match(/Android/i)) {
        setIsMobileMenuOpen(true);
      } else navigate(-1);
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  useEffect(() => {
    const restoreScroll = () => {
      if (appWrapperContentRef.current) {
        appWrapperContentRef.current.scrollTo({
          top: Number(savedPosition),
          behavior: "smooth",
        });
      }
    };

    const timer = setTimeout(() => {
      restoreScroll();
    }, 300);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  // useEffect(() => {
  //   const ref = appWrapperContentRef.current;

  //   if (ref) {
  //     ref.addEventListener("touchstart", handleTouchStart);
  //     ref.addEventListener("touchmove", handleTouchMove);
  //   }

  //   return () => {
  //     if (ref) {
  //       ref.removeEventListener("touchstart", handleTouchStart);
  //       ref.removeEventListener("touchmove", handleTouchMove);
  //     }
  //   };
  // }, [pullStart, isRefreshing]);

  return (
    <>
      {/* DESKTOP SIDEBAR */}
      <div
        className={styles.mainWrapper}
        style={
          {
            backgroundImage: `url(${getDefaultBackground(defaultBackground)})`,
            "--backgroundColor": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div
          className={styles.desktopSidebar}
          style={{
            opacity: show1kMedalsPopup
              ? 0.7
              : [
                  "/signup/personal-details",
                  "/cart/user-info",
                  "/signup/passcode",
                ].includes(window.location.pathname)
              ? 0
              : 1,
            pointerEvents:
              show1kMedalsPopup ||
              ["/signup/personal-details", "/cart/user-info"].includes(
                window.location.pathname
              )
                ? "none"
                : "all",
          }}
        >
          <div className={styles.upperSidebar}>
            <div className={styles.logoSection}>
              <img src={VinfinityLogo} alt="Vinfinity Logo" />
            </div>
            <div className={styles.pageActionButtons}>
              <div className={styles.menuItemsBordered}>
                <div
                  className={styles.item}
                  style={
                    ["/purchasing", "/cart"].includes(window.location.pathname)
                      ? {
                          backgroundColor: getDefaultColour(defaultColour),
                          color: "#fff",
                        }
                      : {}
                  }
                  onClick={() => navigate("/purchasing")}
                >
                  <PurchasingWineIcon
                    color={
                      ["/purchasing", "/cart"].includes(
                        window.location.pathname
                      )
                        ? "#fff"
                        : "#000"
                    }
                  />
                  <span>{translations("label.purchase_wine")}</span>
                </div>

                {isLoggedIn() ? (
                  <div
                    className={styles.item}
                    style={
                      ["/journal", "/add-wine"].includes(
                        window.location.pathname
                      )
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {}
                    }
                    onClick={() => handleRedirect("/journal")}
                  >
                    <PrivateLedgerIcon
                      color={
                        ["/journal", "/add-wine"].includes(
                          window.location.pathname
                        )
                          ? "#fff"
                          : "#000"
                      }
                    />
                    <span>
                      {translations(
                        isMobile ? "label.journal" : "label.manage_journal"
                      )}
                    </span>
                  </div>
                ) : null}

                <div
                  className={styles.item}
                  style={
                    ["/investments", "/investments/topup"].includes(
                      window.location.pathname
                    )
                      ? {
                          backgroundColor: getDefaultColour(defaultColour),
                          color: "#fff",
                        }
                      : {}
                  }
                  onClick={() => navigate("/investments")}
                >
                  <InvestmentsIcon
                    color={
                      ["/investments", "/investments/topup"].includes(
                        window.location.pathname
                      )
                        ? "#fff"
                        : "#000"
                    }
                  />
                  <span>{translations("label.invest_in_wine")}</span>
                </div>
                {/* EVENTS COMMENTED */}
                {/* <div
                  className={styles.item}
                  style={
                    {
                      "--backgroundColor": getDefaultColour(defaultColour),
                    } as React.CSSProperties
                  }
                  onClick={() => navigate("/events")}
                >
                  {translations("label.attend_events")}
                </div> */}

                <div
                  className={styles.item}
                  style={
                    [
                      "/invite-referral",
                      "/games",
                      "/games/info",
                      "/games/question",
                      "/games/fill-in-the-blank",
                      "/games/true-or-false",
                      "/games/audio-questions",
                      "/games/pour-and-score",
                      "/games/magic-match",
                      "/games/match-the-pairs",
                    ].includes(window.location.pathname)
                      ? {
                          backgroundColor: getDefaultColour(defaultColour),
                          color: "#fff",
                        }
                      : {}
                  }
                  onClick={() => navigate("/games")}
                >
                  <GamificationIcon
                    color={
                      [
                        "/invite-referral",
                        "/games",
                        "/games/info",
                        "/games/question",
                        "/games/fill-in-the-blank",
                        "/games/true-or-false",
                        "/games/audio-questions",
                        "/games/pour-and-score",
                        "/games/magic-match",
                        "/games/match-the-pairs",
                      ].includes(window.location.pathname)
                        ? "#fff"
                        : "#000"
                    }
                  />
                  <span>
                    {translations(
                      translations(
                        isMobile ? "label.games" : "label.play_games"
                      )
                    )}
                  </span>
                </div>

                {isLoggedIn() ? (
                  <div
                    className={styles.item}
                    style={
                      [
                        "/membership",
                        "/membership/how-it-works",
                        "/referrals",
                      ].includes(window.location.pathname)
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {}
                    }
                    onClick={() => handleRedirect("/membership")}
                  >
                    <MembershipIcon
                      color={
                        [
                          "/membership",
                          "/membership/how-it-works",
                          "/referrals",
                        ].includes(window.location.pathname)
                          ? "#fff"
                          : "#000"
                      }
                    />
                    <span>{translations("label.membership")}</span>
                  </div>
                ) : null}

                {isLoggedIn() ? (
                  <div
                    className={styles.item}
                    style={
                      ["/wallet"].includes(window.location.pathname)
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {}
                    }
                    onClick={() => handleRedirect("/wallet")}
                  >
                    <WalletIcon
                      color={
                        ["/wallet"].includes(window.location.pathname)
                          ? "#fff"
                          : "#000"
                      }
                    />
                    <span>
                      {translations(
                        isMobile ? "label.wallet" : "label.menu_your_wallet"
                      )}
                    </span>
                  </div>
                ) : null}

                {isLoggedIn() ? (
                  <div
                    className={styles.item}
                    style={
                      ["/profile"].includes(window.location.pathname)
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {}
                    }
                    onClick={() => {
                      dispatch(setMiddlepartItem(null));
                      dispatch(setRightPartItem(null));
                      handleRedirect("/profile");
                    }}
                  >
                    <ProfileIcon
                      color={
                        ["/profile"].includes(window.location.pathname)
                          ? "#fff"
                          : "#000"
                      }
                    />
                    <span>
                      {translations(
                        isMobile ? "label.profile" : "label.your_profile"
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.bottomBar}>
            {isLoggedIn() ? (
              <Tooltip title={translations("label.logout")} placement="top">
                <div className={styles.logoutButton} onClick={handleLogout}>
                  <LogoutIcon color={"#000"} />
                </div>
              </Tooltip>
            ) : null}
            {!isLoggedIn() && window.location.pathname !== "/auth" ? (
              <>
                <Tooltip title={translations("label.login")} placement="top">
                  <div className="pointer" onClick={() => navigate("login")}>
                    <LoginIcon color={"#000"} />
                  </div>
                </Tooltip>
              </>
            ) : null}
            <Tooltip title={translations("label.language")} placement="top">
              <div
                className={styles.additionalItem}
                onClick={handleLanguageChangeModal}
              >
                <span>{langStored()}</span>
              </div>
            </Tooltip>
            {/* <Tooltip title={translations("label.terms")} placement="top">
              <div
                className={styles.additionalItem}
                onClick={() => navigate("/terms-of-use")}
              >
                <span>TC</span>
              </div>
            </Tooltip>
            <Tooltip
              title={translations("label.privacy_policy")}
              placement="top"
            >
              <div
                className={styles.additionalItem}
                onClick={() => navigate("/privacy-policy")}
              >
                <span>PP</span>
              </div>
            </Tooltip> */}
          </div>
        </div>

        {/* MOBILE (SIDEBAR) MENU */}
        {isMobile ? (
          <div
            className={styles.mobileSidebar}
            style={{ width: isMobileMenuOpen ? "70%" : "0" }}
          >
            <>
              <div
                className={`${styles.closeMenuIcon} closeMenuIcon`}
                onClick={() => {
                  window.Intercom("hide");
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                }}
              >
                <CloseNoBgIcon />
              </div>

              {isMobileMenuOpen ? (
                <div
                  className={styles.menuItems}
                  style={{
                    width: isMobileMenuOpen ? "100%" : "0",
                    display: isMobileMenuOpen ? "flex" : "none",
                  }}
                >
                  <div
                    className={styles.item}
                    onClick={() => handleRedirect("/purchasing")}
                  >
                    <div className={styles.menuIcon}>
                      <PurchasingWineIcon color={"#000"} />
                    </div>
                    <div className={styles.label}>
                      <span>{translations("label.purchasing")}</span>
                    </div>
                  </div>

                  {isLoggedIn() ? (
                    <div
                      className={styles.item}
                      onClick={() => handleRedirect("/journal")}
                    >
                      <div className={styles.menuIcon}>
                        <PrivateLedgerIcon color={"#000"} />
                      </div>
                      <div className={styles.label}>
                        <span>{translations("label.journal")}</span>
                      </div>
                    </div>
                  ) : null}

                  <div
                    className={styles.item}
                    onClick={() => handleRedirect("/investments")}
                  >
                    <div className={styles.menuIcon}>
                      <InvestmentsIcon color={"#000"} />
                    </div>
                    <div className={styles.label}>
                      <span>{translations("label.investments")}</span>
                    </div>
                  </div>

                  <div
                    className={styles.item}
                    onClick={() => handleRedirect("/games")}
                  >
                    <div className={styles.menuIcon}>
                      <GamificationIcon color={"#000"} />
                    </div>
                    <div className={styles.label}>
                      <span>{translations("label.gamification")}</span>
                    </div>
                  </div>

                  {isLoggedIn() ? (
                    <div
                      className={styles.item}
                      onClick={() => handleRedirect("/membership")}
                    >
                      <div className={styles.menuIcon}>
                        <MembershipIcon color={"#000"} />
                      </div>
                      <div className={styles.label}>
                        <span>{translations("label.membership")}</span>
                      </div>
                    </div>
                  ) : null}

                  {isLoggedIn() ? (
                    <div
                      className={styles.item}
                      onClick={() => handleRedirect("/wallet")}
                    >
                      <div className={styles.menuIcon}>
                        <WalletIcon color={"#000"} />
                      </div>
                      <div className={styles.label}>
                        <span>{translations("label.wallet")}</span>
                      </div>
                    </div>
                  ) : null}

                  {isLoggedIn() ? (
                    <div
                      className={styles.item}
                      onClick={() => handleRedirect("/profile")}
                    >
                      <div className={styles.menuIcon}>
                        <ProfileIcon color={"#000"} />
                      </div>
                      <div className={styles.label}>
                        <span>{translations("label.profile")}</span>
                      </div>
                    </div>
                  ) : null}

                  <div
                    className={styles.item}
                    onClick={() => handleOpenConcierge()}
                  >
                    <div className={styles.menuIcon}>
                      <ConciergeIcon color={"#000"} />
                    </div>
                    <div className={styles.label}>
                      <span>{translations("label.concierge")}</span>
                    </div>
                  </div>
                </div>
              ) : null}

              {isMobileMenuOpen ? (
                isLoggedIn() ? (
                  <>
                    <div
                      className={styles.menuOpenMobileFirstIcon}
                      onClick={() => {
                        if (isLoggedIn()) {
                          setIsMobileMenuOpen(false);
                          navigate("/invite-referral");
                        } else {
                          setIsMobileMenuOpen(false);
                          navigate("/login", {
                            state: {
                              lastRoute: "/invite-referral",
                            },
                          });
                        }
                      }}
                    >
                      <ReferralsIcon color={getDefaultColour(defaultColour)} />
                    </div>

                    <div
                      className={styles.menuOpenMobileNdIcon}
                      onClick={() => {
                        if (isLoggedIn()) handleRedirect("/add-wine");
                        else {
                          setIsMobileMenuOpen(false);
                          navigate("/login", {
                            state: {
                              lastRoute: "/add-wine",
                            },
                          });
                        }
                      }}
                    >
                      <EditPencilIcon color="#fff" />
                    </div>

                    <div
                      className={styles.menuOpenMobileRdIcon}
                      onClick={() => handleRedirect("/cart")}
                    >
                      <ShoppingCartHeaderIcon
                        color={getDefaultColour(defaultColour)}
                      />
                      <Badge
                        className={styles.badge}
                        badgeContent={cartItems?.length}
                        color="info"
                        style={{ backgroundColor: "#fff" }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={styles.authMobileIcon}
                      onClick={() => handleAuthBtnClick()}
                    >
                      {isLoggedIn() ? (
                        <LogoutIcon color={"#fff"} />
                      ) : (
                        <LoginIcon color={"#fff"} />
                      )}
                    </div>
                    <div
                      className={styles.mobileChangeLanguageButton}
                      onClick={handleLanguageChangeModal}
                    >
                      <span>{langStored()}</span>
                    </div>
                  </>
                )
              ) : null}
            </>
          </div>
        ) : null}

        {/* REFERRALS MOBILE ICON */}
        {shouldHideMobileMenu
          ? null
          : !isMobileMenuOpen &&
            ["/games", "/guest-signup"].includes(window.location.pathname) &&
            isMobile && (
              <div
                className={styles.referralsIcon}
                onClick={() => {
                  if (isLoggedIn()) {
                    navigate("/invite-referral");
                  } else {
                    navigate("/login", {
                      state: {
                        lastRoute: "/invite-referral",
                      },
                    });
                  }
                }}
              >
                <ReferralsIcon color={getDefaultColour(defaultColour)} />
              </div>
            )}

        {/* INVESTMENTS PLUS MOBILE ICON */}
        {shouldHideMobileMenu
          ? null
          : !isMobileMenuOpen &&
            window.location.pathname === "/investments" &&
            isMobile && (
              <div
                className={styles.investmentsPlusIcon}
                onClick={() => handleInvestmentsPlusClick()}
              >
                <PlusIcon color={getDefaultColour(defaultColour)} />
              </div>
            )}

        {/* PURCHASING SHOPPING CART MOBILE ICON */}
        {shouldHideMobileMenu
          ? null
          : !isMobileMenuOpen &&
            ["/purchasing"].includes(window.location.pathname) &&
            isMobile && (
              <div className={styles.purchasingIconMobile}>
                <div
                  className={styles.searchIconMobile}
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    searchParams.set("search", "true");
                    setSearchParams(searchParams);
                  }}
                >
                  <NewSearchIcon color="#fff" />
                </div>
                <div
                  className={styles.filtersIcon}
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setShowPurchasingFilters(true);
                  }}
                >
                  <FiltersIcon color={"#fff"} />
                  <Badge
                    className={styles.badge}
                    badgeContent={Number(getFiltersSelectedCount(filters))}
                    color="info"
                    style={{ backgroundColor: "#fff" }}
                  />
                </div>
                <div
                  className={styles.bookmarksIcon}
                  onClick={() => handleRedirect("/bookmarks")}
                >
                  <BookmarksIcon color={"#fff"} />
                  <Badge
                    className={styles.badge}
                    badgeContent={productBookmarks?.length}
                    color="info"
                    style={{ backgroundColor: "#fff" }}
                  />
                </div>
                <div
                  className={styles.cartIconMobile}
                  onClick={() => navigate("/cart")}
                >
                  <ShoppingCartHeaderIcon
                    color={getDefaultColour(defaultColour)}
                  />
                  <Badge
                    className={styles.badge}
                    badgeContent={cartItems?.length}
                    color="info"
                    style={{ backgroundColor: "#fff" }}
                  />
                </div>
              </div>
            )}

        {/* PRIVATE LEDGER PLUS MOBILE ICON */}
        {shouldHideMobileMenu
          ? null
          : !isMobileMenuOpen &&
            [
              "/journal",
              "/journal/product-details",
              "/journal/transactions",
              "/journal/rate-and-taste",
            ].includes(window.location.pathname) &&
            isMobile && (
              <div
                className={styles.privateLedgerMobileIcons}
                onClick={() => handlePrivateLedgerScanIconClick()}
              >
                {/* <div className={styles.scanIconMenu}>
                  <ScanDocumentIcon />
                </div> */}
                <div
                  className={styles.manualEnterIcon}
                  onClick={() => navigate("/add-wine")}
                >
                  <EditPencilIcon color="#fff" />
                </div>
              </div>
            )}

        {/* MOBILE MENU END */}

        <div
          className={styles.appWrapper}
          style={{
            display: isMobileMenuOpen ? "none" : "block",
          }}
        >
          <div
            className={styles.appHeader}
            style={{
              zIndex: isMobileMenuOpen ? "99" : "unset",
              opacity: show1kMedalsPopup ? 0.7 : 1,
              pointerEvents: show1kMedalsPopup ? "none" : "all",
            }}
          >
            {/* TOP BAR USED FOR DEKSTOP FULLSCREEN */}
            <div className={styles.desktopHeader}>
              {window.location.pathname === "/events" ? (
                <div className={"eventFilters"} style={{ margin: "0 auto" }}>
                  <div
                    className={"filter"}
                    style={
                      eventSelectedCategory === "All"
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            width: 50,
                          }
                        : {
                            backgroundColor: "#fff",
                            width: 50,
                          }
                    }
                    onClick={() => dispatch(clearEventsFilter())}
                  >
                    <AllCategoriesIcon
                      color={
                        eventSelectedCategory === "All"
                          ? "#fff"
                          : getDefaultColour(defaultColour)
                      }
                    />
                  </div>
                  <div
                    className={"filter"}
                    style={
                      eventSelectedCategory === "Wine"
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {
                            backgroundColor: "#fff",
                            color: getDefaultColour(defaultColour),
                          }
                    }
                    onClick={() => dispatch(setEventsFilter("Wine"))}
                  >
                    <span>{translations("label.wine")}</span>
                  </div>
                  <div
                    className={"filter"}
                    style={
                      eventSelectedCategory === "Lifestyle"
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {
                            backgroundColor: "#fff",
                            color: getDefaultColour(defaultColour),
                          }
                    }
                    onClick={() => dispatch(setEventsFilter("Lifestyle"))}
                  >
                    <span>{translations("label.lifestyle")}</span>
                  </div>
                  <div
                    className={"filter"}
                    style={
                      eventSelectedCategory === "Finance"
                        ? {
                            backgroundColor: getDefaultColour(defaultColour),
                            color: "#fff",
                          }
                        : {
                            backgroundColor: "#fff",
                            color: getDefaultColour(defaultColour),
                          }
                    }
                    onClick={() => dispatch(setEventsFilter("Finance"))}
                  >
                    <span>{translations("label.finance")}</span>
                  </div>
                </div>
              ) : null}

              {["/purchasing"].includes(window.location.pathname) ? (
                <div
                  className={styles.threePartsHeader}
                  style={
                    {
                      "--color": getDefaultColour(defaultColour),
                    } as React.CSSProperties
                  }
                >
                  <div className={styles.listTabs}>
                    <div
                      style={{
                        backgroundColor:
                          streamParam === "map" ? "#000" : "#E6EBEF",
                      }}
                      onClick={() => handleStreamTabChange("map")}
                    >
                      <LocationMapIcon
                        color={streamParam === "map" ? "#fff" : "#000"}
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          streamParam === "list" ? "#000" : "#E6EBEF",
                      }}
                      onClick={() => handleStreamTabChange("list")}
                    >
                      <ListStreamMap
                        color={streamParam === "list" ? "#fff" : "#000"}
                      />
                    </div>
                  </div>
                  <div className={styles.purchasingFiltersDesktop}>
                    <div
                      className={styles.searchInput}
                      style={isSearching ? { width: "30%" } : {}}
                    >
                      <SearchIcon color={"#000"} />
                      <input
                        type="text"
                        placeholder={translations("label.search")}
                        value={
                          !isSearching
                            ? searchValue.length > 8
                              ? searchValue.substring(0, 8) + "..."
                              : searchValue
                            : searchValue
                        }
                        onBlur={(e) => setIsSearching(false)}
                        onFocus={(e) => setIsSearching(true)}
                        onChange={(e) => handleSearchValue(e.target.value)}
                      />
                    </div>

                    <div className={styles.sort}>
                      <SortingFilter
                        label={translations("label.sort")}
                        options={getSortingFilters()}
                      />
                    </div>

                    <MultiSelectDropdown
                      type="Type"
                      label={translations("label.type")}
                      options={typeFilterOptions}
                    />

                    <MultiSelectDropdown
                      type="Price"
                      label={translations("label.price")}
                      options={[
                        `${translations("label.below")} 25 ${currency}`,
                        `${translations("label.from")} 25 - 35 ${currency}`,
                        `${translations("label.from")} 35 - 50 ${currency}`,
                        `${translations("label.from")} 50 - 100 ${currency}`,
                        `${translations("label.from")} 100 - 500 ${currency}`,
                        `${translations("label.above")} 500 ${currency}`,
                      ]}
                    />
                    <MultiSelectDropdown
                      type="Country"
                      label={translations("label.country")}
                      options={countryFilterOptions}
                    />

                    {/* <MultiSelectDropdown
                      type="Vintage"
                      label={translations("label.vintage")}
                      options={vintageFilterOptions}
                    /> */}

                    <div
                      className={styles.moreFiltersButton}
                      onClick={() => handleViewMoreFilters()}
                    >
                      <span>
                        {translations("label.more_filters")}
                        {Number(getFiltersSelectedCount(filters)) !== 0
                          ? " (" +
                            Number(getFiltersSelectedCount(filters)) +
                            ")"
                          : null}
                      </span>
                    </div>
                  </div>

                  <div className={styles.rightSideButtons}>
                    <div
                      className={styles.bookmarksIconHeader}
                      onClick={() => navigate("/bookmarks")}
                    >
                      <Badge
                        className={styles.badge}
                        badgeContent={productBookmarks?.length}
                        color="info"
                        style={{ backgroundColor: "#fff" }}
                      />
                      <BookmarksIcon color="#000" />
                    </div>
                    <div
                      className={styles.cartIconHeader}
                      onClick={() => navigate("/cart")}
                    >
                      <ShoppingCartHeaderIcon
                        color={getDefaultColour(defaultColour)}
                      />
                      <Badge
                        className={styles.badge}
                        badgeContent={cartItems?.length}
                        color="info"
                        style={{ backgroundColor: "#fff" }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {["/cart", "/bookmarks"].includes(window.location.pathname) ? (
                <div
                  className={styles.closeButton}
                  onClick={() => navigate("/purchasing")}
                >
                  <CloseFirstTypeIcon color={getDefaultColour(defaultColour)} />
                </div>
              ) : null}

              {window.location.pathname === "/investments" ? (
                <div
                  className={styles.threePartsHeader}
                  style={
                    {
                      "--color": getDefaultColour(defaultColour),
                    } as React.CSSProperties
                  }
                >
                  {isLoggedIn() ? (
                    <div>
                      <div>
                        <span className={styles.smallLabel}>
                          {translations("label.staked")}
                        </span>
                      </div>
                      <div>
                        <span className={styles.stakedValue}>
                          {getCurrencyStr(client?.currency)} {stakedValue}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div></div>
                  <div
                    className={`${styles.investmentsIcon} pointer`}
                    onClick={() => handleInvestmentsPlusClick()}
                  >
                    <PlusIcon color={getDefaultColour(defaultColour)} />
                  </div>
                </div>
              ) : null}

              {[
                "/games",
                "/games/info",
                "/games/question",
                "/games/fill-in-the-blank",
                "/games/true-or-false",
                "/games/audio-questions",
                "/games/pour-and-score",
                "/games/magic-match",
                "/games/match-the-pairs",
              ].includes(window.location.pathname) ? (
                <div className={styles.gamificationHeader}>
                  <div className={styles.shownMedals}>
                    <span>{translations("label.status")}</span>
                    <span>{client?.membership?.name}</span>
                  </div>

                  <div className={styles.rightPartHeader}>
                    {window.location.pathname !== "/games/info" ? (
                      <div
                        className={styles.learnMore}
                        onClick={() => navigate("/games/info")}
                      >
                        <div>
                          <span>
                            {translations("label.learn_more_about") +
                              " " +
                              translations("label.games")}
                          </span>
                        </div>
                        <div>
                          <HowItWorksIcon />
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={styles.desktopReferralsIcon}
                      onClick={() => {
                        navigate("/invite-referral");
                      }}
                    >
                      <ReferralsIcon color={getDefaultColour(defaultColour)} />
                    </div>
                  </div>
                </div>
              ) : null}

              {window.location.pathname === "/wallet" ? (
                <div className="walletTabs">
                  <WalletTabs />
                </div>
              ) : null}

              {["/journal"].includes(window.location.pathname) ? (
                <div className={styles.threePartsHeader}>
                  <div></div>
                  <div className={styles.ledgerHeader}>
                    {(isMobile && !isLedgerSearching) || !isMobile ? (
                      <>
                        <div className={styles.lSearch}>
                          <input
                            type="text"
                            className={styles.searchInp}
                            placeholder={
                              isMobile ? "" : translations("label.search")
                            }
                            style={{
                              width:
                                isLedgerSearching ||
                                ledgerSearchValue?.length > 0
                                  ? 300
                                  : 85,
                            }}
                            onBlur={(e) => setIsLedgerSearching(false)}
                            onFocus={(e) => setIsLedgerSearching(true)}
                            onChange={(e) =>
                              setLedgerSearchValue(e.target.value)
                            }
                          />
                          <SearchIcon color="#000" />
                        </div>
                        {!isLedgerSearching && !ledgerSearchValue ? (
                          <div
                            className={styles.tab}
                            style={{
                              backgroundColor:
                                ledgerActiveTab === "vinfinity-wines"
                                  ? "#000"
                                  : "transparent",
                              color:
                                ledgerActiveTab === "vinfinity-wines"
                                  ? "#fff"
                                  : "#000",
                            }}
                            onClick={() =>
                              handleSetActiveTab("vinfinity-wines")
                            }
                          >
                            <span>{translations("label.vinfinity_wines")}</span>
                          </div>
                        ) : null}
                        {/* <div
                          className={styles.tab}
                          style={{
                            backgroundColor:
                              ledgerActiveTab === "tastings"
                                ? "#000"
                                : "transparent",
                            color:
                              ledgerActiveTab === "tastings" ? "#fff" : "#000",
                          }}
                          onClick={() => handleSetActiveTab("tastings")}
                        >
                          <span>{translations("label.tastings")}</span>
                        </div> */}
                        {!isLedgerSearching && !ledgerSearchValue ? (
                          <div
                            className={styles.tab}
                            style={{
                              backgroundColor:
                                ledgerActiveTab === "added-wines"
                                  ? "#000"
                                  : "transparent",
                              color:
                                ledgerActiveTab === "added-wines"
                                  ? "#fff"
                                  : "#000",
                            }}
                            onClick={() => handleSetActiveTab("added-wines")}
                          >
                            <span>{translations("label.added_wines")}</span>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div></div>
                    )}
                    <div></div>
                  </div>
                  <div className={styles.rightSideButtons}>
                    <div
                      className={styles.cartSecondaryIcon}
                      onClick={() => navigate("/cart")}
                    >
                      <ShoppingCartSecondaryIcon />
                      <Badge
                        className={styles.badge}
                        badgeContent={cartItems?.length}
                        color="info"
                        style={{ backgroundColor: "#fff" }}
                      />
                    </div>
                    {/* <div className={styles.scanIcon}>
                      <ScanDocumentIcon />
                    </div> */}

                    <div
                      className={styles.manualEnterDataIcon}
                      onClick={() => navigate("/add-wine")}
                    >
                      <EditPencilIcon color="#fff" />
                    </div>
                  </div>
                </div>
              ) : null}

              {["/membership"].includes(window.location.pathname) ? (
                <div className={styles.gamificationHeader}>
                  <div className={styles.shownMedals}>
                    <span>{translations("label.status")}</span>
                    <span>{client?.membership?.name}</span>
                  </div>

                  <div className={styles.rightPartHeader}>
                    <div
                      className={styles.learnMore}
                      onClick={() => navigate("/membership/how-it-works")}
                    >
                      <div>
                        <span>{translations("label.learn_how_it_works")}</span>
                      </div>
                      <div>
                        <HowItWorksIcon />
                      </div>
                    </div>
                    <div
                      className={styles.desktopReferralsIcon}
                      onClick={() => {
                        navigate("/invite-referral");
                      }}
                    >
                      <ReferralsIcon color={getDefaultColour(defaultColour)} />
                    </div>
                  </div>
                </div>
              ) : null}

              {["/membership/how-it-works"].includes(
                window.location.pathname
              ) ? (
                <div className={styles.threePartsHeader}>
                  <div></div>

                  <div className={styles.membershipTabs}>
                    <div
                      style={{
                        backgroundColor:
                          membershipActiveView === "games"
                            ? "#000"
                            : "transparent",
                        color:
                          membershipActiveView === "games" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        searchParams.set("view", "games");
                        setSearchParams(searchParams);
                      }}
                    >
                      <span>{translations("label.games")}</span>
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          membershipActiveView === "medals"
                            ? "#000"
                            : "transparent",
                        color:
                          membershipActiveView === "medals" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        searchParams.set("view", "medals");
                        setSearchParams(searchParams);
                      }}
                    >
                      <span>{translations("label.medals")}</span>
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          membershipActiveView === "status"
                            ? "#000"
                            : "transparent",
                        color:
                          membershipActiveView === "status" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        searchParams.set("view", "status");
                        setSearchParams(searchParams);
                      }}
                    >
                      <span>{translations("label.status")}</span>
                    </div>
                  </div>

                  <div className={styles.rightPartHeader}>
                    <div
                      className={styles.desktopReferralsIcon}
                      onClick={() => {
                        navigate("/invite-referral");
                      }}
                    >
                      <ReferralsIcon color={getDefaultColour(defaultColour)} />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* TOP BAR FOR MOBILE SCREEN */}
            {isMobileMenuOpen ? (
              <MobileHeader
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                setShowPurchasingFilters={setShowPurchasingFilters}
              />
            ) : null}
          </div>

          {/* MOBILE THREE MENUS */}
          {/* {shouldShowTopBar && !showBackButton ? (
            <div className={styles.mHeader}>
              <div className={styles.icons}>
                <div
                  style={{
                    border:
                      pathname === "/purchasing"
                        ? `1px solid ${getDefaultColour(defaultColour)}`
                        : "1px solid transparent",
                  }}
                  onClick={() => handleRedirect("/purchasing")}
                >
                  <PurchasingWineIcon color={getDefaultColour(defaultColour)} />
                </div>
                <div
                  style={{
                    border: pathname.includes("/gamification")
                      ? `1px solid ${getDefaultColour(defaultColour)}`
                      : "1px solid transparent",
                  }}
                  onClick={() => handleRedirect("/gamification")}
                >
                  <GamificationIcon color={getDefaultColour(defaultColour)} />
                </div>
                <div
                  style={{
                    border:
                      pathname === "/investments"
                        ? `1px solid ${getDefaultColour(defaultColour)}`
                        : "1px solid transparent",
                  }}
                  onClick={() => handleRedirect("/investments")}
                >
                  <InvestmentsIcon color={getDefaultColour(defaultColour)} />
                </div>
              </div>
            </div>
          ) : null} */}

          {shouldShowPurchasingFilters &&
          window.location.pathname === "/purchasing" ? (
            <div
              className={styles.purchasingFiltersMobile}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
            >
              <div className={styles.content}>
                <div>
                  <div className={styles.header}>
                    <div onClick={() => setShowPurchasingFilters(false)}>
                      <BackButtonIcon color={getDefaultColour(defaultColour)} />
                    </div>
                  </div>

                  <div className={styles.purchasingFilters}>
                    {isMobile ? null : (
                      <div className={styles.searchInput}>
                        <SearchIcon color="#000" />
                        <input
                          type="text"
                          value={
                            !isSearching
                              ? searchValue.length > 8
                                ? searchValue.substring(0, 8) + "..."
                                : searchValue
                              : searchValue
                          }
                          onBlur={(e) => setIsSearching(false)}
                          onFocus={(e) => setIsSearching(true)}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      </div>
                    )}
                    <MultiSelectDropdown
                      type="Type"
                      label={translations("label.type")}
                      options={typeFilterOptions}
                    />
                    <MultiSelectDropdown
                      type="Price"
                      label={translations("label.price")}
                      options={[
                        `${translations("label.below")} 25 ${currency}`,
                        `${translations("label.from")} 25 - 35 ${currency}`,
                        `${translations("label.from")} 35 - 50 ${currency}`,
                        `${translations("label.from")} 50 - 100 ${currency}`,
                        `${translations("label.from")} 100 - 500 ${currency}`,
                        `${translations("label.above")} 500 ${currency}`,
                      ]}
                    />
                    <MultiSelectDropdown
                      type="Country"
                      label={translations("label.country")}
                      options={countryFilterOptions}
                    />
                    {showRegionFilter ? (
                      <MultiSelectDropdown
                        type="Region"
                        label={translations("label.region")}
                        options={regionFilterOptions}
                      />
                    ) : null}
                    {appellationsFilterOptions?.length !== 0 &&
                    showAppellationFilter ? (
                      <MultiSelectDropdown
                        type="Appellation"
                        label={translations("label.appellation")}
                        options={appellationsFilterOptions}
                      />
                    ) : null}
                    <MultiSelectDropdown
                      type="Grapes"
                      label={translations("label.grapes")}
                      options={grapesFilterOptions}
                    />
                    <MultiSelectDropdown
                      type="Producer"
                      label={translations("label.producer")}
                      options={producersFilterOptions}
                    />

                    <MultiSelectDropdown
                      type="Vintage"
                      label={translations("label.year")}
                      options={vintageFilterOptions}
                    />

                    <MultiSelectDropdown
                      type="Pairing"
                      label={translations("label.pairing")}
                      options={pairingFilterOptions}
                    />
                    <SortingFilter
                      label={translations("label.sort")}
                      options={getSortingFilters()}
                    />
                  </div>
                </div>

                <div className={styles.filterButtons}>
                  <div
                    className={styles.applyFilterButton}
                    onClick={() => handleApplyFiltersClick()}
                  >
                    <span>{translations("label.apply")}</span>
                  </div>
                  <div
                    className={styles.clearFiltersButton}
                    onClick={() => handleClearFilters()}
                  >
                    {translations("button.reset")}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* <PullToRefresh onRefresh={handlePullToRefresh}> */}
          <div
            className={`${styles.appWrapperContent} ${
              hideBackButton || isMobileMenuOpen ? styles.fullHeight : null
            }`}
            style={{
              top: isMobile ? 0 : 150,
              overflow: wineStreamParam === "map" ? "" : "auto",
              opacity: show1kMedalsPopup ? 0.7 : 1,
              pointerEvents: show1kMedalsPopup ? "none" : "all",
            }}
            ref={appWrapperContentRef}
            onScroll={(e) => handleScroll(e)}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                position: "absolute",
                backgroundColor: "#FF6333",
                display: isRefreshing ? "block" : "none",
                transition: "all .3s linear",
                zIndex: "9999999999",
              }}
            >
              <LoaderBounce />
            </div>
            {isMobile &&
            !isMobileMenuOpen &&
            ![
              "/auth",
              "/login",
              "/login/phone",
              "/login/passcode",
              "/login/password",
              "/login/auth-sms",
              "/signup",
              "/signup/passcode",
              "/signup/phone",
              "/signup/auth-sms",
              "/signup/personal-details",
              "/signup/finish",
              "/auth/alternative",
              "/purchasing/product",
              "/order/summary",
              "/purchasing/payment",
              "/bonus",
              "/guest-signup",
              "/membership/how-it-works",
              "/product/promotion",
              "/cart/lucky-winner",
              "/cart/takeover",
            ].includes(window.location.pathname) ? (
              <MobileHeader
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                setShowPurchasingFilters={setShowPurchasingFilters}
              />
            ) : null}

            <div
              className={styles.contentContainer}
              style={{
                opacity: isMobileMenuOpen ? 0 : 1,
                pointerEvents: isMobileMenuOpen ? "none" : "all",
                paddingBottom: 30,
              }}
            >
              <Outlet />
            </div>
          </div>
          {/* </PullToRefresh> */}
        </div>
        {show1kMedalsPopup ? (
          <div className={styles.earnMedalsPopup}>
            <div
              className={styles.header}
              onClick={() => {
                Cookies.set("1kmedals_popup_closed", true, { expires: 1 });
                setShow1kMedalsPopup(false);
                window.location.reload();
              }}
            >
              <CloseIconWhite />
            </div>
            <div className={styles.medalsWrapper}>
              <GoldCoinIcon />
              <div>
                <h1>{translations("label.get_1000_medals")}</h1>
                <span>{translations("label.get_1000_medals.hint")}</span>
              </div>
              <ButtonComponent
                label={translations("label.order_now")}
                class={"btn btn-primary"}
                style={{ color: "#000", backgroundColor: "#fff" }}
                handleClick={() => {
                  Cookies.set("1kmedals_popup_closed", true, { expires: 1 });
                  setShow1kMedalsPopup(false);
                  navigate("/cart");
                }}
              />
            </div>
          </div>
        ) : null}
        <ModalComponent />
      </div>

      {showOnlyInSwitzerland() &&
      !isMobileMenuOpen &&
      window.location.pathname === "/purchasing" ? (
        <div className={styles.onlyInChBox}>
          <div className={styles.leftPart}>
            <div>
              <ChFlagIcon />
            </div>
            <div className={styles.middlePart}>
              <h3>{translations("label.only_available_in_ch")}</h3>
              <span>{translations("label.only_available_in_ch.subtitle")}</span>
            </div>
          </div>
          <div
            className={styles.closeButton}
            onClick={() => handleHideOnlyAvailableInChPopup()}
          >
            X
          </div>
        </div>
      ) : null}

      {!isMobile && !isLoggedIn() && showFooterCookie() ? (
        <div className={styles.footer}>
          <div className={styles.leftPart}>
            <div
              className={styles.closeIcon}
              onClick={() => handleHideFooter()}
            >
              <CloseIconWhiteBg />
            </div>
            <div onClick={() => navigate("/terms-of-use")}>
              <span>
                {isMobile ? "TC" : translations("label.terms_of_service")}
              </span>
            </div>
            <div onClick={() => navigate("/privacy-policy")}>
              <span>
                {isMobile ? "PP" : translations("label.privacy_policy")}
              </span>
            </div>
            <div onClick={() => navigate("/impressum")}>
              <span>{translations("label.impressum")}</span>
            </div>
          </div>
          <div
            className={styles.rightPart}
            onClick={() => window.open("https://www.vinfinity.ch", "_blank")}
          >
            <span>{translations("label.about_vinfinity")}</span>
            <UpperIcon />
          </div>
        </div>
      ) : null}

      <ToastContainer />
    </>
  );
};
export default AppWrapperMiddleware;
