import React from "react";
import styles from "./GameCardFlip.module.scss";

interface Card {
  id: number;
  src: string;
  matched: boolean;
}

interface GameCardFlipProps {
  card: Card;
  handleChoice: (card: Card) => void;
  flipped: boolean;
  disabled: boolean;
}

const GameCardFlip: React.FC<GameCardFlipProps> = ({
  card,
  handleChoice,
  flipped,
  disabled,
}) => {
  const handleClick = () => {
    if (!disabled && !flipped) {
      handleChoice(card);
    }
  };

  return (
    <div className={styles.card} onClick={handleClick}>
      <div className={`${styles.inner} ${flipped ? styles.flipped : ""}`}>
        <div className={styles.front}>
          <img src={card.src} alt="card front" />
        </div>
        <div className={styles.back}></div>
      </div>
    </div>
  );
};

export default GameCardFlip;
