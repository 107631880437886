import { useTranslation } from "react-i18next";
import styles from "./Login.module.scss";
import { PreviousIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput.component";
import ButtonComponent from "components/Button/Button.component";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import { closeModal, openModal, openToaster } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import { checkIfHasPasscodeBegin, clearAction } from "../actions";
import { CHECK_IF_HAS_PASSCODE_SUCCESS } from "../constants";
import StorageService from "services/storage.service";

export default function Login() {
  const { defaultColour, termsAndConditions, privacyPolicy } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
    hasPasscode,
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const [email, setEmail] = useState("");
  const showSignupPopup = () =>
    StorageService.getDataByKey("show_signup_popup");

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handlePreviousButton = () => {
    navigate("/purchasing");
  };

  const handleTermsAndConditionsPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className={styles.shownText}
            dangerouslySetInnerHTML={{
              __html: termsAndConditions?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handlePrivacyPolicyPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className="shownText"
            dangerouslySetInnerHTML={{
              __html: privacyPolicy?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handleSubmit = () => {
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ||
      /(\.\.)/.test(email)
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.special_characters_not_allowed")
        )
      );
      return;
    }

    navigate(`/signup/passcode?email=${email}`);
  };

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const handleLogin = () => {
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ||
      /(\.\.)/.test(email)
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.special_characters_not_allowed")
        )
      );
      return;
    }

    dispatch(
      checkIfHasPasscodeBegin({
        email: email,
        sign_up: false,
        lang: getLanguage().toLowerCase(),
      })
    );
  };

  const handleProceedToSignup = () => {
    dispatch(closeModal());
    navigate("/signup");
  };

  const handleOpenSignupPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            style={
              {
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties
            }
            className={styles.signUpPopup}
          >
            <div>
              <h1>{translations("error.client_doesnt_exists")}</h1>
              <span>{translations("label.do_you_want_to_register?")}</span>
            </div>
            <div className={styles.buttons}>
              <ButtonComponent
                label={translations("label.no")}
                class={"btn btn-primary"}
                handleClick={() => dispatch(closeModal())}
              />
              <ButtonComponent
                label={translations("label.yes")}
                class={"btn btn-primary"}
                handleClick={() => handleProceedToSignup()}
              />
            </div>
          </div>
        ),
        type: modalTypes.sm,
      })
    );
    StorageService.setKeyValue("show_signup_popup", false);
  };

  useEffect(() => {
    if (actionName === CHECK_IF_HAS_PASSCODE_SUCCESS) {
      navigate(
        hasPasscode
          ? `/login/passcode?email=${email}`
          : `/login/password?email=${email}`
      );
    }

    return () => {
      dispatch(clearAction());
    };
  }, [actionName, hasPasscode]);

  useEffect(() => {
    if (showSignupPopup()) {
      handleOpenSignupPopup();
    }
  }, [showSignupPopup()]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <div
            className={styles.previousIcon}
            onClick={() => {
              handlePreviousButton();
            }}
          >
            <PreviousIcon color={getDefaultColour(defaultColour)} />
          </div>
          <div className={styles.link} onClick={() => navigate("/signup")}>
            <span>{translations("label.new_here")}</span>
            <a>{translations("button.sign_up")}</a>
          </div>
        </div>
        <div className={styles.authContent}>
          <h1>{translations("label.login")}</h1>

          <div className={styles.emailField}>
            <CustomInput
              type="email"
              name="email"
              label={translations("label.your_email_address")}
              value={email}
              onKeyDown={(e) => onKeyDown(e)}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={styles.submitButton}>
            <ButtonComponent
              label={translations("button.log_in")}
              class={"btn btn-primary"}
              isLoading={isLoading}
              handleClick={() => handleLogin()}
            />
          </div>

          <div
            className={styles.loginMethods}
            onClick={() => navigate("/auth/alternative?type=login")}
          >
            <span>{translations("label.alternative_login_methods")}</span>
          </div>

          <div className={styles.agreementHint}>
            <span>{translations("label.by_signing,you_agree_to_the")}</span>
            <span
              className={styles.link}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              onClick={() => handleTermsAndConditionsPopup()}
            >
              {translations("label.terms_of_use")}
            </span>
            <span>{translations("label.and")}</span>
            <span
              className={styles.link}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              onClick={() => handlePrivacyPolicyPopup()}
            >
              {translations("label.privacy_policy")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
