const scope = "vinfinity/purchasing";

export const POPULATE_CART_ITEMS = `${scope}/POPULATE_CART_ITEMS`;
export const SET_CART_ITEMS = `${scope}/SET_CART_ITEMS`;
export const DELETE_CART_ITEM = `${scope}/DELETE_CART_ITEM`;
export const DELETE_CART_ITEMS = `${scope}/DELETE_CART_ITEMS`;
export const CLEAR_CART_ITEMS = `${scope}/CLEAR_CART_ITEMS`;
export const UPDATE_QUANTITY = `${scope}/UPDATE_QUANTITY`;

export const FETCH_PRODUCTS_BEGIN = `${scope}/FETCH_PRODUCTS_BEGIN`;
export const FETCH_PRODUCTS_SUCCESS = `${scope}/FETCH_PRODUCTS_SUCCESS`;
export const FETCH_PRODUCTS_FAILED = `${scope}/FETCH_PRODUCTS_FAILED`;

export const FETCH_PRODUCT_DETAILS_BEGIN = `${scope}/FETCH_PRODUCT_DETAILS_BEGIN`;
export const FETCH_PRODUCT_DETAILS_SUCCESS = `${scope}/FETCH_PRODUCT_DETAILS_SUCCESS`;
export const FETCH_PRODUCT_DETAILS_FAILED = `${scope}/FETCH_PRODUCT_DETAILS_FAILED`;

export const UPDATE_SHIPMENT_ADDRESS_BEGIN = `${scope}/UPDATE_SHIPMENT_ADDRESS_BEGIN`;
export const UPDATE_SHIPMENT_ADDRESS_SUCCESS = `${scope}/UPDATE_SHIPMENT_ADDRESS_SUCCESS`;
export const UPDATE_SHIPMENT_ADDRESS_FAILED = `${scope}/UPDATE_SHIPMENT_ADDRESS_FAILED`;

export const ORDER_BEGIN = `${scope}/ORDER_BEGIN`;
export const ORDER_SUCCESS = `${scope}/ORDER_SUCCESS`;
export const ORDER_FAILED = `${scope}/ORDER_FAILED`;

export const ORDER_AS_GUEST_BEGIN = `${scope}/ORDER_AS_GUEST_BEGIN`;
export const ORDER_AS_GUEST_SUCCESS = `${scope}/ORDER_AS_GUEST_SUCCESS`;
export const ORDER_AS_GUEST_FAILED = `${scope}/ORDER_AS_GUEST_FAILED`;

export const SEND_ORDER_INVOICE_BEGIN = `${scope}/SEND_ORDER_INVOICE_BEGIN`;
export const SEND_ORDER_INVOICE_SUCCESS = `${scope}/SEND_ORDER_INVOICE_SUCCESS`;
export const SEND_ORDER_INVOICE_FAILED = `${scope}/SEND_ORDER_INVOICE_FAILED`;

export const SEND_GUEST_ORDER_INVOICE_BEGIN = `${scope}/SEND_GUEST_ORDER_INVOICE_BEGIN`;
export const SEND_GUEST_ORDER_INVOICE_SUCCESS = `${scope}/SEND_GUEST_ORDER_INVOICE_SUCCESS`;
export const SEND_GUEST_ORDER_INVOICE_FAILED = `${scope}/SEND_GUEST_ORDER_INVOICE_FAILED`;

export const GET_CUSTOMER_PORTAL_BEGIN = `${scope}/GET_CUSTOMER_PORTAL_BEGIN`;
export const GET_CUSTOMER_PORTAL_SUCCESS = `${scope}/GET_CUSTOMER_PORTAL_SUCCESS`;
export const GET_CUSTOMER_PORTAL_FAILED = `${scope}/GET_CUSTOMER_PORTAL_FAILED`;

export const SET_FILTERS = `${scope}/SET_FILTERS`;
export const POPULATE_FILTERS = `${scope}/POPULATE_FILTERS`;
export const CLEAR_FILTERS = `${scope}/CLEAR_FILTERS`;

export const GET_PURCHASING_FILTERS_BEGIN = `${scope}/GET_PURCHASING_FILTERS_BEGIN`;
export const GET_PURCHASING_FILTERS_SUCCESS = `${scope}/GET_PURCHASING_FILTERS_SUCCESS`;
export const GET_PURCHASING_FILTERS_FAILED = `${scope}/GET_PURCHASING_FILTERS_FAILED`;

export const GET_REGIONS_FILTERS_BEGIN = `${scope}/GET_REGIONS_FILTERS_BEGIN`;
export const GET_REGIONS_FILTERS_SUCCESS = `${scope}/GET_REGIONS_FILTERS_SUCCESS`;
export const GET_REGIONS_FILTERS_FAILED = `${scope}/GET_REGIONS_FILTERS_FAILED`;

export const GET_APPELLATIONS_FILTERS_BEGIN = `${scope}/GET_APPELLATIONS_FILTERS_BEGIN`;
export const GET_APPELLATIONS_FILTERS_SUCCESS = `${scope}/GET_APPELLATIONS_FILTERS_SUCCESS`;
export const GET_APPELLATIONS_FILTERS_FAILED = `${scope}/GET_APPELLATIONS_FILTERS_FAILED`;

export const TOGGLE_BOOKMARK_PRODUCT_BEGIN = `${scope}/TOGGLE_BOOKMARK_PRODUCT_BEGIN`;
export const TOGGLE_BOOKMARK_PRODUCT_SUCCESS = `${scope}/TOGGLE_BOOKMARK_PRODUCT_SUCCESS`;
export const TOGGLE_BOOKMARK_PRODUCT_FAILED = `${scope}/TOGGLE_BOOKMARK_PRODUCT_FAILED`;

export const FETCH_PRODUCT_BOOKMARKS_BEGIN = `${scope}/FETCH_PRODUCT_BOOKMARKS_BEGIN`;
export const FETCH_PRODUCT_BOOKMARKS_SUCCESS = `${scope}/FETCH_PRODUCT_BOOKMARKS_SUCCESS`;
export const FETCH_PRODUCT_BOOKMARKS_FAILED = `${scope}/FETCH_PRODUCT_BOOKMARKS_FAILED`;

export const SET_PRODUCT_BOOKMARKS_COOKIE = `${scope}/SET_PRODUCT_BOOKMARKS_COOKIE `;
export const POPULATE_PRODUCT_BOOKMARKS = `${scope}/POPULATE_PRODUCT_BOOKMARKS`;

export const SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN = `${scope}/SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN`;
export const SUBMIT_PRODUCT_BOOKMARKS_STORED_SUCCESS = `${scope}/SUBMIT_PRODUCT_BOOKMARKS_STORED_SUCCESS`;
export const SUBMIT_PRODUCT_BOOKMARKS_STORED_FAILED = `${scope}/SUBMIT_PRODUCT_BOOKMARKS_STORED_FAILED`;

export const FETCH_PRODUCTS_COUNT_BEGIN = `${scope}/FETCH_PRODUCTS_COUNT_BEGIN`;
export const FETCH_PRODUCTS_COUNT_SUCCESS = `${scope}/FETCH_PRODUCTS_COUNT_SUCCESS`;
export const FETCH_PRODUCTS_COUNT_FAILED = `${scope}/FETCH_PRODUCTS_COUNT_FAILED`;

export const GET_LAST_ORDER_BEGIN = `${scope}/GET_LAST_ORDER_BEGIN`;
export const GET_LAST_ORDER_SUCCESS = `${scope}/GET_LAST_ORDER_SUCCESS`;
export const GET_LAST_ORDER_FAILED = `${scope}/GET_LAST_ORDER_FAILED`;

export const GET_GUEST_LAST_ORDER_BEGIN = `${scope}/GET_GUEST_LAST_ORDER_BEGIN`;
export const GET_GUEST_LAST_ORDER_SUCCESS = `${scope}/GET_GUEST_LAST_ORDER_SUCCESS`;
export const GET_GUEST_LAST_ORDER_FAILED = `${scope}/GET_GUEST_LAST_ORDER_FAILED`;

export const GET_AD_PRODUCT_INFO_BEGIN = `${scope}/GET_AD_PRODUCT_INFO_BEGIN`;
export const GET_AD_PRODUCT_INFO_SUCCESS = `${scope}/GET_AD_PRODUCT_INFO_SUCCESS`;
export const GET_AD_PRODUCT_INFO_FAILED = `${scope}/GET_AD_PRODUCT_INFO_FAILED`;

export const CLEAR_COUNTRY_REGION_APPELLATION_FILTER = `${scope}/CLEAR_COUNTRY_REGION_APPELLATION_FILTER`;

export const GET_NECK_TIES_BEGIN = `${scope}/GET_NECK_TIES_BEGIN`;
export const GET_NECK_TIES_SUCCESS = `${scope}/GET_NECK_TIES_SUCCESS`;
export const GET_NECK_TIES_FAILED = `${scope}/GET_NECK_TIES_FAILED`;

export const GET_WINES_BY_COUNTRY_BEGIN = `${scope}/GET_WINES_BY_COUNTRY_BEGIN`;
export const GET_WINES_BY_COUNTRY_SUCCESS = `${scope}/GET_WINES_BY_COUNTRY_SUCCESS`;
export const GET_WINES_BY_COUNTRY_FAILED = `${scope}/GET_WINES_BY_COUNTRY_FAILED`;

export const GET_MAGNUM_WINES_BEGIN = `${scope}/GET_MAGNUM_WINES_BEGIN`;
export const GET_MAGNUM_WINES_SUCCESS = `${scope}/GET_MAGNUM_WINES_SUCCESS`;
export const GET_MAGNUM_WINES_FAILED = `${scope}/GET_MAGNUM_WINES_FAILED`;
