import { useTranslation } from "react-i18next";
import styles from "./WalletBalance.module.scss";

import { GlobalIState } from "types/RootState";
import { useAppSelector } from "hooks";
import {
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getTotalPrice,
} from "utils/utils";
import StorageService from "services/storage.service";

export default function WalletBalance() {
  const { leftPartShownItem } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const {
    defaultColour,
    client,
    pending_delivery_balance,
    balance_total_amount_balance,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { cartItems } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const { t: translations } = useTranslation();
  const totalPrice = Number(getTotalPrice(cartItems)).toFixed(2);
  const deliveryPrice = StorageService.getDataByKey("deliveryPriceForHeader");
  const totalPriceFormatted =
    getTotalPrice(cartItems) >= 99
      ? totalPrice
      : Number(getTotalPrice(cartItems) + deliveryPrice).toFixed(2);
  const movementsNetTotal = formatPrice(
    (
      (getTotalPrice(cartItems) !== 0 ? Number(totalPriceFormatted) : 0) +
      balance_total_amount_balance
    )
      .toFixed(2)
      .toString()
  );

  const netTotal =
    Number(client?.staked_balance) +
    Number(client?.pending_staked_balance) -
    Number(client?.pending_staked_withdrawal);

  const getFormattedValue = (value: number | string) => {
    return isLoggedIn
      ? value?.toString()?.length <= 3
        ? Number(value)?.toFixed(2)
        : formatPrice(Number(value)?.toFixed(2))
      : "0";
  };

  return (
    <div
      className={styles.balanceWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {leftPartShownItem === "1" || leftPartShownItem === "drinking" ? (
        <>
          <div className={styles.title}>{translations("label.drinking")}</div>
          <div className={styles.valuesContent}>
            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.in_basket")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span>CHF </span>
                </div>
                <div>
                  <span>
                    {" "}
                    {getTotalPrice(cartItems) === 0
                      ? "0.00"
                      : totalPriceFormatted}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.in_delivery")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span>CHF </span>
                </div>
                <div>
                  <span>
                    {formatPrice(
                      pending_delivery_balance?.toFixed(2)?.toString()
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.available_credits")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span>CHF </span>
                </div>
                <div>
                  <span>
                    {" "}
                    {formatPrice(
                      Number(client?.available_balance)
                        .toFixed(2)
                        .toString()
                    )}
                  </span>
                </div>
              </div>
            </div>

            {client?.blocked_avaliable_balance !== 0 ? (
              <div className={styles.row}>
                <div className={styles.label}>
                  <span>
                    {translations("label.blocked_available_benefits")}
                  </span>
                </div>
                <div className={styles.price}>
                  <div>
                    <span>CHF </span>
                  </div>
                  <div>
                    <span>
                      {" "}
                      {formatPrice(
                        Number(client?.blocked_avaliable_balance)
                          .toFixed(2)
                          .toString()
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.bottomPart}>
            <div className={styles.label}>
              <span>{translations("label.total_purchases")}</span>
            </div>
            <div className={styles.price}>
              <span style={{ marginRight: 5 }}>CHF </span>
              <span>
                {" "}
                {formatPrice(
                  Number(balance_total_amount_balance).toFixed(2).toString()
                )}
              </span>
            </div>
          </div>
        </>
      ) : null}

      {leftPartShownItem === "gaming" ? (
        <>
          <div className={styles.title}>{translations("label.gaming")}</div>
          <div className={styles.valuesContent}>
            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.medals")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span> </span>
                </div>
                <div>
                  <span>{formatPrice(client?.medals_balance.toString())}</span>
                </div>
              </div>
            </div>

            {/* <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.pending_medals")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span> </span>
                </div>
                <div>
                  <span> 0.00</span>
                </div>
              </div>
            </div> */}

            {/* <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.pending_disbursements")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span> </span>
                </div>
                <div>
                  <span> 0.00</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className={styles.bottomPart}>
            <div className={styles.label}>
              <span>{translations("label.net_total")}</span>
            </div>
            <div className={styles.price}>
              <span></span>
              <span>{formatPrice(client?.medals_balance.toString())}</span>
            </div>
          </div>
        </>
      ) : null}

      {leftPartShownItem === "staking" ? (
        <>
          <div className={styles.title}>
            <span>{translations("label.staking")}</span>
          </div>
          <div className={styles.valuesContent}>
            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.staked")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span>{getCurrencyStr(client?.currency)} </span>
                </div>
                <div>{getFormattedValue(client?.staked_balance)}</div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.pending_stakes")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span>{getCurrencyStr(client?.currency)} </span>
                </div>
                <div>{getFormattedValue(client?.pending_staked_balance)}</div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.label}>
                <span>{translations("label.pending_withdrawals")}</span>
              </div>
              <div className={styles.price}>
                <div>
                  <span>{getCurrencyStr(client?.currency)} </span>
                </div>
                <div>
                  {getFormattedValue(client?.pending_staked_withdrawal)}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomPart}>
            <div className={styles.label}>
              <span>{translations("label.net_total")}</span>
            </div>
            <div className={styles.price}>
              <div style={{ marginRight: 5 }}>
                <span>{getCurrencyStr(client?.currency)}</span>
              </div>
              <div>
                <span>{getFormattedValue(netTotal)}</span>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
