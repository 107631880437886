import { useTranslation } from "react-i18next";
import styles from "./Wallet.module.scss";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import WalletTabs from "components/WalletTabs/WalletTabs.component";
import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import { BackButtonIcon } from "assets/icons/icons";
import {
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getTotalPrice,
} from "utils/utils";
import WalletBalance from "./WalletBalance/WalletBalance.page";
import ManageWallet from "./ManageWallet/ManageWallet.page";
import { useEffect } from "react";

import TransactionDetails from "./Transactions/TransactionDetails/TransactionDetails.page";
import {
  getPendingDeliveryBalanceBegin,
  getWalletTotalAmountBegin,
} from "pages/Profile/actions";
import StorageService from "services/storage.service";

export default function Wallet() {
  const { leftPartShownItem, middlePartShownItem, rightPartShownItem } =
    useAppSelector((state: GlobalIState) => state.globalReducer);
  const { defaultColour, client, balance_total_amount_balance } =
    useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { cartItems } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const getFormattedValue = (value: number | string) => {
    return isLoggedIn
      ? value?.toString()?.length <= 3
        ? Number(value)?.toFixed(2)
        : formatPrice(value?.toString())
      : "0";
  };
  const totalPrice = Number(getTotalPrice(cartItems)).toFixed(2);
  const deliveryPrice = StorageService.getDataByKey("deliveryPriceForHeader");
  const totalPriceFormatted =
    getTotalPrice(cartItems) >= 99
      ? totalPrice
      : Number(getTotalPrice(cartItems) + deliveryPrice).toFixed(2);
  const availableBalance = formatPrice(
    (
      (getTotalPrice(cartItems) !== 0 ? +totalPriceFormatted : 0) +
      balance_total_amount_balance
    )
      .toFixed(2)
      .toString()
  );

  let medalsValue = isLoggedIn
    ? formatPrice(client?.medals_balance.toString())
    : "0";
  let stakedValue = getFormattedValue(client?.staked_balance);

  useEffect(() => {
    dispatch(getPendingDeliveryBalanceBegin());
    dispatch(getWalletTotalAmountBegin());

    return () => {
      dispatch(setLeftPartItem("1"));
    };
  }, []);

  return (
    <div
      className={styles.walletWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {isMobile ? (
        <div className={styles.balanceContent}>
          <div>
            <div>
              <span>{translations("label.purchased")}</span>
            </div>
            <div>
              <span className={styles.value}>
                CHF{" "}
                {formatPrice(
                  Number(balance_total_amount_balance).toFixed().toString()
                )}
              </span>
            </div>
          </div>
          <div>
            <div>
              <span>{translations("label.medals")}</span>
            </div>
            <div>
              <span className={styles.value}>{medalsValue}</span>
            </div>
          </div>
          <div>
            <div>
              <span>{translations("label.staked")}</span>
            </div>
            <div>
              <span className={styles.value}>
                {getCurrencyStr(client?.currency)} {stakedValue}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {isMobile ? (
        <div className={styles.balanceTabs}>
          <div
            style={
              ["drinking", "1"].includes(leftPartShownItem)
                ? {
                    backgroundColor: "#000",
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => {
              dispatch(setLeftPartItem("drinking"));
              dispatch(setMiddlepartItem(null));
              dispatch(setRightPartItem(null));
            }}
          >
            <span>{translations("label.purchased")}</span>
          </div>
          <div
            style={
              ["gaming"].includes(leftPartShownItem)
                ? {
                    backgroundColor: "#000",
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => {
              dispatch(setLeftPartItem("gaming"));
              dispatch(setMiddlepartItem(null));
              dispatch(setRightPartItem(null));
            }}
          >
            <span>{translations("label.medals")}</span>
          </div>
          <div
            style={
              ["staking"].includes(leftPartShownItem)
                ? {
                    backgroundColor: "#000",
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => {
              dispatch(setLeftPartItem("staking"));
              dispatch(setMiddlepartItem(null));
              dispatch(setRightPartItem(null));
            }}
          >
            <span>{translations("label.staked")}</span>
          </div>
        </div>
      ) : null}

      <div
        className={styles.leftContainer}
        style={
          rightPartShownItem
            ? { width: "20%" }
            : { width: "35%", minWidth: 300 }
        }
      >
        {leftPartShownItem.includes("2") ? (
          <ManageWallet />
        ) : (
          <>
            <WalletBalance />
            {isMobile ? <div style={{ marginBottom: 20 }}></div> : null}
            {isMobile ? <ManageWallet /> : null}
          </>
        )}
      </div>
      <div className={styles.middleContainer} style={{ width: "40%" }}>
        {middlePartShownItem ? (
          <div className={styles.header}>
            <div
              className="pointer"
              style={{ margin: "20px 0px 0px 20px" }}
              onClick={() => {
                dispatch(setMiddlepartItem(null));
                dispatch(setRightPartItem(null));
              }}
            >
              <BackButtonIcon color={getDefaultColour(defaultColour)} />
            </div>
            {middlePartShownItem ? (
              <h2>{translations("label.booked_transactions")}</h2>
            ) : null}
          </div>
        ) : null}
        {middlePartShownItem ? middlePartShownItem : <ManageWallet />}
      </div>
      <div
        className={styles.rightContainer}
        style={!rightPartShownItem ? {} : { width: "40%" }}
      >
        <div className={styles.header}>
          <div
            className="pointer"
            style={{ padding: rightPartShownItem ? 20 : 0 }}
            onClick={() => dispatch(setRightPartItem(null))}
          >
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
          {!middlePartShownItem && rightPartShownItem ? (
            <h2>{translations("label.booked_transactions")}</h2>
          ) : null}

          {rightPartShownItem === "2" ? (
            <h2>{translations("label.transaction_details")}</h2>
          ) : null}
        </div>
        {rightPartShownItem === "2" ? (
          <TransactionDetails />
        ) : (
          rightPartShownItem
        )}
      </div>
    </div>
  );
}
