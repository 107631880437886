import api from "./api";

export const getTodaysQuiz = (lang: string) => {
  return api.get(`/games/today/${lang}`);
};

// SINGLE CHOICE
export const submitQuiz = (answer_id: number) => {
  return api.post("/games/submit", { answer_id: answer_id });
};

export const checkQuizAnswer = (answer_id: number) => {
  return api.post("/games/check/answer", { answer_id: answer_id });
};

// FILL IN THE BLANK
export const submitFillInTheBlankGame = (data: {
  type: number;
  answer: string;
}) => {
  return api.post("/games/submit/claim", data);
};

export const checkBlankAnswer = (data: {
  answer: string;
  language: string;
}) => {
  return api.post("/games/check/claim/answer", data);
};

// TRUE OR FALSE
export const submitTrueOrFalse = (data: number[]) => {
  return api.post("/games/submit/multiple/questions", { answer_ids: data });
};

// Magic Match
export const submitMagicMatch = (matched: number) => {
  return api.post("/games/submit/magic-match", { matched: matched });
};

export const getClientObjectives = () => {
  return api.get("/membership/objectives");
};

export const getReferralsTable = () => {
  return api.get("/membership/referrals/table");
};

export const getMedalsTable = () => {
  return api.get("/membership/medals/table");
};

export const getClientTimeline = () => {
  return api.get("/timeline/client");
};

export const getTodaysGame = () => {
  return api.get("/games");
};
