import {
  ChangeEmailInterface,
  ChangePasswordInterface,
  ChangePhoneNumberInterface,
  ConfirmChangePhoneNumberInterface,
  NotificationIdentificationInterface,
  ResetPasswordInterface,
  UpdateAddressDetailsInterface,
  UpdateApplicationViewInterface,
  UpdatePersonalDetailsInterface,
  UpdateUserInfoCartInterface,
} from "types/pages/profile.page.type";
import axiosInstance from "./api";

export const changePassword = (payload: ChangePasswordInterface) => {
  return axiosInstance.put(`/auth/change-password`, payload);
};

export const changeEmail = (payload: ChangeEmailInterface) => {
  return axiosInstance.put("/clients/change-email", payload);
};

export const confirmChangeEmail = (data: { token: string; email: string }) => {
  return axiosInstance.put(
    `/clients/change-email/confirm?token=${data.token}`,
    {
      email: data.email,
    }
  );
};

export const changePhoneNumber = (payload: ChangePhoneNumberInterface) => {
  return axiosInstance.put(`/clients/change-phone-number`, payload);
};

export const confirmPhoneNumberChange = (
  payload: ConfirmChangePhoneNumberInterface
) => {
  return axiosInstance.put(`/clients/change-phone-number/confirm`, payload);
};

export const resetPassword = (payload: ResetPasswordInterface) => {
  return axiosInstance.put(`/reset-password/${payload.token}`, {
    password: payload.password,
  });
};

export const getClientData = () => {
  return axiosInstance.get(`/auth/client`);
};

export const getTermsAndConditions = () => {
  return axiosInstance.get(`/clients/terms-conditions`);
};

export const agreeToTermsAndConditions = () => {
  return axiosInstance.put(`/clients/update-terms-conditions`);
};

export const agreeToPrivacyPolicy = () => {
  return axiosInstance.put(`/clients/update-privacy-policy`);
};

export const getPrivacyPolicy = () => {
  return axiosInstance.get(`/clients/privacy-policy`);
};

export const declineToPrivacyPolicy = () => {
  return axiosInstance.put(`/clients/decline-privacy-policy`);
};

export const requestAccountClosure = () => {
  return axiosInstance.put(`/clients/account-closure/request`);
};

export const updateNotificationIdentification = (
  payload: NotificationIdentificationInterface
) => {
  return axiosInstance.put(`/clients/notifcation-identification`, payload);
};

export const updateLanguage = (payload: { language: string }) => {
  return axiosInstance.put(`/clients/language`, payload);
};

export const updateProfilePicture = (payload: { image: string }) => {
  return axiosInstance.put(`/clients/profile-picture`, payload);
};

export const updatePersonalDetails = (
  payload: UpdatePersonalDetailsInterface
) => {
  return axiosInstance.put(`/clients/personal-details`, payload);
};

export const addPhone = (payload: { country_phone: string; phone: string }) => {
  return axiosInstance.put(`/clients/add-phone`, payload);
};

export const confirmAddPhone = (token: string) => {
  return axiosInstance.put(`/clients/confirm-add-phone?token=${token}`);
};

export const addEmail = (payload: { email: string }) => {
  return axiosInstance.put(`/clients/add-email`, payload);
};

export const confirmAddEmail = () => {
  return axiosInstance.put("/clients/confirm-add-email");
};

export const updateAddressDetails = (
  payload: UpdateAddressDetailsInterface
) => {
  return axiosInstance.put(`/clients/address-details`, payload);
};

export const updateApplicationView = (
  payload: UpdateApplicationViewInterface
) => {
  return axiosInstance.put(`/clients/personalize-app`, payload);
};

export const getPendingDeliveryBalance = () => {
  return axiosInstance.get(`/orders/client/my-orders/balance`);
};

export const getWalletTotalAmount = () => {
  return axiosInstance.get("/orders/client/my-purchases/balance/total-amount");
};

export const confirmPasswordChange = () => {
  return axiosInstance.post("/auth/profile/confirm-password-changed");
};

export const getReferralCode = () => {
  return axiosInstance.get("/referrals/me");
};

export const getAllUserReferrals = () => {
  return axiosInstance.get("/clients/my/referrals");
};

export const getUserReferrals = (recent: boolean) => {
  return axiosInstance.get(`/referrals/getUserReferrals?recent=${recent}`);
};

export const checkIfHasSavedCard = () => {
  return axiosInstance.get(`/clients/check-card`);
};

export const generateTwoFator = () => {
  return axiosInstance.get("/auth/two-factor-auth");
};

export const verifyTwoFactorAuth = (code) => {
  return axiosInstance.post(`/auth/two-factor-auth-verify`, { code: code });
};

export const deactivateTwoFactorAuth = () => {
  return axiosInstance.delete("/auth/two-factor-auth");
};

export const updateUserInfo = (payload: UpdateUserInfoCartInterface) => {
  return axiosInstance.put(`/clients/update/personal-details`, payload);
};

export const changeAuthSecurity = (password: string) => {
  return axiosInstance.post("/auth/change/auth/security", {
    password: password,
  });
};

export const getReferralImageUploadUrl = () => {
  return axiosInstance.get("/referrals/share/ReferralInvitation");
};
