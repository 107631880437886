import { useTranslation } from "react-i18next";
import styles from "./TasteWine.module.scss";
import { getTastingColorOptions } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useEffect, useState } from "react";
import { DownArrow } from "assets/icons/icons";
import {
  SET_ADDED_WINE_TASTE_SUCCESS,
  SET_PRODUCT_TASTE_SUCCESS,
} from "pages/PersonalLedger/constants";
import {
  getAddedWinesBegin,
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
  setAddedWineTasteBegin,
  setProductTasteBegin,
} from "pages/PersonalLedger/actions";
import { openToaster, scrollToTop } from "App/actions";
import { ITastings } from "types/reducers/personal-ledger.reducer.type";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useSearchParams } from "react-router-dom";

interface Props {
  setActiveTab?: any;
  product_id: string;
}

function TasteWine(props: Props) {
  const {
    orders,
    userBlindTastings,
    addedWines,
    action: { actionName, isLoading },
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);

  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { setActiveTab, product_id } = props;
  const { t: translations } = useTranslation();
  const [selectedValues, setSelectedValues] = useState({
    body: "",
    aroma: "",
    flavor: "",
    acidity: "",
    sweetness: "",
  });
  const [searchParams] = useSearchParams();
  let ledgerActiveTabParam = searchParams.get("tab");
  const [data, setData] = useState([]);
  const isDisabled = Object?.keys(selectedValues)?.length === 0;

  const order: any = data?.filter(
    (item) => item.product?.id === Number(product_id)
  );

  const tasteOptions = [
    {
      id: 1,
      label: translations("label.taste.body"),
      leftLabel: translations("label.light"),
      rightLabel: translations("label.full"),
    },
    {
      id: 2,
      label: translations("label.taste.aroma"),
      leftLabel: translations("label.fruity"),
      rightLabel: translations("label.earthy"),
    },
    {
      id: 3,
      label: translations("label.taste.flavor"),
      leftLabel: translations("label.fresh"),
      rightLabel: translations("label.developed"),
    },
    {
      id: 4,
      label: translations("label.taste.acidity"),
      leftLabel: translations("label.low"),
      rightLabel: translations("label.high"),
    },
    {
      id: 5,
      label: translations("label.taste.sweetness"),
      leftLabel: translations("label.dry"),
      rightLabel: translations("label.sweet"),
    },
  ];

  const getItemName = (item) => {
    switch (item) {
      case 1:
        return "body";
      case 2:
        return "aroma";
      case 3:
        return "flavor";
      case 4:
        return "acidity";
      case 5:
        return "sweetness";
      default:
        return "body";
    }
  };

  const hasSpecificValue = (id, order) => {
    switch (id) {
      case 1:
        return selectedValues.body == order;
      case 2:
        return selectedValues.aroma == order;
      case 3:
        return selectedValues.flavor == order;
      case 4:
        return selectedValues.acidity == order;
      case 5:
        return selectedValues.sweetness == order;
    }
  };

  const handleChange = (name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [getItemName(name)]: value,
    }));
  };

  const handleSubmit = () => {
    const checkIfEmptyValues = Object.values(selectedValues).some(
      (value) => value === ""
    );

    let data: ITastings[] = Object.keys(selectedValues).map((key) => ({
      type: key,
      rating: Number(selectedValues[key]),
    }));

    if (checkIfEmptyValues) {
      dispatch(
        openToaster("ERROR", translations("error.tasting_mandatory_fields"))
      );
      return;
    }

    if (ledgerActiveTabParam === "added-wines") {
      dispatch(
        setAddedWineTasteBegin({
          product_id: Number(product_id),
          tastings: data,
        })
      );
    } else {
      dispatch(
        setProductTasteBegin({
          product_id: Number(product_id),
          tastings: data,
          isBlind: false,
        })
      );
    }
  };

  useEffect(() => {
    if (
      [SET_PRODUCT_TASTE_SUCCESS, SET_ADDED_WINE_TASTE_SUCCESS].includes(
        actionName
      )
    ) {
      setActiveTab("rating");
      if (ledgerActiveTabParam === "vinfinity-wines") {
        dispatch(getUserOrdersBegin());
        dispatch(getUserBlindTastingsBegin());
      } else if (ledgerActiveTabParam === "added-wines") {
        dispatch(getAddedWinesBegin());
      }

      dispatch(scrollToTop());
    }
  }, [actionName]);

  useEffect(() => {
    if (order?.length !== 0) {
      const selectedValuesObj = order[0]?.product?.clientTastings?.reduce(
        (acc, item) => {
          acc[item.type] = item.rating.toString();
          return acc;
        },
        {}
      );

      setSelectedValues(selectedValuesObj);
    }
  }, [data, product_id]);

  useEffect(() => {
    if (
      ledgerActiveTabParam === "vinfinity-wines" &&
      orders &&
      userBlindTastings
    ) {
      setData([...orders, ...userBlindTastings]);
    }
    if (ledgerActiveTabParam === "added-wines" && addedWines) {
      setData([...addedWines]);
    }
  }, [ledgerActiveTabParam, orders, userBlindTastings, addedWines]);

  return (
    <div className={styles.wrapper}>
      <LoaderWrapper isLoading={isLoading}>
        {tasteOptions.map((item) => (
          <div key={item.id} className={styles.item}>
            <div className={styles.leftTitle}>
              <span>{item.label}</span>
            </div>
            <div className={styles.colorOptions}>
              <div className={styles.leftLabel}>
                <span>{item.leftLabel}</span>
              </div>
              <div className={styles.options}>
                {getTastingColorOptions().map((col, idx) => (
                  <div
                    key={idx}
                    className={styles.color}
                    style={{
                      width: hasSpecificValue(item.id, col.order)
                        ? isMobile
                          ? 48
                          : 75
                        : isMobile
                        ? 42
                        : 65,
                      height: hasSpecificValue(item.id, col.order) ? 36 : 28,
                      backgroundColor: col.color,
                      border: `2px solid ${col.strokeColor}`,
                    }}
                    onClick={() => handleChange(item.id, col.order)}
                  >
                    {hasSpecificValue(item.id, col.order) ? (
                      <div className={styles.arrowIcon}>
                        <DownArrow />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.rightLabel}>
              <span>{item.rightLabel}</span>
            </div>

            {isMobile ? (
              <div className={styles.mobileLabels}>
                <div>
                  <span>{item.leftLabel}</span>
                </div>
                <div>
                  <span>{item.rightLabel}</span>
                </div>
              </div>
            ) : null}
          </div>
        ))}
        <div className={styles.submitBtn}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            disabled={isDisabled}
            handleClick={() => handleSubmit()}
          />
        </div>
      </LoaderWrapper>
    </div>
  );
}

export default TasteWine;
