import { useAppSelector, useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour, shuffleArray } from "utils/utils";

import styles from "./MatchThePairs.module.scss";
import { useEffect, useState } from "react";
import { getClientBegin } from "pages/Profile/actions";
import StorageService from "services/storage.service";
import ButtonComponent from "components/Button/Button.component";

import Lottie from "react-lottie";
import * as matchThePairsAnimation from "assets/animations/match-the-pairs.json";
import CoinImage from "assets/images/Coin.png";
import { InfoModalCloseIcon } from "assets/icons/icons";
import { getTodaysQuestionBegin, submitMagicMatchBegin } from "../actions";
import { SUBMIT_MAGIC_MATCH_SUCCESS } from "../constants";

type Item = {
  id: number;
  value: string;
};

function MatchThePairs() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    todays_question,
    todaysGame,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const [timeLeft, setTimeLeft] = useState(25);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const submitParam = searchParams.get("submit");
  const matchedItemsCookie = () => StorageService.getDataByKey("matchedTexts");
  const [state, setState] = useState({
    activeStep: "",
    selectedAnswerId: 0,
  });
  const [data, setData] = useState<Item[]>([]);
  const formattedTimeLeft = timeLeft < 10 ? `0${timeLeft}` : timeLeft;

  // shuffleArray([
  //   { id: 1, value: "Test" },
  //   { id: 2, value: "Test 2" },
  //   { id: 1, value: "Test Match" },
  //   { id: 3, value: "Test3" },
  //   { id: 3, value: "Test 3 matched" },
  //   { id: 2, value: "Test2 Matched" },
  //   { id: 4, value: "Test4" },
  //   { id: 4, value: "Test4 Matched" },
  // ])

  const [clickedItems, setClickedItems] = useState<number[]>([]);
  const [incorrectItems, setIncorrectItems] = useState<number[]>([]);
  const [selectedPair, setSelectedPair] = useState<
    { item: Item; index: number }[]
  >([]);
  const allItemsClicked =
    clickedItems.length + incorrectItems.length === data.length;
  const clickedPairs = clickedItems?.length / 2;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: matchThePairsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleClick = (item: Item, index: number) => {
    if (
      clickedItems.includes(index) ||
      incorrectItems.includes(index) ||
      selectedPair.some((pair) => pair.index === index)
    ) {
      return;
    }

    const newPair = [...selectedPair, { item, index }];
    setSelectedPair(newPair);

    if (newPair.length === 2) {
      const [first, second] = newPair;

      if (first.item.id === second.item.id) {
        setClickedItems((prev) => [...prev, first.index, second.index]);
      } else {
        setIncorrectItems((prev) => [...prev, first.index, second.index]);
      }

      setSelectedPair([]);
    }
  };

  const handleStartGame = () => {
    navigate("/games/match-the-pairs?step=quiz");
  };

  const handleNext = () => {
    setState({ ...state, activeStep: "submit" });
  };

  const handleSubmit = () => {
    if (timeLeft === 0 || allItemsClicked) {
      if (isLoggedIn()) {
        dispatch(submitMagicMatchBegin(clickedPairs));
      } else {
        StorageService.setKeyValue("imagesMatched", clickedPairs);
        StorageService.setKeyValue(
          "lastRouteCookie",
          "/games/match-the-pairs?submit=true"
        );
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    dispatch(getTodaysQuestionBegin(getLanguage()));
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
  }, [state.activeStep]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      if (client?.has_played) {
        navigate("/purchasing");
      } else {
        window.history.back();
      }
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, client?.has_played]);

  useEffect(() => {
    if (stepParam === "quiz" && state.activeStep !== "submit") {
      setState({ ...state, activeStep: "playQuiz" });
    }

    if (submitParam) {
      dispatch(submitMagicMatchBegin(Number(matchedItemsCookie())));
      StorageService.deleteCookie("matchedTexts");
      searchParams.delete("submit");
      setSearchParams(searchParams);
    }
  }, [stepParam, submitParam]);

  useEffect(() => {
    if (
      (actionName === SUBMIT_MAGIC_MATCH_SUCCESS && !submitParam) ||
      client?.has_played
    ) {
      StorageService.deleteCookie("matchedTexts");
      navigate("/games");
      window.location.reload();
    }
  }, [actionName]);

  useEffect(() => {
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
    if (todaysGame !== "Match the pairs") {
      navigate("/games");
      window.location.reload();
    }
  }, [client, todaysGame]);

  useEffect(() => {
    if (todays_question?.pairs?.length > 0) {
      setData(shuffleArray(todays_question?.pairs));
    }
  }, [todays_question]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (stepParam === "quiz" && timeLeft <= 0) {
      setIsTimeUp(true);
    }
  }, [timeLeft]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.magicMatchContainer}
    >
      {state.activeStep === "" ? (
        <div className={styles.content}>
          <div className={styles.animation}>
            <Lottie
              options={defaultOptions}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div>
          <div className={styles.gameInfo}>
            <div>
              <h1>{translations("label.match_the_pairs")}</h1>
              <span>{translations("label.match_the_pairs.subtitle")}</span>
            </div>
            <ButtonComponent
              label={translations("label.start_game")}
              class={"btn btn-primary"}
              style={{ backgroundColor: "#FF6333" }}
              handleClick={() => handleStartGame()}
            />
          </div>
        </div>
      ) : ["playQuiz", "next", "submit"].includes(state.activeStep) ? (
        <div className={styles.playQuizGameBox}>
          <div className={styles.header}>
            <div
              onClick={() => {
                navigate("/games");
                setState({ ...state, activeStep: "" });
              }}
            >
              <InfoModalCloseIcon color="#fff" />
            </div>

            <div className={styles.questionBox}>
              <h2>{translations("label.daily_quiz")}</h2>

              <div className={styles.subtitle}>
                <span>{translations("label.match_the_pairs")}</span>
              </div>
            </div>

            {state.activeStep === "submit" ? (
              <div className={styles.answeredCoinBox}>
                <img src={CoinImage} alt={"Coin"} />
              </div>
            ) : (
              <div className={styles.gameBox}>
                <div className={styles.timer}>
                  {timeLeft === 0 ? (
                    <span className={styles.timeUpLabel}>
                      {translations("label.time_up")}
                    </span>
                  ) : (
                    <>
                      <span>{translations("label.time_remaining")}</span>
                      <span>0:{formattedTimeLeft}</span>
                    </>
                  )}
                </div>

                <div
                  style={{ pointerEvents: timeLeft === 0 ? "none" : "all" }}
                  className={styles.textsGrid}
                >
                  {data.map((item, index) => {
                    const isMatched = clickedItems.includes(index);
                    const isIncorrect = incorrectItems.includes(index);
                    const isSelected = selectedPair.some(
                      (pair) => pair.index === index
                    );

                    return (
                      <div
                        key={index}
                        onClick={() => handleClick(item, index)}
                        style={{
                          background: isMatched
                            ? "#B5FF6C"
                            : isIncorrect
                            ? "#FF4141"
                            : isSelected
                            ? "#fff"
                            : "#6D11C4",
                          color:
                            isSelected || isMatched || isSelected
                              ? "#000"
                              : "#fff",
                          userSelect: "none",
                        }}
                      >
                        {item.value}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {state.activeStep === "submit" ? (
              <>
                {!isLoggedIn() ? (
                  <div className={styles.notLoggedInUser}>
                    <span>
                      {translations("label.login_to_retrieve_medals")}
                    </span>
                  </div>
                ) : null}
                <div className={styles.earnedMedals}>
                  <span>
                    {clickedPairs === 4 ? 15 : 5} {translations("label.medals")}
                  </span>
                </div>
              </>
            ) : null}

            <div
              className={styles.submitAnswerButton}
              style={{
                background:
                  allItemsClicked || timeLeft === 0 || clickedPairs === 4
                    ? "#fff"
                    : "#511C85",
                cursor:
                  allItemsClicked || timeLeft === 0 || clickedPairs === 4
                    ? "pointer"
                    : "default",
              }}
              onClick={() =>
                state.activeStep === "playQuiz" ? handleNext() : handleSubmit()
              }
            >
              <span>
                {translations(
                  state.activeStep === "submit"
                    ? isLoggedIn()
                      ? "label.get_medals"
                      : "button.log_in"
                    : "button.next"
                )}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MatchThePairs;
