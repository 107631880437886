import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Topup.module.scss";

import {
  ArrowRightTableIcon,
  BackButtonIcon,
  BankTransferIcon,
  CalendarIcon,
  CreditCardIcon,
  EditPencilIcon,
  MoneyWalletIcon,
  PayPalIcon,
  RoundedSuccessIcon,
} from "assets/icons/icons";
import {
  formatPrice,
  getArrayWithEachDayOfMonth,
  getDefaultColour,
  handleOnlyNumbersKeydown,
} from "utils/utils";

import CustomInputComponent from "components/CustomInput/CustomInput.component";
import ButtonComponent from "components/Button/Button.component";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";

import { openToaster } from "App/actions";
import {
  paypalSubscriptionSuccessPaymentCall,
  paypalSuccessPaymentCall,
  paypalUserTopUpBegin,
  paypalUserTopUpSubscriptionBegin,
  stripeUserTopUpBegin,
  stripeUserTopUpSubscriptionBegin,
  updateTransactionsStatusBegin,
} from "../actions";
import StorageService from "services/storage.service";
import dayjs from "dayjs";
import { logInByToken } from "pages/Authentication/actions";
import { TextField } from "@mui/material";
import { getClientBegin, updateUserInfoBegin } from "pages/Profile/actions";
import { UPDATE_USER_INFO_SUCCESS } from "pages/Profile/constants";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  PAYPAL_USER_TOP_UP_FAILED,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED,
  STRIPE_USER_TOP_UP_FAILED,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED,
} from "../constants";

export default function Topup() {
  const {
    action: { actionName },
    defaultColour,
    client,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const {
    action: { isLoading },
    redirectUrl,
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);

  const { InvestmentsReducer } = useAppSelector((state: GlobalIState) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  const { t: translations } = useTranslation();
  const topUpStateCookie = StorageService.getDataByKey("topupInfo");
  const [state, setState] = useState({
    shownView: "main",
    activeTab: "recurring",
    currency: client?.currency ? client?.currency : "CHF",
    amount: "",
    selectedPaymentMethod: "",
    selectedPaymentMethodLabel: "",
    selectedMonthlyDate: "",
    selectedMonthlyDateLabel: "",
    firstname: client?.firstname ? client?.firstname : "",
    lastname: client?.lastname ? client?.lastname : "",
    email: client?.email ? client?.email : "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const showNextButton = () => {
    if (state.activeTab === "recurring") {
      if (
        state.selectedPaymentMethod === translations("label.bank_transfer") &&
        state.amount !== "" &&
        state.currency !== "" &&
        state.selectedPaymentMethod !== ""
      ) {
        return true;
      } else if (
        state.amount !== "" &&
        state.currency !== "" &&
        state.selectedPaymentMethod !== ""
      ) {
        return true;
      }
    } else if (
      state.amount !== "" &&
      state.currency !== "" &&
      state.selectedPaymentMethod !== ""
    ) {
      return true;
    }
  };
  const showCurrencyError = () => {
    return (
      state.currency === "" &&
      state.amount !== "" &&
      state.selectedPaymentMethod !== "" &&
      state.selectedPaymentMethodLabel !== "" &&
      state.selectedMonthlyDate !== "" &&
      state.selectedMonthlyDateLabel !== ""
    );
  };
  const paymentIdParam = searchParams.get("paymentId");
  const payerIdParam = searchParams.get("PayerID");
  const paymentSuccessCookie = StorageService.getDataByKey("paymentSuccess");
  const agreementIdCookie = StorageService.getDataByKey("agreementId");
  const paymentStatusParam = searchParams.get("payment_status");
  const tokenParam = searchParams.get("token");
  const lastRouteCookie = StorageService.getDataByKey("lastRouteCookie");
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const clientRequiredInfo =
    isLoggedIn() && (!client?.firstname || !client?.lastname || !client?.email);
  const [isDisabled, setIsDisabled] = useState(false);

  const epochRecurringSelectedDate =
    Number(state.selectedMonthlyDate) > dayjs().date()
      ? new Date(
          dayjs()
            .date(Number(state.selectedMonthlyDate))
            .format("YYYY/MM/DD")
            .toString()
        ).getTime() / 1000
      : new Date(
          dayjs()
            .set("month", dayjs().get("month") + 1)
            .date(Number(state.selectedMonthlyDate))
            .format("YYYY/MM/DD")
            .toString()
        ).getTime() / 1000;
  const recurringPaymentDate =
    Number(state.selectedMonthlyDate) > dayjs().date()
      ? dayjs()
          .date(Number(state.selectedMonthlyDate))
          .format("YYYY/MM/DD")
          .toString()
      : dayjs()
          .set("month", dayjs().get("month") + 1)
          .date(Number(state.selectedMonthlyDate))
          .format("YYYY/MM/DD")
          .toString();

  const handleSelectedAmount = (value: string) => {
    setState({ ...state, amount: value });
  };

  const handleSelectPaymentMethod = (selectedMethod: string, label: string) => {
    setState({
      ...state,
      shownView: "main",
      selectedPaymentMethod: selectedMethod,
      selectedPaymentMethodLabel: label,
    });
  };

  const handleInputChange = (event) => {
    const inputElement = event.target;
    const value = inputElement.value;
    const selectionStart = inputElement.selectionStart;

    let finalValue = formatPrice(value);

    setState({ ...state, amount: finalValue });

    // Set the cursor position after formatting
    requestAnimationFrame(() => {
      inputElement.setSelectionRange(
        selectionStart + (finalValue.length - value.length),
        selectionStart + (finalValue.length - value.length)
      );
    });
  };

  const handleStateChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const getPaymentMethodIcon = () => {
    switch (state.selectedPaymentMethod) {
      case translations("label.credit_card"):
        return <CreditCardIcon />;
      case translations("label.bank_transfer"):
        return <BankTransferIcon />;
      case translations("label.paypal"):
        return <PayPalIcon />;
      default:
        return <CreditCardIcon />;
    }
  };

  const handleClose = () => {
    dispatch(updateTransactionsStatusBegin());
    navigate("/investments");
    navigate(0);
    searchParams.delete("payment_status");
    setSearchParams(searchParams);
  };

  const handleBackButton = () => {
    dispatch(updateTransactionsStatusBegin());
    navigate("/investments");
    navigate(0);
  };

  const handleNext = () => {
    StorageService.setKeyValue("topupInfo", state);
    if (state.amount === "") {
      dispatch(openToaster("ERROR", translations("error.amount_field_empty")));
      return;
    }

    if (state.activeTab === "recurring" && state.selectedMonthlyDate === "") {
      dispatch(openToaster("ERROR", translations("error.date_field_empty")));
      return;
    }

    setIsDisabled(true);

    switch (state.selectedPaymentMethod) {
      case translations("label.credit_card"):
        if (state.activeTab === "oneTime") {
          return dispatch(
            stripeUserTopUpBegin({
              currency: state.currency,
              price: state.amount.replace("'", ""),
              email: client?.email,
            })
          );
        } else if (state.activeTab === "recurring") {
          return dispatch(
            stripeUserTopUpSubscriptionBegin({
              currency: state.currency,
              price: state.amount.replace("'", ""),
              fullname: client?.firstname + " " + client?.lastname,
              email: client?.email,
              recurring_payment_date: epochRecurringSelectedDate,
            })
          );
        }
        break;
      case translations("label.paypal"):
        if (state.activeTab === "oneTime") {
          return dispatch(
            paypalUserTopUpBegin({
              currency: state.currency.toUpperCase(),
              price: state.amount.replace("'", ""),
            })
          );
        } else if (state.activeTab === "recurring") {
          return dispatch(
            paypalUserTopUpSubscriptionBegin({
              currency: state.currency.toUpperCase(),
              price: state.amount.replace("'", ""),
              recurring_payment_date: recurringPaymentDate,
            })
          );
        }
        break;
      case translations("label.bank_transfer"):
        return navigate("/investments/topup/bank-transfer");
      case translations("label.paypal"):
        return <PayPalIcon />;
    }
  };

  const handleSubmitUserInfo = () => {
    dispatch(
      updateUserInfoBegin({
        salutation: "",
        firstname: state.firstname,
        lastname: state.lastname,
        email: state.email,
      })
    );
  };

  useEffect(() => {
    if (redirectUrl.length !== 0) {
      window.open(redirectUrl, "_self");
    }

    if (paymentSuccessCookie) {
      StorageService.deleteCookie("paymentSuccess");
      searchParams.delete("paymentId");
      searchParams.delete("PayerID");
      setSearchParams(searchParams);
      window.location.reload();
    }
  }, [redirectUrl, paymentSuccessCookie]);

  useEffect(() => {
    if (lastRouteCookie) StorageService.deleteCookie("lastRouteCookie");

    if (state.activeTab === "recurring" || state.selectedPaymentMethod === "") {
      setState({
        ...state,
        selectedPaymentMethod: translations("label.credit_card"),
        selectedMonthlyDateLabel: translations("label.instant_payment"),
      });
    }
  }, [lastRouteCookie]);

  useEffect(() => {
    if (paymentIdParam && payerIdParam) {
      dispatch(
        paypalSuccessPaymentCall({
          payment_id: paymentIdParam,
          payer_id: payerIdParam,
        })
      );
    }

    if (tokenParam) {
      dispatch(logInByToken(tokenParam));
    }
  }, [paymentIdParam, payerIdParam, tokenParam]);

  useEffect(() => {
    if (agreementIdCookie && paymentStatusParam === "recurring-success") {
      dispatch(
        paypalSubscriptionSuccessPaymentCall({
          agreement_id: agreementIdCookie,
        })
      );
    }
  }, [agreementIdCookie]);

  useEffect(() => {
    if (!client) {
      dispatch(getClientBegin());
    }

    if (client) {
      setState({
        ...state,
        currency: client?.currency ? client?.currency : "CHF",
        firstname: client?.firstname,
        lastname: client?.lastname,
        email: client?.email,
      });

      if (clientRequiredInfo && !paymentStatusParam && !payerIdParam) {
        setState({ ...state, shownView: "userRequiredInfo" });
      }
    }
  }, [client, clientRequiredInfo]);

  useEffect(() => {
    if (actionName === UPDATE_USER_INFO_SUCCESS && !paymentStatusParam) {
      dispatch(getClientBegin());
      setState({ ...state, shownView: "main" });
    }
    if (
      [
        STRIPE_USER_TOP_UP_FAILED,
        STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED,
        PAYPAL_USER_TOP_UP_FAILED,
        PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED,
      ].includes(InvestmentsReducer?.action?.actionName)
    ) {
      setIsDisabled(false);
    }
  }, [actionName, InvestmentsReducer?.action?.actionName]);

  useEffect(() => {
    if (
      paymentStatusParam === "success" ||
      paymentStatusParam === "recurring-success"
    ) {
      setState({ ...state, shownView: "paymentSuccess" });
    } else if (paymentStatusParam === "failed") {
      setState({ ...state, shownView: "paymentFailed" });
    }
  }, [paymentStatusParam]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {state.shownView !== "paymentSuccess" && !isMobile ? (
        <div className="pointer" onClick={() => handleBackButton()}>
          <BackButtonIcon color={getDefaultColour(defaultColour)} />
        </div>
      ) : null}
      {!["chooseMonthlyPaymentDateView", "paymentSuccess"].includes(
        state.shownView
      ) ? (
        <div className={styles.title}>
          <h1>
            {translations(
              state.shownView === "paymentFailed"
                ? "error.ooops_something_went_wrong"
                : state.shownView === "userRequiredInfo"
                ? "label.required_information"
                : "label.topup.title"
            )}
          </h1>
        </div>
      ) : null}
      <div className={styles.content}>
        {state.shownView === "main" ? (
          <>
            <div className={styles.title}>
              <span>{translations("label.topup.subtitle")}</span>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.tabs}>
                <div
                  className={
                    state.activeTab === "recurring" ? styles.activeTab : ""
                  }
                  onClick={() => setState({ ...state, activeTab: "recurring" })}
                >
                  {translations("label.recurring_monthly").toString().length >=
                  16
                    ? translations("label.recurring_monthly").slice(0, 16) +
                      "..."
                    : translations("label.recurring_monthly")}
                </div>
                <div
                  className={
                    state.activeTab === "oneTime" ? styles.activeTab : ""
                  }
                  onClick={() => {
                    setState({
                      ...state,
                      activeTab: "oneTime",
                      selectedPaymentMethod: "",
                      selectedPaymentMethodLabel: "",
                    });
                  }}
                >
                  {translations("label.one_time")}
                </div>
              </div>

              <div className={styles.inputs}>
                {showCurrencyError() ? (
                  <div style={{ color: "red", marginTop: 20 }}>
                    <span>{translations("error.currency_mandatory")}</span>
                  </div>
                ) : null}
                <div className={styles.amount}>
                  <span>{translations("label.amount")}</span>
                  <div className={styles.priceInput}>
                    <div
                      className={styles.currencySelect}
                      style={
                        client?.currency
                          ? {
                              pointerEvents: "none",
                              opacity: 0.6,
                            }
                          : {}
                      }
                    >
                      <select
                        name="currency"
                        value={state.currency}
                        onChange={(e) =>
                          setState({
                            ...state,
                            currency: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          {/* {translations("label.currency")} */}...
                        </option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="CHF">CHF</option>
                      </select>
                    </div>
                    <div className={styles.amountValue}>
                      <CustomInputComponent
                        type="text"
                        name="amount"
                        value={state.amount}
                        onKeyDown={handleOnlyNumbersKeydown}
                        onBlur={(e) => {
                          if (
                            !e.target.value.includes(".") &&
                            [1, 2, 3, 4, 5].includes(e.target.value.length)
                          ) {
                            setState({
                              ...state,
                              amount: state.amount + ".00",
                            });
                          }
                        }}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.amountItems}>
                  <div
                    className={styles.item}
                    style={{
                      background:
                        state.amount === "50.00"
                          ? `${getDefaultColour(defaultColour)}70`
                          : null,
                    }}
                    onClick={() => handleSelectedAmount("50.00")}
                  >
                    50.00
                  </div>
                  <div
                    className={styles.item}
                    style={{
                      backgroundColor:
                        state.amount === "100.00"
                          ? `${getDefaultColour(defaultColour)}70`
                          : null,
                    }}
                    onClick={() => handleSelectedAmount("100.00")}
                  >
                    100.00
                  </div>
                  <div
                    className={styles.item}
                    style={{
                      backgroundColor:
                        state.amount === "250.00"
                          ? `${getDefaultColour(defaultColour)}70`
                          : null,
                    }}
                    onClick={() => handleSelectedAmount("250.00")}
                  >
                    250.00
                  </div>
                </div>

                <div
                  className={styles.paymentMethod}
                  style={{
                    pointerEvents:
                      state.activeTab === "recurring" ? "none" : "all",
                  }}
                >
                  <div>
                    <h2 className={styles.title}>
                      {translations("label.payment_method")}
                    </h2>
                  </div>
                  <div
                    className={styles.items}
                    onClick={() =>
                      setState({ ...state, shownView: "choosePaymentMethod" })
                    }
                  >
                    <div className={styles.leftPart}>
                      {state.selectedPaymentMethod === "" ? (
                        <div className={styles.divWithBorder}></div>
                      ) : (
                        getPaymentMethodIcon()
                      )}
                      {state.selectedPaymentMethod === "" ? (
                        <div style={{ marginLeft: 20 }}>
                          {translations("label.choose_a_payment_method")}
                        </div>
                      ) : (
                        <div style={{ marginLeft: 20 }}>
                          <div className={styles.pMethodTitle}>
                            {state.selectedPaymentMethod}
                          </div>
                          <div className={styles.pMethodLabel}>
                            {state.selectedPaymentMethodLabel}
                          </div>
                        </div>
                      )}
                    </div>
                    {state?.activeTab !== "recurring" ? (
                      <div>
                        {state.selectedPaymentMethod === "" ? (
                          <MoneyWalletIcon
                            color={getDefaultColour(defaultColour)}
                          />
                        ) : (
                          <EditPencilIcon
                            color={getDefaultColour(defaultColour)}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                {state.activeTab === "recurring" ? (
                  <div className={styles.paymentMethod}>
                    <div>
                      <h2 className={styles.title}>
                        {translations("label.monthly_payment_date")}
                      </h2>
                    </div>
                    {state.selectedPaymentMethod ===
                    translations("label.bank_transfer") ? (
                      <span style={{ color: getDefaultColour(defaultColour) }}>
                        {translations("label.bank_transfer.monthly_payment")}
                      </span>
                    ) : (
                      <div
                        className={styles.items}
                        onClick={() =>
                          setState({
                            ...state,
                            shownView: "chooseMonthlyPaymentDateView",
                          })
                        }
                      >
                        <div className={styles.leftPart}>
                          {state.selectedMonthlyDate === "" ? (
                            <div className={styles.divWithBorder}></div>
                          ) : (
                            <span
                              style={{ color: getDefaultColour(defaultColour) }}
                            >
                              {state.selectedMonthlyDateLabel}
                            </span>
                          )}
                          {state.selectedMonthlyDate === "" ? (
                            <div style={{ marginLeft: 20 }}>
                              {translations("label.choose_monthly_date")}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          {state.selectedMonthlyDate === "" ? (
                            <CalendarIcon
                              color={getDefaultColour(defaultColour)}
                            />
                          ) : (
                            <EditPencilIcon
                              color={getDefaultColour(defaultColour)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}

                {showNextButton() ? (
                  <div className={styles.button}>
                    <ButtonComponent
                      label={translations("button.next")}
                      class={"btn btn-primary"}
                      isLoading={isLoading}
                      disabled={isDisabled}
                      handleClick={() => handleNext()}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : state.shownView === "choosePaymentMethod" ? (
          <div className={styles.choosePMContent}>
            <h3>{translations("label.select_payment_method")}</h3>

            <div className={styles.items}>
              <div
                className={styles.row}
                onClick={() =>
                  handleSelectPaymentMethod(
                    translations("label.credit_card"),
                    translations("label.instant_payment")
                  )
                }
              >
                <div>
                  <div className={styles.icon}>
                    <CreditCardIcon />
                  </div>
                  <div>
                    <div className={styles.title}>
                      {translations("label.credit_card")}
                    </div>
                    <div className={styles.label}>
                      {translations("label.instant_payment")}
                    </div>
                  </div>
                </div>

                <div>
                  <ArrowRightTableIcon
                    color={getDefaultColour(defaultColour)}
                  />
                </div>
              </div>
              {/* <div
                className={styles.row}
                onClick={() =>
                  handleSelectPaymentMethod(
                    translations("label.bank_transfer"),
                    translations("label.2-5_days")
                  )
                }
              >
                <div>
                  <div className={styles.icon}>
                    <BankTransferIcon />
                  </div>
                  <div>
                    <div className={styles.title}>
                      {translations("label.bank_transfer")}
                    </div>
                    <div className={styles.label}>
                      {translations("label.2-5_days")}
                    </div>
                  </div>
                </div>
                <div>
                  <ArrowRightTableIcon
                    color={getDefaultColour(defaultColour)}
                  />
                </div>
              </div> */}
              <div
                className={styles.row}
                onClick={() =>
                  handleSelectPaymentMethod(
                    translations("label.paypal"),
                    translations("label.instant_payment")
                  )
                }
              >
                <div>
                  <div className={styles.icon}>
                    <PayPalIcon />
                  </div>
                  <div>
                    <div className={styles.title}>
                      {translations("label.paypal")}
                    </div>
                    <div className={styles.label}>
                      {translations("label.instant_payment")}
                    </div>
                  </div>
                </div>
                <div>
                  <ArrowRightTableIcon
                    color={getDefaultColour(defaultColour)}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : state.shownView === "chooseMonthlyPaymentDateView" ? (
          <>
            <div className={styles.title}>
              <h1>{translations("label.select_monthly_date")}</h1>
            </div>
            <div className={styles.mappedDays}>
              {getArrayWithEachDayOfMonth().map((item) => {
                return (
                  <div
                    key={item.day}
                    className={styles.oneRow}
                    onClick={() =>
                      setState({
                        ...state,
                        shownView: "main",
                        selectedMonthlyDate: item.day,
                        selectedMonthlyDateLabel: `${translations(
                          "label.every"
                        )} ${item.value} ${translations("label.of_month")}`,
                      })
                    }
                  >
                    <div className={styles.order}>{item.day}</div>
                    <div>{`${translations("label.every")} ${
                      item.value
                    } ${translations("label.of_month")}`}</div>
                    <div>
                      <ArrowRightTableIcon
                        color={getDefaultColour(defaultColour)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : state.shownView === "paymentSuccess" ? (
          <div className={styles.successWrapper}>
            <div className={styles.successStep}>
              <div>
                <h1>
                  {translations(
                    paymentStatusParam === "recurring-success"
                      ? "label.scheduled_payment_success.title"
                      : "label.payment_success.title"
                  )}
                </h1>
              </div>

              <div className={styles.icon}>
                <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
              </div>

              {paymentStatusParam !== "recurring-success" ? (
                <div
                  className={styles.monthlySubscriptionBtn}
                  style={{
                    backgroundColor: `${getDefaultColour(defaultColour)}30`,
                  }}
                  onClick={() =>
                    setState({
                      ...state,
                      shownView: "chooseMonthlyPaymentDateView",
                      activeTab: "recurring",
                      amount: topUpStateCookie?.amount,
                      currency: topUpStateCookie?.currency,
                      selectedMonthlyDate:
                        topUpStateCookie?.selectedMonthlyDate,
                      selectedMonthlyDateLabel:
                        topUpStateCookie?.selectedMonthlyDateLabel,
                      selectedPaymentMethod:
                        topUpStateCookie?.selectedPaymentMethod,
                      selectedPaymentMethodLabel:
                        topUpStateCookie?.selectedPaymentMethodLabel,
                    })
                  }
                >
                  <span>{translations("label.make_it_a_monthly_payment")}</span>
                </div>
              ) : null}
              <div className={styles.button}>
                <ButtonComponent
                  label={translations("button.close")}
                  class={"btn btn-primary"}
                  handleClick={() => {
                    StorageService.deleteCookie("topupInfo");
                    navigate("/investments");
                  }}
                />
              </div>
            </div>
          </div>
        ) : state.shownView === "paymentFailed" ? (
          <div className={styles.failedPayment}>
            <div className={styles.coloredLabel}>
              <span>
                {translations("error.this_payment_couldnt_be_processed")}
              </span>
              <br />
              <span>
                {translations(
                  "error.please_try_again_or_another_payment_method"
                )}
              </span>
            </div>
            <div className={styles.closeButton}>
              <ButtonComponent
                label={translations("button.close")}
                class={"btn btn-primary"}
                handleClick={() => handleClose()}
              />
            </div>
          </div>
        ) : state.shownView === "userRequiredInfo" ? (
          <div className={styles.userInfoRequiredContent}>
            <span>{translations("label.topup_info_required_subtitle")}</span>
            <div className={styles.inputs}>
              <TextField
                name="firstname"
                label={translations("label.firstname")}
                variant="filled"
                value={state.firstname}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  style: {
                    color: "#000",
                    fontFamily: "Montserrat-Regular",
                    fontSize: 14,
                    marginTop: state?.firstname?.length >= 1 ? 5 : 13,
                    transition: ".1s ease-in-out",
                  },
                }}
                sx={{
                  outline: "none",
                }}
                onChange={(e) => handleStateChange(e)}
              />

              <TextField
                name="lastname"
                label={translations("label.lastname")}
                variant="filled"
                value={state.lastname}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  style: {
                    color: "#000",
                    fontFamily: "Montserrat-Regular",
                    fontSize: 14,
                    marginTop: state?.lastname?.length >= 1 ? 5 : 13,
                    transition: ".1s ease-in-out",
                  },
                }}
                sx={{
                  outline: "none",
                }}
                onChange={(e) => handleStateChange(e)}
              />

              <TextField
                name="email"
                label={translations("label.email")}
                variant="filled"
                value={state.email}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  style: {
                    color: "#000",
                    fontFamily: "Montserrat-Regular",
                    fontSize: 14,
                    marginTop: state?.email?.length >= 1 ? 5 : 13,
                    transition: ".1s ease-in-out",
                  },
                }}
                sx={{
                  outline: "none",
                }}
                onChange={(e) => handleStateChange(e)}
              />
            </div>

            <div className={styles.button}>
              <ButtonComponent
                label={translations("button.next")}
                class={"btn btn-primary"}
                isLoading={isLoading}
                handleClick={() => handleSubmitUserInfo()}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
