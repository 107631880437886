import { useTranslation } from "react-i18next";
import styles from "./CartDetails.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkZipCodesForExpressShipment,
  formatPrice,
  generateExpressShipmentTimeSlots,
  getDefaultColour,
  getQuantity,
  getSelectOptions,
  getTotalPrice,
  getTotalVatIncluded,
  isTimeBetweenShipment,
} from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import {
  AmexCheckoutIcon,
  ApplePayIcon,
  ArrowLeft,
  ArrowRightBlackIcon,
  CartEditActionIcon,
  CornerBottomLeftIcon,
  CornerBottomRight,
  CornerTopLeft,
  CornerTopRight,
  HowItWorksIcon,
  MasterCardIcon,
  MinusRoundedIcon,
  NecktieTopSelected,
  NecktieTopUnselected,
  PaypalCheckoutIcon,
  RoundedPlusIcon,
  TwintIcon,
  VisaIcon,
} from "assets/icons/icons";
import { useEffect, useRef, useState } from "react";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import CartItemRowCard from "components/CartItemRowCard/CartItemRowCard.component";
import CheckboxRoundStandardComponent from "components/Checkbox/CheckboxRoundStandard/CheckboxRoundStandard.component";

import {
  OrderAsGuestInterface,
  OrderInterface,
} from "types/pages/purchasing.page.type";
import StorageService from "services/storage.service";
import { openModal, openToaster, scrollToTop } from "App/actions";

import ButtonComponent from "components/Button/Button.component";
import { getClientBegin } from "pages/Profile/actions";
import {
  getLastOrderBegin,
  getNeckTiesBegin,
  orderAsGuestBegin,
  orderBegin,
  populateCartItems,
} from "../actions";
import dayjs from "dayjs";
import { ClientInterface } from "types/reducers/profile.reducer.type";
import { ORDER_AS_GUEST_FAILED, ORDER_FAILED } from "../constants";
import properties from "properties";
import { modalTypes } from "types/reducers/modal.reducer.type";
import NecktieFormatText from "components/NecktieFormatText/NecktieFormatText.component";
import Box from "@mui/material/Box";
import VinfinityLogo from "assets/images/VinfinityLogo.png";

import Modal from "@mui/material/Modal";
export const env = properties.ENV;

type SelectedItem = {
  order_id: number;
  product_id: number | null;
  necktie_id: number;
  quantity: number;
  unit_price: string;
  total_price: string;
};

export default function CartDetails() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const {
    cartItems,
    action: { actionName, isLoading },
    lastOrder,
    session_url,
    is_winner,
    is_taken_over,
    neckties,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);

  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [standardShipment, setStandardShipment] = useState(true);
  const [expressShipment, setExpressShipment] = useState(false);
  const [pickupShipment, setPickupShipment] = useState(false);
  const [zurichExpressTimeslot, setZurichExpressTimeslot] = useState("");
  const [selectedTimeslot, setSelectedTimeslot] = useState("0");
  // const [payWithDefaultCard, setPayWithDefaultCard] = useState(false);
  const [state, setState] = useState({
    delivery_name: client?.delivery_name ?? null,
    da_street: client?.da_street ?? null,
    da_city: client?.da_city ?? null,
    da_zip: client?.da_zip ?? null,
    da_country: client?.da_country !== null ? client?.da_country : null,
    delivery_company_name: client?.delivery_company_name ?? null,
    billing_name: client?.billing_name ?? null,
    billing_street: client?.billing_street ?? null,
    billing_city: client?.billing_city ?? null,
    billing_zip: client?.billing_zip ?? null,
    billing_country:
      client?.billing_country !== null ? client?.billing_country : null,
    billing_company_name: client?.billing_company_name ?? null,
    order: null,
  });
  const quantity = getQuantity(cartItems);
  const orderTypeCookie = () => StorageService.getDataByKey("orderType");
  const deliveryPrice =
    (getTotalPrice(cartItems) >= 99.0 && !expressShipment) ||
    (orderTypeCookie() && expressShipment) ||
    pickupShipment
      ? 0.0
      : expressShipment
      ? 30.0
      : 12.0;
  const [totalPriceFormatted, setTotalPriceFormatted] = useState("0");
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const typeParam = searchParams.get("type");
  const orderParam = searchParams.get("order");
  const [showZurichExpressShipment, setShowZurichExpressShipment] =
    useState(false);
  const guestUserInfoCookie = StorageService.getDataByKey("guest_user");
  const deliveryMethodCookie = StorageService.getDataByKey("delivery_method");
  const guestUserDeliveryInfoCookie = StorageService.getDataByKey(
    "guestUserDeliveryInfo"
  );
  const selectedGuestUserDeliveryCountry = countries?.find(
    (item) => item.id === guestUserDeliveryInfoCookie?.da_country
  )?.name;
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const clientRequiredInfo =
    isLoggedIn() && (!client?.firstname || !client?.lastname || !client?.email);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const requiredDeliveryInfo =
    isLoggedIn() &&
    (!client?.delivery_name ||
      !client?.da_street ||
      !client?.da_city ||
      !client?.da_zip ||
      !client?.da_city);
  const [shownDeliveryField, setShownDeliveryField] = useState("delivery");
  const [visibleIndex, setVisibleIndex] = useState(0);
  const basketRowRef = useRef(null);
  const itemRefs = useRef([]);
  const timeslots = generateExpressShipmentTimeSlots();
  const currentTime = dayjs();
  const endTime = dayjs().hour(18).minute(0);
  const isTimeAfter18 = currentTime.isAfter(endTime);
  const isWithinTimeRange = isTimeBetweenShipment();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [personalNote, setPersonalNote] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const necktiesTotalPrice = selectedItems?.reduce(
    (sum, item) => sum + parseFloat(item.total_price),
    0
  );
  const totalPrice = Number(
    Number(getTotalPrice(cartItems)) + necktiesTotalPrice
  ).toFixed(2);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isPreviewNoteOpen, setiIsPreviewNoteOpen] = useState(false);
  const necktieItemsCookie = () => StorageService.getDataByKey("necktieItems");

  const handleIsPreviewModalToggle = () => setIsPreviewOpen(!isPreviewOpen);
  const handleIsPreviewNoteModalToggle = () =>
    setiIsPreviewNoteOpen(!isPreviewNoteOpen);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  const noteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 2,
    outline: "none",
  };

  const neckTieComponent = () => {
    return (
      <div className={styles.neckTieContainer}>
        <div className={styles.top}>
          <div>
            <span>{translations("label.dress_up_your_wine")}</span>
            <h2>{translations("label.add_a_neck_tie")}</h2>
          </div>
          <div>
            <span>{translations("label.1chf_each")}</span>
          </div>
        </div>
        <div className={styles.scrollableContainer}>
          {neckties?.map((item, index) => (
            <div
              key={index}
              className={`${styles.necktieItem} ${
                selectedItems.some(
                  (selected) => selected.necktie_id === item.id
                )
                  ? styles.selected
                  : styles.unselectedItem
              }`}
              onClick={() => {
                if (
                  selectedItems.some(
                    (selected) => selected.necktie_id === item.id
                  )
                ) {
                  return;
                } else toggleSelect(item.id, item.price, item?.image_url);
              }}
            >
              {selectedItems.some(
                (selected) => selected.necktie_id === item.id
              ) ? (
                <div
                  className={styles.deselect}
                  onClick={() =>
                    toggleSelect(item.id, item.price, item?.image_url)
                  }
                >
                  -
                </div>
              ) : null}
              {selectedItems.some(
                (selected) => selected.necktie_id === item.id
              ) ? (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className={styles.necktieTop}
                  onClick={() =>
                    toggleSelect(item.id, item.price, item?.image_url)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 332.09 75.6"
                  >
                    <g>
                      <path
                        style={{
                          fill: "#eaeef5",
                        }}
                        d="M287.84,1H44.15c-11.97,0-22.54,7.78-26.1,19.21L.9,75.3h330.18l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21ZM165.99,56.9c-31.66,0-57.33-8.39-57.33-18.75s25.67-18.75,57.33-18.75,57.33,8.4,57.33,18.75-25.67,18.75-57.33,18.75Z"
                      />
                      <path
                        style={{
                          fill: "none",
                          stroke: "#600f83",
                          strokeMiterlimit: 10,
                          strokeWidth: 4,
                        }}
                        d="M331.13,75.3l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21H44.21c-11.97,0-22.54,7.78-26.1,19.21L.95,75.3"
                      />
                    </g>
                  </svg>
                </div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 330.18 74.3"
                  className={styles.necktieTop}
                >
                  <g>
                    <path
                      style={{
                        fill: "#eaeef5",
                      }}
                      d="M286.94,0H43.25c-11.97,0-22.54,7.78-26.1,19.21L0,74.3h330.18l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21ZM165.09,55.9c-31.66,0-57.33-8.39-57.33-18.75s25.67-18.75,57.33-18.75,57.33,8.4,57.33,18.75-25.67,18.75-57.33,18.75Z"
                    />
                  </g>
                </svg>
              )}
              <div className={styles.content}>
                <NecktieFormatText text={item?.image_text} />
              </div>
              <div className={styles.bottomContent}>
                {!selectedItems.some(
                  (selected) => selected.necktie_id === item.id
                ) ? (
                  <span
                    className={`${styles.bottomContent} ${
                      selectedItems.some(
                        (selected) => selected.necktie_id === item.id
                      )
                        ? styles.selectedItem
                        : styles.unselectedItem
                    }`}
                  >
                    {translations("label.select")}
                  </span>
                ) : null}

                {selectedItems.some(
                  (selected) => selected.necktie_id === item.id
                ) ? (
                  <div className={styles.necktieQuantity}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        decrementQuantity(item.id);
                      }}
                    >
                      <MinusRoundedIcon color={"#000"} />
                    </div>
                    <span>
                      {
                        selectedItems.find(
                          (selected) => selected.necktie_id === item.id
                        )?.quantity
                      }
                    </span>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        incrementQuantity(item.id);
                      }}
                    >
                      <RoundedPlusIcon color={"#000"} />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        {selectedItems?.length > 0 ? (
          <div
            className={styles.bottomLabel}
            onClick={() => handleIsPreviewModalToggle()}
          >
            <span>{translations("label.see_preview")}</span>
          </div>
        ) : null}
      </div>
    );
  };

  const toggleSelect = (id: number, price: number, img_url: string) => {
    setSelectedItems((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (item) => item.necktie_id === id
      );
      if (itemIndex !== -1) {
        return prevSelectedItems.filter((item) => item.necktie_id !== id);
      } else {
        return [
          ...prevSelectedItems,
          {
            order_id: 1,
            product_id: null,
            necktie_id: id,
            quantity: 1,
            unit_price: price.toFixed(2),
            total_price: price.toFixed(2),
            img_url: img_url,
          },
        ];
      }
    });
  };

  const incrementQuantity = (id: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) =>
        item.necktie_id === id
          ? {
              ...item,
              quantity: item.quantity + 1,
              total_price: (
                (item.quantity + 1) *
                parseFloat(item.unit_price)
              ).toFixed(2),
            }
          : item
      )
    );
  };

  const decrementQuantity = (id: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems
        .map((item) =>
          item.necktie_id === id
            ? {
                ...item,
                quantity: item.quantity - 1,
                total_price: (
                  (item.quantity - 1) *
                  parseFloat(item.unit_price)
                ).toFixed(2),
              }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const getMembershipDiscountedPrice = (
    client: ClientInterface,
    amount: number
  ): number => {
    const percentDiscount = client?.permanentDiscountPercentage;

    const isValidDiscount =
      Number(percentDiscount) >= 0 && Number(percentDiscount) <= 100;

    const flatDiscount = isValidDiscount
      ? (amount * Number(percentDiscount)) / 100
      : 0;

    const discountedPrice = amount - flatDiscount;
    return Number(discountedPrice.toFixed(2));
  };

  const discountPrice = formatPrice(
    Number(
      Number(getTotalPrice(cartItems)) -
        getMembershipDiscountedPrice(client, Number(getTotalPrice(cartItems)))
    )
      .toFixed(2)
      .toString()
  );

  const getLanguageStored = () => {
    return localStorage.getItem("lang");
  };

  const handle2hExpressShipmentPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.expressShipment2hPopup}>
            <span>{translations("next_2h_shipment_popup.label1")}</span>
            <br />
            <span>{translations("next_2h_shipment_popup.label2")}</span>
          </div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handlePersonalNoteInfoPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.personalNoteInfoContent}>
            <span>{translations("label.personal_note_info1")}</span>
            <span>{translations("label.personal_note_info2")}</span>
          </div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handleContinueAsGuest = () => {
    setIsGuestUser(true);
  };

  const handleDeliveryStateUpdate = (e, type) => {
    switch (type) {
      case "standard":
        StorageService.setKeyValue("delivery_method", "standard");
        setExpressShipment(false);
        setPickupShipment(false);
        setStandardShipment(true);
        setZurichExpressTimeslot("");
        break;
      case "express":
        StorageService.setKeyValue("delivery_method", "express");
        setStandardShipment(false);
        setPickupShipment(false);
        setExpressShipment(true);
        break;
      case "pickup":
        StorageService.setKeyValue("delivery_method", "pickup");
        setStandardShipment(false);
        setExpressShipment(false);
        setPickupShipment(true);
        setZurichExpressTimeslot("");
        break;
      default:
        StorageService.setKeyValue("delivery_method", "standard");
        setExpressShipment(false);
        setPickupShipment(false);
        setStandardShipment(true);
        setZurichExpressTimeslot("");
        break;
    }
  };

  const addLastOrderToBasket = () => {
    const lastOrderItems: any[] = lastOrder?.orderDetails
      ?.filter((item) => !item.necktie_id)
      .map((item) => ({
        quantity: item.quantity,
        total: item.total_price,
        ...item.product,
      }));

    setSelectedItems(
      lastOrder?.orderDetails?.filter((item) => item.necktie_id)
    );
    localStorage.setItem("cartItems", JSON.stringify([...lastOrderItems]));
    dispatch(populateCartItems([...lastOrderItems]));
  };

  const handleProceedToPaymentClick = () => {
    if (paymentMethod === "") {
      dispatch(
        openToaster("ERROR", translations("error.please_select_payment_method"))
      );
      return;
    }
    localStorage.removeItem("orderData");
    StorageService.deleteCookie("necktieItems");

    const formattedObject: OrderInterface = {
      client_id: client?.id,
      payment_method_type: pickupShipment
        ? 5
        : orderTypeCookie() && expressShipment
        ? 4
        : standardShipment
        ? 1
        : 2,
      promotional_code: "",
      order_details: [],
      payment_type: paymentMethod,
      shipment_time:
        isTimeAfter18 ||
        (timeslots?.length === 1 &&
          selectedTimeslot !== translations("label.within_next_2_hours_new"))
          ? `${translations("label.tomorrow_morning")}`.toLowerCase()
          : selectedTimeslot,
      order_delivery_note: personalNote,
    };

    cartItems.forEach((originalObject, index) => {
      const orderDetailsObject = {
        order_id: index + 1,
        product_id: originalObject.id,
        quantity: originalObject.quantity,
        unit_price: originalObject.price,
        total_price: (originalObject.quantity * originalObject.price).toFixed(
          2
        ),
      };

      formattedObject.order_details.push(orderDetailsObject);
    });

    const formattedObjectWithNeckties = {
      ...formattedObject,
      order_details: [...formattedObject.order_details, ...selectedItems],
    };

    setState({ ...state, order: formattedObjectWithNeckties });
    StorageService.setKeyValue(
      "deliveryPrice",
      getTotalPrice(cartItems) >= 99 && !expressShipment ? 0 : deliveryPrice
    );

    if (isLoggedIn()) {
      if (client?.da_country !== 219) {
        dispatch(openToaster("ERROR", translations("error.delivery_country")));
        return;
      }
      if (
        !client?.delivery_name ||
        !client?.da_street ||
        !client?.da_city ||
        !client?.da_zip ||
        !client?.da_country ||
        !client?.billing_name ||
        !client?.billing_street ||
        !client?.billing_city ||
        !client?.billing_zip ||
        !client?.billing_country
      ) {
        dispatch(
          openToaster(
            "ERROR",
            translations("error.please_fill_all_delivery_address_fields")
          )
        );
        return;
      }

      if (client.email?.length === 0) {
        dispatch(
          openToaster("ERROR", translations("error.email_required_to_proceed"))
        );
        return;
      }

      if (
        orderTypeCookie() === "rg" &&
        !isTimeAfter18 &&
        selectedTimeslot === "0" &&
        expressShipment &&
        timeslots?.length > 1
      ) {
        dispatch(
          openToaster(
            "ERROR",
            translations("error.please_select_shipment_a_delivery_time")
          )
        );
        return;
      }

      dispatch(orderBegin(formattedObjectWithNeckties));
      setIsDisabledButton(true);
    } else {
      StorageService.setKeyValue(
        "lastRouteCookie",
        orderTypeCookie() ? "/cart?order=rg" : "/cart"
      );

      navigate("/login", {
        state: {
          lastRoute: orderTypeCookie() ? "/cart?order=rg" : "/cart",
        },
      });
    }
  };

  const handlePurchaseAsGuest = () => {
    if (paymentMethod === "") {
      dispatch(
        openToaster("ERROR", translations("error.please_select_payment_method"))
      );
      return;
    }
    localStorage.removeItem("orderData");
    StorageService.deleteCookie("necktieItems");

    const formattedObject: OrderAsGuestInterface = {
      payment_method_type: pickupShipment
        ? 5
        : orderTypeCookie() && expressShipment
        ? 4
        : standardShipment
        ? 1
        : 2,
      promotional_code: "",
      total_order_amount:
        getTotalPrice(cartItems) >= 99.0 && standardShipment
          ? totalPrice
          : Number(getTotalPrice(cartItems) + deliveryPrice).toFixed(2),
      order_details: [],
      payment_type: paymentMethod,
      email: guestUserInfoCookie?.email,
      first_name: guestUserInfoCookie?.firstname,
      last_name: guestUserInfoCookie?.lastname,
      billing_name: guestUserDeliveryInfoCookie?.billing_name,
      billing_city: guestUserDeliveryInfoCookie?.billing_city,
      billing_street: guestUserDeliveryInfoCookie?.billing_street,
      billing_zip: guestUserDeliveryInfoCookie?.billing_zip,
      billing_country: guestUserDeliveryInfoCookie?.billing_country,
      billing_company_name: guestUserDeliveryInfoCookie?.billing_company_name,
      da_city: guestUserDeliveryInfoCookie?.da_city,
      da_street: guestUserDeliveryInfoCookie?.da_street,
      da_zip: guestUserDeliveryInfoCookie?.da_zip,
      da_country: guestUserDeliveryInfoCookie?.da_country,
      delivery_name: guestUserDeliveryInfoCookie?.delivery_name,
      delivery_company_name: guestUserDeliveryInfoCookie?.delivery_company_name,
      language: getLanguageStored(),
      shipment_time:
        isTimeAfter18 ||
        (timeslots?.length === 1 &&
          selectedTimeslot !== translations("label.within_next_2_hours_new"))
          ? `${translations("label.tomorrow_morning")}`.toLowerCase()
          : selectedTimeslot,
      residency_name: "",
      salutation: guestUserInfoCookie?.salutation,
      order_delivery_note: personalNote,
    };

    cartItems.forEach((originalObject, index) => {
      const orderDetailsObject = {
        order_id: index + 1,
        product_id: originalObject.id,
        quantity: originalObject.quantity,
        unit_price: originalObject.price,
        total_price: (originalObject.quantity * originalObject.price).toFixed(
          2
        ),
      };

      formattedObject.order_details.push(orderDetailsObject);
    });

    const formattedObjectWithNeckties = {
      ...formattedObject,
      order_details: [...formattedObject.order_details, ...selectedItems],
    };

    setState({ ...state, order: formattedObjectWithNeckties });
    StorageService.setKeyValue(
      "deliveryPrice",
      getTotalPrice(cartItems) >= 99 && !expressShipment ? 0 : deliveryPrice
    );

    if (
      !guestUserInfoCookie?.firstname ||
      !guestUserInfoCookie?.lastname ||
      !guestUserInfoCookie?.email
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.please_fill_name_and_email_fields")
        )
      );
      return;
    }

    if (
      !guestUserDeliveryInfoCookie?.delivery_name ||
      !guestUserDeliveryInfoCookie?.da_street ||
      !guestUserDeliveryInfoCookie?.da_city ||
      !guestUserDeliveryInfoCookie?.da_zip ||
      !guestUserDeliveryInfoCookie?.da_country ||
      !guestUserDeliveryInfoCookie?.billing_name ||
      !guestUserDeliveryInfoCookie?.billing_street ||
      !guestUserDeliveryInfoCookie?.billing_city ||
      !guestUserDeliveryInfoCookie?.billing_zip ||
      !guestUserDeliveryInfoCookie?.billing_country
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.please_fill_all_delivery_address_fields")
        )
      );
      return;
    }

    if (guestUserDeliveryInfoCookie?.da_country !== 219) {
      dispatch(openToaster("ERROR", translations("error.delivery_country")));
      return;
    }

    if (
      orderTypeCookie() === "rg" &&
      !isTimeAfter18 &&
      selectedTimeslot === "0" &&
      expressShipment &&
      timeslots?.length > 1
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.please_select_shipment_a_delivery_time")
        )
      );
      return;
    }

    dispatch(orderAsGuestBegin(formattedObjectWithNeckties));
    setIsDisabledButton(true);
  };

  useEffect(() => {
    dispatch(scrollToTop());

    if (client) {
      setState({
        ...state,
        delivery_name: client?.delivery_name ?? null,
        da_street: client?.da_street ?? null,
        da_city: client?.da_city ?? null,
        da_zip: client?.da_zip ?? null,
        da_country: client?.da_country !== null ? client?.da_country : null,
        delivery_company_name: client?.delivery_company_name ?? null,
        billing_name: client?.billing_name ?? null,
        billing_street: client?.billing_street ?? null,
        billing_city: client?.billing_city ?? null,
        billing_zip: client?.billing_zip ?? null,
        billing_country:
          client?.billing_country !== null ? client?.billing_country : null,
        billing_company_name: client?.billing_company_name ?? null,
      });

      // if (isLoggedIn() && !hasSavedCard) {
      //   dispatch(checkIfHasSavedCardBegin());
      // }
    }
    if (isLoggedIn() && !client) {
      dispatch(getClientBegin());
    }

    if (isLoggedIn()) {
      dispatch(getLastOrderBegin());
      StorageService.deleteCookie("guestUserDeliveryInfo");
      StorageService.deleteCookie("guest_user");
    }

    dispatch(getNeckTiesBegin());

    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    StorageService.setKeyValue("deliveryPriceForHeader", deliveryPrice);

    if (deliveryMethodCookie) {
      if (deliveryMethodCookie === "express") {
        setExpressShipment(true);
        setStandardShipment(false);
      } else if (deliveryMethodCookie === "pickup") {
        setExpressShipment(false);
        setStandardShipment(false);
        setPickupShipment(true);
      } else {
        setExpressShipment(false);
        setStandardShipment(true);
      }
    }
    // eslint-disable-next-line
  }, [deliveryPrice, deliveryMethodCookie]);

  useEffect(() => {
    if (typeParam === "guest") {
      setIsGuestUser(true);
    }

    if (quantity > 24 && !orderParam) {
      setStandardShipment(true);
      StorageService.setKeyValue("delivery_method", "standard");
      setExpressShipment(false);
      setPickupShipment(false);
      setZurichExpressTimeslot("");
    }

    if (orderParam) {
      StorageService.setKeyValue("orderType", "rg");
    }
    // eslint-disable-next-line
  }, [typeParam, quantity, orderParam]);

  useEffect(() => {
    if (is_winner) {
      navigate("/cart/lucky-winner");
    } else if (is_taken_over) {
      navigate("/cart/takeover");
    } else {
      window.open(session_url, "_self");
    }
  }, [session_url, is_winner, is_taken_over]);

  useEffect(() => {
    if (client) {
      let price = getMembershipDiscountedPrice(
        client,
        Number(getTotalPrice(cartItems)) + necktiesTotalPrice
      );

      setTotalPriceFormatted(
        price >= 99.0 && standardShipment
          ? price.toString()
          : Number(price + deliveryPrice).toFixed(2)
      );
    } else {
      setTotalPriceFormatted(
        Number(getTotalPrice(cartItems) + necktiesTotalPrice) >= 99 &&
          standardShipment
          ? totalPrice
          : Number(
              getTotalPrice(cartItems) + necktiesTotalPrice + deliveryPrice
            ).toFixed(2)
      );
    }
  }, [getTotalPrice(cartItems), deliveryPrice, selectedItems]);

  useEffect(() => {
    if ([ORDER_FAILED, ORDER_AS_GUEST_FAILED].includes(actionName)) {
      setIsDisabledButton(false);
    }
  }, [actionName]);

  useEffect(() => {
    // const hasProductsRoseGlow = cartItems.some(
    //   (product) => product.id === (env === "production" ? 729 : 9236)
    // );

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Number(entry.target.getAttribute("data-index"));
            setVisibleIndex(index);
          }
        });
      },
      {
        root: basketRowRef.current,
        threshold: 0.5,
      }
    );

    itemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, [cartItems]);

  useEffect(() => {
    if (orderTypeCookie()) {
      let daZip = client
        ? client?.da_zip
        : StorageService.getDataByKey("guestUserDeliveryInfo")?.da_zip;
      if (daZip) {
        setShowZurichExpressShipment(checkZipCodesForExpressShipment(daZip));
      }
      if (!checkZipCodesForExpressShipment(daZip)) {
        setStandardShipment(true);
        StorageService.setKeyValue("delivery_method", "standard");
        setExpressShipment(false);
        setZurichExpressTimeslot("");
      }
    }
  }, [client, StorageService.getDataByKey("guestUserDeliveryInfo")?.da_zip]);

  useEffect(() => {
    const storedItems = StorageService.getDataByKey("necktieItems");
    if (storedItems) {
      setSelectedItems(storedItems);
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      StorageService.setKeyValue("necktieItems", selectedItems);
    }
  }, [selectedItems, isInitialized]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {cartItems?.length === 0 ? (
        <div className={styles.emptyBasketContainer}>
          <span className={styles.emptyBasketLabel}>
            {translations("label.your_basket_is_empty")}
          </span>
          <span
            className="underlinedBtn"
            style={{ color: getDefaultColour(defaultColour) }}
            onClick={() => navigate("/purchasing")}
          >
            {translations("label.continue_shopping")}
          </span>

          {neckTieComponent()}

          {lastOrder ? (
            <div className={styles.lastOrderContainer}>
              <span className={styles.lastOrderTitle}>
                {translations("label.reorder_last_purchase")}
              </span>
              <div className={styles.actionBox}>
                <div className={styles.title}>
                  <span>
                    {translations("label.last_purchase")}:{" "}
                    {dayjs(lastOrder?.order_date).format("DD.MM.YYYY")}
                  </span>
                </div>
                <div className={styles.content}>
                  <div>
                    {lastOrder?.orderDetails?.length +
                      " " +
                      translations(
                        lastOrder?.orderDetails?.length === 1
                          ? "label.item_for"
                          : "label.items_for"
                      ) +
                      " CHF " +
                      formatPrice(
                        Number(
                          lastOrder?.total_order_amount_discounted
                        ).toFixed(2)
                      )}
                  </div>
                  <div
                    className={styles.loadOrderBtn}
                    onClick={() => addLastOrderToBasket()}
                  >
                    <span>{translations("button.load")}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div className={styles.cartSummaryDetails}>
            {!isMobile ? (
              <h1>{translations("label.proceed_with_checkout")}</h1>
            ) : null}
            {!isMobile ? (
              <div>
                <span className={styles.title}>
                  {translations("label.items")}
                </span>
              </div>
            ) : null}

            <div className={styles.basketRow}>
              {cartItems?.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    ref={(el) => (itemRefs.current[index] = el)}
                    data-index={index}
                    style={{
                      width: "100%",
                    }}
                  >
                    <CartItemRowCard
                      item={item}
                      index={index}
                      isLastItem={index === cartItems.length - 1}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.underBasketItems}>
              {orderTypeCookie() && !isLoggedIn() ? (
                <div
                  className={styles.continueShoppingLabel}
                  onClick={() => {
                    StorageService.setKeyValue(
                      "lastRouteCookie",
                      orderTypeCookie() ? "/cart?order=rg" : "/cart"
                    );

                    navigate("/login", {
                      state: {
                        lastRoute: orderTypeCookie()
                          ? "/cart?order=rg"
                          : "/cart",
                      },
                    });
                  }}
                >
                  <ArrowLeft />
                  <span
                    className="underlinedBtn"
                    style={{ color: getDefaultColour(defaultColour) }}
                  >
                    {translations("label.already_a_client") +
                      " " +
                      translations("button.log_in")}
                  </span>
                </div>
              ) : (
                <div
                  className={styles.continueShoppingLabel}
                  onClick={() => navigate("/purchasing")}
                >
                  <ArrowLeft />
                  <span
                    className="underlinedBtn"
                    style={{ color: getDefaultColour(defaultColour) }}
                  >
                    {translations("label.continue_shopping")}
                  </span>
                </div>
              )}
              <div className={styles.cartItemsLength}>
                <span>
                  {visibleIndex + 1}/{cartItems?.length}
                </span>
              </div>
            </div>
            <div className={styles.nonEmptyBasketNecktie}>
              {neckTieComponent()}

              <div className={styles.personalNoteContent}>
                <span>{translations("label.attach_a_greeting_card")}</span>
                <h2>{translations("label.add_a_personal_note")}</h2>
                <div
                  className={styles.infoIcon}
                  onClick={() => handlePersonalNoteInfoPopup()}
                >
                  <HowItWorksIcon />
                </div>

                <textarea
                  value={personalNote}
                  placeholder={translations("label.max_100_characters")}
                  maxLength={100}
                  onChange={(e) => setPersonalNote(e.target.value)}
                />
              </div>

              {personalNote?.length > 0 ? (
                <div
                  className={styles.bottomLabel}
                  onClick={() => handleIsPreviewNoteModalToggle()}
                >
                  <span>{translations("label.see_preview")}</span>
                </div>
              ) : null}
            </div>

            {(isGuestUser || isLoggedIn()) && !clientRequiredInfo ? (
              <div className={styles.guestUserDataHeader}>
                <div className={styles.title}>
                  <span>{translations("label.name_and_email")}</span>
                </div>
                <div
                  className={styles.editIcon}
                  onClick={() => navigate("/cart/user-info")}
                >
                  <CartEditActionIcon />
                </div>
              </div>
            ) : null}
            {(!isLoggedIn() && isGuestUser && !guestUserInfoCookie) ||
            clientRequiredInfo ? (
              <div
                className={styles.actionBox}
                onClick={() => navigate("/cart/user-info")}
              >
                <div className={styles.guestUserContent}>
                  <div>
                    {isGuestUser ? (
                      <span>
                        {guestUserInfoCookie
                          ? guestUserInfoCookie?.firstname
                          : translations("label.first_names")}
                      </span>
                    ) : (
                      <span>
                        {client?.firstname
                          ? client?.firstname
                          : translations("label.first_names")}
                      </span>
                    )}
                  </div>
                  <div>
                    {isGuestUser ? (
                      <span>
                        {guestUserInfoCookie
                          ? guestUserInfoCookie?.lastname
                          : translations("label.last_names")}
                      </span>
                    ) : (
                      <span>
                        {client?.lastname
                          ? client?.lastname
                          : translations("label.last_names")}
                      </span>
                    )}
                  </div>
                  <div>
                    {isGuestUser ? (
                      <span>
                        {guestUserInfoCookie
                          ? guestUserInfoCookie?.email
                          : translations("label.email")}
                      </span>
                    ) : (
                      <span>
                        {client?.email
                          ? client?.email
                          : translations("label.email")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {(isLoggedIn() && !clientRequiredInfo) || guestUserInfoCookie ? (
              <div
                className={
                  isMobile ? styles.checkoutInformations : styles.actionBox
                }
              >
                <div>
                  <span>
                    {guestUserInfoCookie
                      ? guestUserInfoCookie?.firstname
                      : client?.firstname}
                  </span>
                </div>
                <div>
                  <span>
                    {guestUserInfoCookie
                      ? guestUserInfoCookie?.lastname
                      : client?.lastname}
                  </span>
                </div>
                <div>
                  <span>
                    {guestUserInfoCookie
                      ? guestUserInfoCookie?.email
                      : client?.email}
                  </span>
                </div>
              </div>
            ) : null}

            {isLoggedIn() || isGuestUser ? (
              <>
                <div className={styles.shipmentHeader}>
                  <div className={styles.title} style={{ width: "100%" }}>
                    <span>
                      {translations("label.delivery_and_billing_address")}
                    </span>
                  </div>
                  <div
                    className={styles.editIcon}
                    onClick={() =>
                      navigate(
                        isGuestUser
                          ? "/cart/delivery?type=guest"
                          : "/cart/delivery"
                      )
                    }
                  >
                    <CartEditActionIcon />
                  </div>
                </div>
              </>
            ) : null}

            {/*  ADDRESS DESKTOP */}
            {!isMobile && (isGuestUser || isLoggedIn()) ? (
              <div
                className={styles.actionBox}
                onClick={() =>
                  navigate(
                    isGuestUser ? "/cart/delivery?type=guest" : "/cart/delivery"
                  )
                }
              >
                <div className={styles.addressesWrapper}>
                  <div className={styles.dAddressContent}>
                    <span className={styles.titleLabel}>
                      {translations("label.delivery_address")}
                    </span>
                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.delivery_name
                        : client?.delivery_name
                        ? client?.delivery_name
                        : translations("label.full_name")}
                    </span>
                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.delivery_company_name
                        : client?.delivery_company_name
                        ? client?.delivery_company_name
                        : "c/o"}
                    </span>
                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.da_street
                        : client?.da_street
                        ? client?.da_street
                        : translations("label.street")}
                    </span>

                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.da_city
                        : client?.da_city
                        ? client?.da_city
                        : translations("label.city")}
                    </span>

                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.da_zip
                        : client?.da_zip
                        ? client?.da_zip
                        : translations("label.zip_code")}
                    </span>

                    <span>
                      {guestUserDeliveryInfoCookie
                        ? selectedGuestUserDeliveryCountry
                        : client?.da_country
                        ? client?.countryDelivery?.[
                            client?.language
                              ? `name_${client?.language}`
                              : "name_en"
                          ]
                        : translations("label.country")}
                    </span>
                  </div>
                  <div className={styles.bAddressContent}>
                    <span className={styles.titleLabel}>
                      {translations("label.billing_address")}
                    </span>
                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.billing_name
                        : client?.billing_name
                        ? client?.billing_name
                        : translations("label.full_name")}
                    </span>
                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.billing_company_name
                        : client?.billing_company_name
                        ? client?.billing_company_name
                        : "c/o"}
                    </span>
                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.billing_street
                        : client?.billing_street
                        ? client?.billing_street
                        : translations("label.street")}
                    </span>

                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.billing_city
                        : client?.billing_city
                        ? client?.billing_city
                        : translations("label.city")}
                    </span>

                    <span>
                      {guestUserDeliveryInfoCookie
                        ? guestUserDeliveryInfoCookie?.billing_zip
                        : client?.billing_zip
                        ? client?.billing_zip
                        : translations("label.zip_code")}
                    </span>

                    <span>
                      {guestUserDeliveryInfoCookie
                        ? selectedGuestUserDeliveryCountry
                        : client?.billing_country
                        ? client?.countryDelivery?.[
                            client?.language
                              ? `name_${client?.language}`
                              : "name_en"
                          ]
                        : translations("label.country")}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}

            {/* NO ADDRESS MOBILE */}
            {isMobile &&
            ((requiredDeliveryInfo && isLoggedIn()) ||
              (isGuestUser && !isLoggedIn())) &&
            !guestUserDeliveryInfoCookie ? (
              <div className={styles.actionBox}>
                <div className={styles.addressesWrapper}>
                  {shownDeliveryField === "delivery" ? (
                    <div className={styles.dAddressContent}>
                      <div className={styles.headerPart}>
                        <div>
                          <span className={styles.titleLabel}>
                            {translations("label.delivery_address")}
                          </span>
                        </div>
                        <div onClick={() => setShownDeliveryField("billing")}>
                          <ArrowRightBlackIcon
                            color={getDefaultColour(defaultColour)}
                          />
                        </div>
                      </div>
                      <span>{translations("label.full_name")}</span>

                      <span>{translations("label.street")}</span>

                      <span>{translations("label.city")}</span>

                      <span>{translations("label.zip_code")}</span>

                      <span>{translations("label.country")}</span>
                    </div>
                  ) : null}
                  {shownDeliveryField === "billing" ? (
                    <div className={styles.bAddressContent}>
                      <div className={styles.headerPart}>
                        <div>
                          <span className={styles.titleLabel}>
                            {translations("label.billing_address")}
                          </span>
                        </div>
                        <div onClick={() => setShownDeliveryField("delivery")}>
                          <ArrowRightBlackIcon
                            color={getDefaultColour(defaultColour)}
                          />
                        </div>
                      </div>
                      <span>{translations("label.street")}</span>

                      <span>{translations("label.city")}</span>

                      <span>{translations("label.zip_code")}</span>

                      <span>{translations("label.country")}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {isMobile &&
            (!requiredDeliveryInfo ||
              (isGuestUser && guestUserDeliveryInfoCookie)) ? (
              <div className={styles.checkoutInformations}>
                {(guestUserDeliveryInfoCookie || isLoggedIn()) &&
                shownDeliveryField === "delivery" ? (
                  <div className={styles.dAddressContent}>
                    <div className={styles.headerPart}>
                      <div>
                        <span className={styles.titleLabel}>
                          {translations("label.delivery_address")}
                        </span>
                      </div>
                      <div onClick={() => setShownDeliveryField("billing")}>
                        <ArrowRightBlackIcon
                          color={getDefaultColour(defaultColour)}
                        />
                      </div>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.delivery_name
                          : client?.delivery_name}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.delivery_company_name
                          : client?.delivery_company_name}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.da_street
                          : client?.da_street}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.da_city
                          : client?.da_city}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.da_zip
                          : client?.da_zip}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? selectedGuestUserDeliveryCountry
                          : client?.da_country
                          ? client?.countryDelivery?.[
                              client?.language
                                ? `name_${client?.language}`
                                : "name_en"
                            ]
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : null}

                {(guestUserDeliveryInfoCookie || isLoggedIn()) &&
                shownDeliveryField === "billing" ? (
                  <div className={styles.bAddressContent}>
                    <div className={styles.headerPart}>
                      <div>
                        <span className={styles.titleLabel}>
                          {translations("label.billing_address")}
                        </span>
                      </div>
                      <div onClick={() => setShownDeliveryField("delivery")}>
                        <ArrowRightBlackIcon
                          color={getDefaultColour(defaultColour)}
                        />
                      </div>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.billing_name
                          : client?.billing_name}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.billing_company_name
                          : client?.billing_company_name}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.billing_street
                          : client?.billing_street}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.billing_city
                          : client?.billing_city}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? guestUserDeliveryInfoCookie?.billing_zip
                          : client?.billing_zip}
                      </span>
                    </div>
                    <div>
                      <span>
                        {guestUserDeliveryInfoCookie
                          ? selectedGuestUserDeliveryCountry
                          : client?.billing_country
                          ? client?.countryBilling?.[
                              client?.language
                                ? `name_${client?.language}`
                                : "name_en"
                            ]
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className={styles.title}>
              <span>{translations("label.delivery_method")}</span>
            </div>
            <div
              className={styles.actionBox}
              style={{ padding: "20px 15px 30px 15px" }}
            >
              <div className={styles.deliveryMethodContent}>
                <div className={styles.leftPart}>
                  <div
                    className={styles.toggleItem}
                    style={{
                      padding:
                        checkZipCodesForExpressShipment(
                          client
                            ? client?.da_zip
                            : StorageService.getDataByKey(
                                "guestUserDeliveryInfo"
                              )?.da_zip
                        ) && orderTypeCookie() === "rg"
                          ? "0px 20px"
                          : 0,
                    }}
                  >
                    <CheckboxRoundStandardComponent
                      id="standard"
                      label={`${translations("label.standard_shipment1")}`}
                      secondLabel={translations("label.standard_shipment2")}
                      thirdLabel={translations("label.standard_shipment3")}
                      checked={standardShipment}
                      onChange={(e) => handleDeliveryStateUpdate(e, "standard")}
                    />
                  </div>
                  {(client?.da_zip || guestUserDeliveryInfoCookie?.da_zip) &&
                  showZurichExpressShipment &&
                  isWithinTimeRange &&
                  orderTypeCookie() === "rg" ? (
                    <div className={styles.rgZurichExpressShipment}>
                      <CheckboxRoundStandardComponent
                        id="zurich_express"
                        label={translations("label.zurich_express_shipment", {
                          zip_code: isLoggedIn()
                            ? client?.da_zip
                            : guestUserDeliveryInfoCookie?.da_zip,
                        })}
                        fourthLabel={translations(
                          "label.promotion_free_in_oct_and_now"
                        )}
                        checked={expressShipment}
                        onChange={(e) =>
                          handleDeliveryStateUpdate(e, "express")
                        }
                      />
                      <div
                        className={styles.next2hShipmentPopup}
                        style={
                          getLanguageStored().includes("it")
                            ? { minWidth: 120 }
                            : {}
                        }
                        onClick={() => handle2hExpressShipmentPopup()}
                      >
                        <HowItWorksIcon />
                        <span>{translations("label.info")}</span>
                      </div>

                      <div className={styles.timeslotContainer}>
                        <CheckboxRoundStandardComponent
                          id="within_next_2_hours"
                          label={translations("label.within_next_2_hours")}
                          checked={zurichExpressTimeslot === "next2h"}
                          onChange={(e) => {
                            setStandardShipment(false);
                            setPickupShipment(false);
                            setExpressShipment(true);
                            StorageService.setKeyValue(
                              "delivery_method",
                              "express"
                            );
                            setSelectedTimeslot(
                              translations("label.within_next_2_hours_new")
                            );
                            setZurichExpressTimeslot("next2h");
                          }}
                        />

                        <div className={styles.selectContent}>
                          <CheckboxRoundStandardComponent
                            id="at_this_timeslot"
                            label={translations("label.at_this_timeslot")}
                            checked={
                              zurichExpressTimeslot === "at_this_timeslot"
                            }
                            onChange={(e) => {
                              setStandardShipment(false);
                              setPickupShipment(false);
                              setExpressShipment(true);
                              StorageService.setKeyValue(
                                "delivery_method",
                                "express"
                              );

                              setZurichExpressTimeslot("at_this_timeslot");
                            }}
                          />
                          <div
                            className={styles.select}
                            style={
                              zurichExpressTimeslot !== "at_this_timeslot"
                                ? { opacity: 0.3, pointerEvents: "none" }
                                : {}
                            }
                          >
                            <select
                              name={"timeslot"}
                              className={styles.timeslotSelect}
                              value={selectedTimeslot}
                              style={{
                                color: "#BEC8CF",
                              }}
                              onChange={(e) =>
                                setSelectedTimeslot(e.target.value)
                              }
                            >
                              <option value="0" disabled>
                                {/* {translations("label.please_select")} */}
                              </option>
                              {(isTimeAfter18
                                ? getSelectOptions(
                                    [translations("label.tomorrow_morning")],
                                    true
                                  )
                                : timeslots
                              )?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={
                                      item.label === "label.tomorrow_morning"
                                        ? String(
                                            translations(item.label)
                                          ).toLowerCase()
                                        : item.label
                                    }
                                  >
                                    {item.label === "label.tomorrow_morning"
                                      ? translations(item.label)
                                      : item.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {!orderTypeCookie() ? (
                    <div
                      className={styles.toggleItem}
                      style={
                        quantity > 24
                          ? { opacity: "0.3", pointerEvents: "none" }
                          : {}
                      }
                    >
                      <CheckboxRoundStandardComponent
                        id="express"
                        label={translations("label.express_shipment")}
                        secondLabel={translations("label.express_shipment2")}
                        checked={expressShipment}
                        onChange={(e) =>
                          handleDeliveryStateUpdate(e, "express")
                        }
                      />
                    </div>
                  ) : null}
                  {orderTypeCookie() &&
                  isWithinTimeRange &&
                  showZurichExpressShipment ? (
                    <div
                      className={styles.toggleItem}
                      style={
                        showZurichExpressShipment ? { padding: "0px 20px" } : {}
                      }
                    >
                      <CheckboxRoundStandardComponent
                        id="pickup"
                        label={`${translations("label.pickup_at_store")}`}
                        secondLabel={
                          <div>
                            {" "}
                            <span>
                              Wintsch & Partner GmbH
                              <br />
                              Aathalstrasse 84a <br /> 8610 Uster
                            </span>
                            <br />
                            <span
                              style={{
                                fontFamily: "Montserrat-SemiBold",
                                color: "#000",
                              }}
                            >
                              {translations("label.opening_hours")} 10:00 -
                              18:00
                            </span>
                          </div>
                        }
                        checked={pickupShipment}
                        onChange={(e) => handleDeliveryStateUpdate(e, "pickup")}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {!orderTypeCookie() ? (
                <div className={styles.gerstlDelivery}>
                  <span>{translations("label.delivery_information")}</span>
                </div>
              ) : null}
            </div>

            <div className={styles.totalAmountTitle}>
              <span>{translations("label.payment")}</span>
            </div>

            {/* <div
              className={styles.actionBox}
              style={{ minHeight: 200, position: "relative" }}
            > */}
            <div className={styles.paymentMethods}>
              <div
                className={styles.method}
                style={orderTypeCookie() ? { padding: "0px 30px" } : {}}
                onClick={() => setPaymentMethod("card")}
              >
                <div className={styles.checkbox}>
                  <CheckboxRoundStandardComponent
                    id="card"
                    label={`${translations("label.card")}`}
                    checked={paymentMethod === "card"}
                    onChange={(e) => setPaymentMethod("card")}
                  />
                </div>
                <div className={styles.icon}>
                  <VisaIcon />
                  <MasterCardIcon />
                  <AmexCheckoutIcon />
                </div>
              </div>
              {/* 
                {isApple ? (
                  <div
                    className={styles.method}
                    onClick={() => setPaymentMethod("apple_pay")}
                  >
                    <div className={styles.checkbox}>
                      <CheckboxRoundStandardComponent
                        id="twint"
                        label={"Apple Pay"}
                        checked={paymentMethod === "apple_pay"}
                        onChange={(e) => setPaymentMethod("apple_pay")}
                      />
                    </div>
                    <div className={styles.icon}>
                      <ApplePayIcon />
                    </div>
                  </div>
                ) : null} */}
              <div
                className={styles.method}
                style={orderTypeCookie() ? { padding: "0px 30px" } : {}}
                onClick={() => setPaymentMethod("twint")}
              >
                <div className={styles.checkbox}>
                  <CheckboxRoundStandardComponent
                    id="twint"
                    label={"TWINT"}
                    checked={paymentMethod === "twint"}
                    onChange={(e) => setPaymentMethod("twint")}
                  />
                </div>
                <div className={styles.icon}>
                  <TwintIcon />
                </div>
              </div>
              <div
                className={styles.method}
                style={orderTypeCookie() ? { padding: "0px 30px" } : {}}
                onClick={() => setPaymentMethod("paypal")}
              >
                <div className={styles.checkbox}>
                  <CheckboxRoundStandardComponent
                    id="paypal"
                    label={"PayPal"}
                    checked={paymentMethod === "paypal"}
                    onChange={(e) => setPaymentMethod("paypal")}
                  />
                </div>
                <div className={styles.icon}>
                  <PaypalCheckoutIcon />
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className={styles.totalAmountBox}>
              {/* <div className={styles.contentWrapped}>
                {isLoading ? (
                  <div className={styles.loader}>
                    <LoaderBounce />
                  </div>
                ) : ( */}

              <div className={styles.membershipDiscountContent}>
                <span
                  className={styles.text}
                  style={{ fontFamily: "Montserrat-SemiBold" }}
                >
                  {translations("label.subtotal_including_vat")}
                  {": CHF " +
                    formatPrice(
                      Number(getTotalPrice(cartItems) + necktiesTotalPrice)
                        .toFixed(2)
                        .toString()
                    )}
                </span>
                <span className={styles.text}>
                  {/* FOR ZURICH EXPRESS SHIPMENT SHOULD BE 25.00 instead of 0.00 */}
                  {translations("label.delivery_cost")} :{" "}
                  {(getTotalPrice(cartItems) >= 99.0 && !expressShipment) ||
                  (orderTypeCookie() && expressShipment) ||
                  pickupShipment
                    ? translations("label.free")
                    : `CHF ${
                        expressShipment
                          ? orderTypeCookie()
                            ? "25.00"
                            : "30.00"
                          : "12.00"
                      }`}
                </span>

                <span className={styles.text}>
                  {translations("label.membership_discount")}
                  {" ("}
                  {client?.permanentDiscountPercentage
                    ? client?.permanentDiscountPercentage
                    : 0}
                  %) : CHF{" "}
                  {isLoggedIn() ? "-" + discountPrice : Number(0).toFixed(2)}
                </span>

                <span className={styles.text}>
                  {translations("label.tax_included_label")} : CHF{" "}
                  {formatPrice(
                    getTotalVatIncluded([...cartItems, ...selectedItems])
                      .toFixed(2)
                      .toString()
                  )}
                </span>
              </div>

              {/* )} */}
              {/* </div> */}
            </div>
            <div className={styles.totalLabel}>
              <span>{translations("label.total_amount")}</span>
              <span>
                {": CHF " +
                  formatPrice(
                    Number(totalPriceFormatted).toFixed(2).toString()
                  )}
              </span>
            </div>
            {/* {isLoggedIn() && hasSavedCard ? (
              <div className={styles.payWithDefaultCard}>
                <CheckboxRoundStandardComponent
                  id="payWithDefaultCard"
                  label={`${translations("label.pay_with_your_saved_card")}`}
                  checked={payWithDefaultCard}
                  onChange={(e) => setPayWithDefaultCard(!payWithDefaultCard)}
                />
              </div>
            ) : null} */}

            <div
              className={styles.mainButtons}
              style={{
                justifyContent: !isMobile
                  ? "flex-end"
                  : !isLoggedIn() && !isGuestUser
                  ? "space-between"
                  : "center",
              }}
            >
              {!isLoggedIn() && !isGuestUser ? (
                <ButtonComponent
                  label={translations("button.guest")}
                  class={"btn btn-primary"}
                  isLoading={isLoading}
                  style={{
                    marginRight: 20,
                    backgroundColor: getDefaultColour(defaultColour),
                  }}
                  handleClick={() => handleContinueAsGuest()}
                />
              ) : null}

              {isGuestUser && !isLoggedIn() ? (
                <ButtonComponent
                  label={translations("button.pay")}
                  class={"btn btn-primary"}
                  isLoading={isLoading}
                  disabled={isDisabledButton}
                  handleClick={() => handlePurchaseAsGuest()}
                />
              ) : (
                <ButtonComponent
                  label={translations(
                    isLoggedIn() ? "button.pay" : "button.login"
                  )}
                  class={"btn btn-primary"}
                  isLoading={isLoading}
                  disabled={isDisabledButton}
                  handleClick={() => handleProceedToPaymentClick()}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ backgroundColor: getDefaultColour(defaultColour) }}
            className={styles.previewContainer}
          >
            {selectedItems?.length !== 0 ? (
              <img
                className={styles.previewedWine}
                src={
                  Array.isArray(necktieItemsCookie()) &&
                  necktieItemsCookie().length !== 0
                    ? necktieItemsCookie()?.[necktieItemsCookie().length - 1]
                        ?.img_url
                    : ""
                }
                alt="Wine"
              />
            ) : null}
          </div>
        </Box>
      </Modal>
      <Modal
        open={isPreviewNoteOpen}
        onClose={() => setiIsPreviewNoteOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={noteStyle}>
          <div className={styles.previewPersonalNote}>
            <div className={styles.topLeft}>
              <CornerTopLeft />
            </div>
            <div className={styles.topRight}>
              <CornerTopRight />
            </div>
            <div className={styles.bottomLeft}>
              <CornerBottomLeftIcon />
            </div>
            <div className={styles.bottomRight}>
              <CornerBottomRight />
            </div>
            <div className={styles.content}>
              <img src={VinfinityLogo} alt={"Vinfinity"} />
              <h2>{personalNote}</h2>
              <span className={styles.link}>www.vinfinity.app</span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
