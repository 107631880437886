import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { formatPrice, getCurrencyStr, getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import { fetchClientBalanceSimulationBegin } from "../actions";
import { RefreshIcon } from "assets/icons/icons";
import styles from "./BalanceSimulationChart.module.scss";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import StorageService from "services/storage.service";

dayjs.extend(weekOfYear);

interface Props {}

function BalanceSimulationChart(props: Props) {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { balanceSimulation } = useAppSelector(
    (state: GlobalIState) => state.InvestmentsReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const [chartKey, setChartKey] = useState(0);
  const [series, setSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const isLoggedIn = () => {
    return StorageService.getDataByKey("token");
  };

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {},
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      title: { style: { color: "#000" } },
      tooltip: {
        marker: {
          show: true,
          fillColors: ["#34FF71"],
        },
      },
      grid: {
        show: false,
      },
      colors: ["#000"],
      plotOptions: {
        bar: {
          columnWidth: "80%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories:
          categories.length > 0
            ? categories
            : [
                `${translations("label.year")} 1`,
                `${translations("label.year")} 2`,
                `${translations("label.year")} 3`,
                `${translations("label.year")} 4`,
                `${translations("label.year")} 5`,
                `${translations("label.year")} 6`,
              ],
        labels: {
          style: {
            colors: Array(categories.length > 0 ? categories.length : 6).fill(
              getDefaultColour(defaultColour)
            ),
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
          formatter: (value: any) => {
            return `${getCurrencyStr(client?.currency)} ${formatPrice(
              value.toString()
            )}`;
          },
        },
      },
      fill: {
        type: "pattern",
        colors: [getDefaultColour(defaultColour)],
        opacity: 1,
        pattern: {
          style: [
            "horizontalLines",
            "horizontalLines",
            "horizontalLines",
            "horizontalLines",
            "horizontalLines",
            "horizontalLines",
            "horizontalLines",
          ],
          width: 1,
          height: 3,
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
    }),
    [categories, defaultColour, client?.currency, translations]
  );

  const handleRefresh = () => {
    if (balanceSimulation?.length >= 1) setChartKey((prevKey) => prevKey + 1);
    else {
      setSeries([
        {
          name: " ",
          data: [],
        },
      ]);
      setTimeout(() => {
        setSeries([
          {
            name: " ",
            data: [1219, 2518, 4326, 5297, 5915, 7630],
          },
        ]);
      }, 300);
    }
  };

  useEffect(() => {
    if (isLoggedIn()) dispatch(fetchClientBalanceSimulationBegin());
  }, [dispatch]);

  useEffect(() => {
    if (balanceSimulation?.length >= 1) {
      const newCategories = balanceSimulation.map((item) =>
        item?.month_group
          ? `${translations("label.month")} ${item?.month_group}`
          : `${translations("label.week")} ${dayjs(
              item.week_start_date
            ).week()}`
      );
      const newSeries = [
        {
          name: " ",
          data: balanceSimulation.map((item) => item.calculated_amount),
        },
      ];
      setCategories(newCategories);
      setSeries(newSeries);
    } else {
      setSeries([
        {
          name: " ",
          data: [1219, 2518, 4326, 5297, 5915, 7630],
        },
      ]);
      setCategories([
        `${translations("label.year")} 1`,
        `${translations("label.year")} 2`,
        `${translations("label.year")} 3`,
        `${translations("label.year")} 4`,
        `${translations("label.year")} 5`,
        `${translations("label.year")} 6`,
      ]);
    }
  }, [balanceSimulation, translations]);

  return (
    <div style={{ maxWidth: "100%", height: "90%" }}>
      <div className={styles.header}>
        <div>
          <h2>
            {translations(
              isLoggedIn() && balanceSimulation?.length >= 1
                ? "label.balance_since_start"
                : "label.balance_simulation"
            )}
          </h2>
        </div>
        <div className={styles.refreshIcon} onClick={handleRefresh}>
          <RefreshIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
      <ReactApexChart
        key={chartKey}
        options={options}
        series={series}
        type="bar"
        height={"100%"}
        width={"100%"}
      />
    </div>
  );
}

export default BalanceSimulationChart;
