import { openToaster } from "App/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as gamificationService from "services/gamification.service";
import {
  checkAnswerFailed,
  checkAnswerSuccess,
  checkBlankAnswerFailed,
  checkBlankAnswerSuccess,
  fillInTheBlankSubmitFailed,
  fillInTheBlankSubmitSuccess,
  getClientObjectivesFailed,
  getClientObjectivesSuccess,
  getClientTimelineFailed,
  getClientTimelineSuccess,
  getMedalsTableFailed,
  getMedalsTableSuccess,
  getReferralsTableFailed,
  getReferralsTableSuccess,
  getTodaysGameFailed,
  getTodaysGameSuccess,
  getTodaysQuestionFailed,
  getTodaysQuestionSuccess,
  submitMagicMatchFailed,
  submitMagicMatchSuccess,
  submitQuizFailed,
  submitQuizSuccess,
  submitTrueOrFalseFailed,
  submitTrueOrFalseSuccess,
} from "./actions";
import {
  CHECK_ANSWER_BEGIN,
  CHECK_BLANK_ANSWER_BEGIN,
  GET_CLIENT_OBJECTIVES_BEGIN,
  GET_CLIENT_TIMELINE_BEGIN,
  GET_MEDALS_LEAGUE_TABLE_BEGIN,
  GET_REFERRALS_TABLE_BEGIN,
  GET_TODAYS_GAME_BEGIN,
  GET_TODAYS_QUESTION_BEGIN,
  SUBMIT_FILL_THE_BLANK_BEGIN,
  SUBMIT_MAGIC_MATCH_BEGIN,
  SUBMIT_QUIZ_BEGIN,
  SUBMIT_TRUE_OR_FALSE_BEGIN,
} from "./constants";
import StorageService from "services/storage.service";

export function* fetchTodaysQuestion(action) {
  try {
    const response = yield call(
      gamificationService.getTodaysQuiz,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getTodaysQuestionSuccess(response?.data?.data));
    } else {
      yield put(getTodaysQuestionFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getTodaysQuestionFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* checkQuizAnswer(action) {
  try {
    const response = yield call(
      gamificationService.checkQuizAnswer,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(checkAnswerSuccess(response?.data?.data));
    } else {
      yield put(checkAnswerFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(checkAnswerFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitQuiz(action) {
  try {
    const response = yield call(gamificationService.submitQuiz, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitQuizSuccess(response?.data?.data));
    } else {
      if (response?.data?.httpCode === 405) {
        StorageService.setKeyValue("showAlreadyPlayedGame", true);
      }
      yield put(submitQuizFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitQuizFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitFillIntheBlank(action) {
  try {
    const response = yield call(
      gamificationService.submitFillInTheBlankGame,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fillInTheBlankSubmitSuccess(response?.data?.data));
    } else {
      if (response?.data?.httpCode === 405) {
        StorageService.setKeyValue("showAlreadyPlayedGame", true);
      }
      yield put(fillInTheBlankSubmitFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fillInTheBlankSubmitFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* checkBlankAnswer(action) {
  try {
    const response = yield call(
      gamificationService.checkBlankAnswer,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(checkBlankAnswerSuccess(response?.data?.data));
    } else {
      yield put(checkBlankAnswerFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(checkBlankAnswerFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitTrueOrFalse(action) {
  try {
    const response = yield call(
      gamificationService.submitTrueOrFalse,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitTrueOrFalseSuccess(response?.data?.data));
    } else {
      if (response?.data?.httpCode === 405) {
        StorageService.setKeyValue("showAlreadyPlayedGame", true);
      }
      yield put(submitTrueOrFalseFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitTrueOrFalseFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitMagicMatch(action) {
  try {
    const response = yield call(
      gamificationService.submitMagicMatch,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitMagicMatchSuccess(response?.data?.data));
    } else {
      if (response?.data?.httpCode === 405) {
        StorageService.setKeyValue("showAlreadyPlayedGame", true);
      }
      yield put(submitMagicMatchFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitMagicMatchFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getClientObjectives(action) {
  try {
    const response = yield call(gamificationService.getClientObjectives);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientObjectivesSuccess(response?.data?.data));
    } else {
      yield put(getClientObjectivesFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getClientObjectivesFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getReferralsTable(action) {
  try {
    const response = yield call(gamificationService.getReferralsTable);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getReferralsTableSuccess(response?.data?.data));
    } else {
      yield put(getReferralsTableFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getReferralsTableFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getMedalsTable(action) {
  try {
    const response = yield call(gamificationService.getMedalsTable);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getMedalsTableSuccess(response?.data?.data));
    } else {
      yield put(getMedalsTableFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getMedalsTableFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getClientTimeline(action) {
  try {
    const response = yield call(gamificationService.getClientTimeline);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientTimelineSuccess(response?.data?.data));
    } else {
      yield put(getClientTimelineFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getClientTimelineFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getTodaysGame(action) {
  try {
    const response = yield call(gamificationService.getTodaysGame);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getTodaysGameSuccess(response?.data?.data));
    } else {
      yield put(getTodaysGameFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getTodaysGameFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* gamificationSaga() {
  yield takeLatest(GET_TODAYS_QUESTION_BEGIN, fetchTodaysQuestion);
  yield takeLatest(CHECK_ANSWER_BEGIN, checkQuizAnswer);
  yield takeLatest(CHECK_BLANK_ANSWER_BEGIN, checkBlankAnswer);
  yield takeLatest(SUBMIT_QUIZ_BEGIN, submitQuiz);
  yield takeLatest(SUBMIT_TRUE_OR_FALSE_BEGIN, submitTrueOrFalse);
  yield takeLatest(SUBMIT_FILL_THE_BLANK_BEGIN, submitFillIntheBlank);
  yield takeLatest(GET_CLIENT_OBJECTIVES_BEGIN, getClientObjectives);
  yield takeLatest(GET_REFERRALS_TABLE_BEGIN, getReferralsTable);
  yield takeLatest(GET_MEDALS_LEAGUE_TABLE_BEGIN, getMedalsTable);
  yield takeLatest(GET_CLIENT_TIMELINE_BEGIN, getClientTimeline);
  yield takeLatest(GET_TODAYS_GAME_BEGIN, getTodaysGame);
  yield takeLatest(SUBMIT_MAGIC_MATCH_BEGIN, submitMagicMatch);
}
