import { useTranslation } from "react-i18next";
import styles from "./TrueOrFalseGame.module.scss";
import {
  InfoIcon,
  InfoModalCloseIcon,
  RoundedInfoArrowUp,
} from "assets/icons/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import CoinImage from "assets/images/Coin.png";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkAnswerBegin,
  clearCheckAnswer,
  getTodaysQuestionBegin,
  submitTrueOrFalseBegin,
} from "../actions";
import StorageService from "services/storage.service";
import { SUBMIT_TRUE_OR_FALSE_SUCCESS } from "../constants";
import { getClientBegin, getUserReferralsBegin } from "pages/Profile/actions";
import { closeModal, openModal, openToaster } from "App/actions";

import Lottie from "react-lottie";
import * as trueOrFalseAnimation from "assets/animations/true-or-false.json";
import ButtonComponent from "components/Button/Button.component";
import { modalTypes } from "types/reducers/modal.reducer.type";

export default function TrueOrFalseGame() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    todays_question,
    answer_correctness,
    todaysGame,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    activeStep: "",
    selectedAnswerId: 0,
    question: "",
    trueAnswerId: null,
    falseAnswerId: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const submitParam = searchParams.get("submit");
  const questionStepParam = searchParams.get("question");
  const storedTrueOrFalseCookie =
    StorageService.getDataByKey("storedTrueOrFalse");
  const medalsStored = StorageService.getDataByKey("medals-stored");
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const showAlreadyPlayedGameCookie = StorageService.getDataByKey(
    "showAlreadyPlayedGame"
  );

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: trueOrFalseAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleOpenAlreadyPlayedPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.alreadyPlayedBox}>
            <div className={styles.mainContentPopup}>
              <div>
                <InfoIcon color="#fff" />
              </div>
              <div>
                <span>{translations("label.already_played_game")}</span>
              </div>
            </div>

            <div className={styles.bottomPart}>
              <div>
                <span>{translations("label.info")}</span>
              </div>
              <div
                className="pointer"
                onClick={() => {
                  StorageService.deleteCookie("showAlreadyPlayedGame");
                  dispatch(closeModal());
                }}
              >
                <RoundedInfoArrowUp />
              </div>
            </div>
          </div>
        ),
        type: modalTypes.smInfoOrange,
      })
    );
  };

  const handleStartGame = () => {
    navigate("/games/true-or-false?question=1&step=quiz");
  };

  const handleNext = () => {
    const data = () => StorageService.getDataByKey("storedTrueOrFalse");
    if (state.selectedAnswerId === 0 || !state.selectedAnswerId) {
      dispatch(
        openToaster("ERROR", translations("label.please_select_one_option"))
      );
      return;
    }

    StorageService.setKeyValue(
      "medals-stored",
      Number(medalsStored) + (answer_correctness ? 5 : 0)
    );

    if (questionStepParam === "3" && data()?.length === 3) {
      setState({ ...state, activeStep: "submit" });
      return;
    }

    navigate(
      `/games/true-or-false?question=${Number(questionStepParam) + 1}&step=quiz`
    );

    if (questionStepParam !== "3") {
      setState({ ...state, selectedAnswerId: null });
      dispatch(clearCheckAnswer());
    }
  };

  const handleTrueOrFalseClick = (id: number) => {
    const dataStored = () => StorageService.getDataByKey("storedTrueOrFalse");
    if (state.selectedAnswerId !== 0 && state.selectedAnswerId !== null) {
      return;
    }

    storedTrueOrFalseCookie?.length > 0
      ? StorageService.setKeyValue("storedTrueOrFalse", [
          ...storedTrueOrFalseCookie,
          id,
        ])
      : StorageService.setKeyValue("storedTrueOrFalse", [id]);

    setState({
      ...state,
      selectedAnswerId: id,
    });

    dispatch(checkAnswerBegin(id));
  };

  const handleQuizSubmit = () => {
    if (isLoggedIn()) dispatch(submitTrueOrFalseBegin(storedTrueOrFalseCookie));
    else {
      StorageService.setKeyValue(
        "lastRouteCookie",
        "/games/true-or-false?submit=true"
      );
      navigate("/login", {
        state: {
          lastRoute: "/games/true-or-false?submit=true",
        },
      });
    }
  };

  useEffect(() => {
    dispatch(getTodaysQuestionBegin(getLanguage()));
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
  }, [state.activeStep]);

  useEffect(() => {
    if (stepParam === "quiz" && state.activeStep !== "submit") {
      setState({ ...state, activeStep: "playQuiz" });
    }

    if (submitParam) {
      dispatch(submitTrueOrFalseBegin(storedTrueOrFalseCookie));
      StorageService.deleteCookie("storedTrueOrFalse");
      StorageService.deleteCookie("medals-stored");
      searchParams.delete("submit");
      setSearchParams(searchParams);
    }
  }, [stepParam, submitParam]);

  useEffect(() => {
    if (
      (actionName === SUBMIT_TRUE_OR_FALSE_SUCCESS && !submitParam) ||
      client?.has_played
    ) {
      StorageService.deleteCookie("storedTrueOrFalse");
      StorageService.deleteCookie("medals-stored");
      navigate("/games");
      window.location.reload();
    }
  }, [actionName]);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(getUserReferralsBegin(true));
    }
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      if (client?.has_played) {
        navigate("/purchasing");
      } else {
        window.history.back();
      }
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, client?.has_played]);

  useEffect(() => {
    let step = state.activeStep === "" ? "playQuiz" : state.activeStep;
    if (questionStepParam === "1") {
      setState({
        ...state,
        activeStep: step,
        question: todays_question?.question,
        trueAnswerId: todays_question?.firstQuestionfirstAnswerId,
        falseAnswerId: todays_question?.firstQuestionsecondAnswerId,
      });
    } else if (questionStepParam === "2") {
      setState({
        ...state,
        activeStep: step,
        question: todays_question?.secondQuestion,
        trueAnswerId: todays_question?.secondQuestionsecondAnswerId,
        falseAnswerId: todays_question?.secondQuestionfirstAnswerId,
      });
    } else if (questionStepParam === "3") {
      setState({
        ...state,
        activeStep: step,
        question: todays_question?.thirdQuestion,
        trueAnswerId: todays_question?.thirdQuestionsecondAnswerId,
        falseAnswerId: todays_question?.thirdQuestionfirstAnswerId,
      });
    }
  }, [todays_question, questionStepParam]);

  useEffect(() => {
    if (showAlreadyPlayedGameCookie) {
      handleOpenAlreadyPlayedPopup();
    }
  }, [showAlreadyPlayedGameCookie]);

  useEffect(() => {
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
    if (todaysGame !== "True or False") {
      navigate("/games");
      window.location.reload();
    }
  }, [client, todaysGame]);

  return (
    <div
      className={styles.gamificationWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {state.activeStep === "" ? (
        <div className={styles.content}>
          <div className={styles.animation}>
            <Lottie
              options={defaultOptions}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div>
          <div className={styles.gameInfo}>
            <div>
              <h1>{translations("label.true_or_false")}</h1>
              <span>{translations("label.true_or_false.subtitle")}</span>
            </div>
            <ButtonComponent
              label={translations("label.start_game")}
              class={"btn btn-primary"}
              style={{ backgroundColor: "#FF6333" }}
              handleClick={() => handleStartGame()}
            />
          </div>
        </div>
      ) : ["playQuiz", "submit"].includes(state.activeStep) ? (
        <div className={styles.playQuizGameBox}>
          <div className={styles.header}>
            <div
              onClick={() => {
                navigate("/games");
                setState({ ...state, activeStep: "" });
              }}
            >
              <InfoModalCloseIcon color="#fff" />
            </div>

            <div className={styles.questionBox}>
              <h2>{translations("label.daily_quiz")}</h2>

              <div className={styles.subtitle}>
                <span>{translations("label.true_or_false")}</span>
              </div>

              {state.activeStep !== "submit" && (
                <span className={styles.question}>
                  <span>{state.question}</span>
                </span>
              )}
            </div>

            {state.activeStep === "submit" ? (
              <div className={styles.answeredCoinBox}>
                <img src={CoinImage} alt={"Coin"} />
              </div>
            ) : (
              <div className={styles.answerBox}>
                <div
                  className={styles.answerButton}
                  style={{
                    backgroundColor:
                      state.selectedAnswerId === state.falseAnswerId
                        ? state.selectedAnswerId !== null && !answer_correctness
                          ? "#FF4141"
                          : answer_correctness
                          ? "#B5FF6C"
                          : "#fff"
                        : "transparent",
                    color:
                      state.selectedAnswerId === state.falseAnswerId
                        ? "#000"
                        : "#fff",
                  }}
                  onClick={() => handleTrueOrFalseClick(state?.falseAnswerId)}
                >
                  <span>{translations("label.false")}</span>
                </div>
                <div
                  className={styles.answerButton}
                  style={{
                    backgroundColor:
                      state.selectedAnswerId === state.trueAnswerId
                        ? state.selectedAnswerId !== null && !answer_correctness
                          ? "#FF4141"
                          : answer_correctness
                          ? "#B5FF6C"
                          : "#fff"
                        : "transparent",
                    color:
                      state.selectedAnswerId === state.trueAnswerId
                        ? "#000"
                        : "#fff",
                  }}
                  onClick={() => handleTrueOrFalseClick(state?.trueAnswerId)}
                >
                  <span>{translations("label.true")}</span>
                </div>
              </div>
            )}

            {state.activeStep === "submit" ? (
              <>
                {!isLoggedIn() ? (
                  <div className={styles.notLoggedInUser}>
                    <span>
                      {translations("label.login_to_retrieve_medals")}
                    </span>
                  </div>
                ) : null}
                <div className={styles.earnedMedals}>
                  <span>
                    {medalsStored > 10 ? medalsStored : 5}{" "}
                    {translations("label.medals")}
                  </span>
                </div>
              </>
            ) : null}

            {state.selectedAnswerId !== 0 ? (
              <div
                className={styles.submitAnswerButton}
                style={{
                  backgroundColor:
                    state.selectedAnswerId !== 0 ? "#fff" : "#600f83",
                  pointerEvents: state.selectedAnswerId !== 0 ? "all" : "none",
                  cursor: state.selectedAnswerId !== 0 ? "pointer" : "default",
                  color: state.selectedAnswerId !== 0 ? "#000" : "#fff",
                  opacity: isLoading ? 0.7 : 1,
                }}
                onClick={() =>
                  state.activeStep === "submit"
                    ? handleQuizSubmit()
                    : handleNext()
                }
              >
                <span>
                  {translations(
                    state.activeStep === "submit"
                      ? isLoggedIn() && questionStepParam === "3"
                        ? "label.get_medals"
                        : "button.log_in"
                      : "button.next"
                  )}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
