import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Games.module.scss";
import { getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";
import { GoldCoinIcon, HowItWorksIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import PlayedGame from "./PlayedGame/PlayedGame.page";

import AudioGame from "./AudioGame/AudioGame.page";
import { getTodaysGameBegin } from "./actions";
import SingleChoiceGame from "./SingleChoiceGame/SingleChoiceGame.page";
import FillInTheBlank from "./FillInTheBlank/FillInTheBlank.page";
import TrueOrFalseGame from "./TrueOrFalseGame/TrueOrFalseGame.page";
import PourAndScore from "./PourAndScore/PourAndScore.page";
import MagicMatch from "./MagicMatch/MagicMatch.page";
import MatchThePairs from "./MatchThePairs/MatchThePairs.page";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

function Games() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { todaysGame } = useAppSelector(
    (state: GlobalIState) => state.GamificationReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState("play");
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (client?.has_played || stepParam === "played") {
      setStep("played");
    }
  }, [client, stepParam]);

  useEffect(() => {
    if (!todaysGame) {
      dispatch(getTodaysGameBegin());
    }
  }, [todaysGame]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h1>{translations("label.game_of_the_day")}</h1>

      <div className={styles.gameOfTheDayContainer}>
        {step === "played" ? (
          <PlayedGame />
        ) : (
          <>
            <div className={styles.header}>
              <GoldCoinIcon />
              <div className={styles.medalsToWin}>
                <span>15</span>
              </div>
            </div>

            {todaysGame === "Daily Quiz" ? <SingleChoiceGame /> : null}
            {todaysGame === "Fill Blank" ? <FillInTheBlank /> : null}
            {todaysGame === "True or False" ? <TrueOrFalseGame /> : null}
            {todaysGame === "Audio" ? <AudioGame /> : null}
            {todaysGame === "Pour and Score" ? <PourAndScore /> : null}
            {todaysGame === "Magic Match" ? <MagicMatch /> : null}
            {todaysGame === "Match the pairs" ? <MatchThePairs /> : null}
          </>
        )}
      </div>
      {isMobile ? (
        <div
          className={styles.gamesInfoButton}
          onClick={() => navigate("/games/info")}
        >
          <div className={styles.header}>
            <span>{translations("label.learn_more_about")}</span>
            <HowItWorksIcon />
          </div>
          <div>
            <h1>{translations("label.vinfinity_games")}</h1>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Games;
