const scope = "vinfinity/games";

export const GET_TODAYS_QUESTION_BEGIN = `${scope}/GET_TODAYS_QUESTION_BEGIN`;
export const GET_TODAYS_QUESTION_SUCCESS = `${scope}/GET_TODAYS_QUESTION_SUCCESS`;
export const GET_TODAYS_QUESTION_FAILED = `${scope}/GET_TODAYS_QUESTION_FAILED`;

// SINGLE CHOICE
export const SUBMIT_QUIZ_BEGIN = `${scope}/SUBMIT_QUIZ_BEGIN`;
export const SUBMIT_QUIZ_SUCCESS = `${scope}/SUBMIT_QUIZ_SUCCESS`;
export const SUBMIT_QUIZ_FAILED = `${scope}/SUBMIT_QUIZ_FAILED`;

export const CHECK_ANSWER_BEGIN = `${scope}/CHECK_ANSWER_BEGIN`;
export const CHECK_ANSWER_SUCCESS = `${scope}/CHECK_ANSWER_SUCCESS`;
export const CHECK_ANSWER_FAILED = `${scope}/CHECK_ANSWER_FAILED`;

export const CLEAR_CHECK_ANSWER = `${scope}/CLEAR_CHECK_ANSWER`;

// FILL IN THE BLANK
export const SUBMIT_FILL_THE_BLANK_BEGIN = `${scope}/SUBMIT_FILL_THE_BLANK_BEGIN`;
export const SUBMIT_FILL_THE_BLANK_SUCCESS = `${scope}/SUBMIT_FILL_THE_BLANK_SUCCESS`;
export const SUBMIT_FILL_THE_BLANK_FAILED = `${scope}/SUBMIT_FILL_THE_BLANK_FAILED`;

export const CHECK_BLANK_ANSWER_BEGIN = `${scope}/CHECK_BLANK_ANSWER_BEGIN`;
export const CHECK_BLANK_ANSWER_SUCCESS = `${scope}/CHECK_BLANK_ANSWER_SUCCESS`;
export const CHECK_BLANK_ANSWER_FAILED = `${scope}/CHECK_BLANK_ANSWER_FAILED`;

// TRUE OR FALSE
export const SUBMIT_TRUE_OR_FALSE_BEGIN = `${scope}/SUBMIT_TRUE_OR_FALSE_BEGIN`;
export const SUBMIT_TRUE_OR_FALSE_SUCCESS = `${scope}/SUBMIT_TRUE_OR_FALSE_SUCCESS`;
export const SUBMIT_TRUE_OR_FALSE_FAILED = `${scope}/SUBMIT_TRUE_OR_FALSE_FAILED`;

// MAGIC MATCH
export const SUBMIT_MAGIC_MATCH_BEGIN = `${scope}/SUBMIT_MAGIC_MATCH_BEGIN`;
export const SUBMIT_MAGIC_MATCH_SUCCESS = `${scope}/SUBMIT_MAGIC_MATCH_SUCCESS`;
export const SUBMIT_MAGIC_MATCH_FAILED = `${scope}/SUBMIT_MAGIC_MATCH_FAILED`;

export const GET_CLIENT_OBJECTIVES_BEGIN = `${scope}/GET_CLIENT_OBJECTIVES_BEGIN`;
export const GET_CLIENT_OBJECTIVES_SUCCESS = `${scope}/GET_CLIENT_OBJECTIVES_SUCCESS`;
export const GET_CLIENT_OBJECTIVES_FAILED = `${scope}/GET_CLIENT_OBJECTIVES_FAILED`;

export const GET_REFERRALS_TABLE_BEGIN = `${scope}/GET_REFERRALS_TABLE_BEGIN`;
export const GET_REFERRALS_TABLE_SUCCESS = `${scope}/GET_REFERRALS_TABLE_SUCCESS`;
export const GET_REFERRALS_TABLE_FAILED = `${scope}/GET_REFERRALS_TABLE_FAILED`;

export const GET_MEDALS_LEAGUE_TABLE_BEGIN = `${scope}/GET_MEDALS_LEAGUE_TABLE_BEGIN`;
export const GET_MEDALS_LEAGUE_TABLE_SUCCESS = `${scope}/GET_MEDALS_LEAGUE_TABLE_SUCCESS`;
export const GET_MEDALS_LEAGUE_TABLE_FAILED = `${scope}/GET_MEDALS_LEAGUE_TABLE_FAILED`;

export const GET_CLIENT_TIMELINE_BEGIN = `${scope}/GET_CLIENT_TIMELINE_BEGIN`;
export const GET_CLIENT_TIMELINE_SUCCESS = `${scope}/GET_CLIENT_TIMELINE_SUCCESS`;
export const GET_CLIENT_TIMELINE_FAILED = `${scope}/GET_CLIENT_TIMELINE_FAILED`;

export const GET_TODAYS_GAME_BEGIN = `${scope}/GET_TODAYS_GAME_BEGIN`;
export const GET_TODAYS_GAME_SUCCESS = `${scope}/GET_TODAYS_GAME_SUCCESS`;
export const GET_TODAYS_GAME_FAILED = `${scope}/GET_TODAYS_GAME_FAILED`;
